import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Container, Row, Stack, Table } from "react-bootstrap";
import {
  OrderClient,
  StoreSalesKpi,
  ApiException,
} from "../../../services/ApiClient";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import isoWeek from "dayjs/plugin/isoWeek";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { SingleCard } from "../../layouts/SingleCard";
import "./StoreWastage.scss";
import { LinkContainer } from "react-router-bootstrap";

dayjs.extend(weekday);
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);

export const StoreWastage = () => {
  const { storeid } = useParams();
  const [wastageData, setWastageData] = useState<StoreSalesKpi[] | null>(null);

  const getLastFullWeekSunday = (): dayjs.Dayjs => {
    const today = dayjs();
    const todayIsSunday = today.day() === 0;

    if (todayIsSunday) {
      // If today is Sunday, get the previous Sunday
      return today.subtract(1, "week");
    } else {
      // If today is not Sunday, get the Sunday two weeks ago
      return today.day(-7);
    }
  };

  useEffect(() => {
    const fetchWastageData = async () => {
      try {
        const lastFullWeekSunday = getLastFullWeekSunday();

        console.log(lastFullWeekSunday.format("YYYY-DD-MM"));
        const data = await new OrderClient(
          process.env.REACT_APP_API_BASE
        ).getStoreSalesKpi(storeid, "2024-07-14");
        setWastageData(data);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.error(error.message);
      }
    };

    fetchWastageData();
  }, [storeid]);

  // Separate data into Cuts and Pots
  const cutsData =
    wastageData?.filter((item) => item.inV_ItemGroupId === "Cuts") ?? [];
  const potsData =
    wastageData?.filter((item) => item.inV_ItemGroupId === "Pots") ?? [];

  // Calculate totals for Cuts
  const cutsTotals = {
    deliveredQty: cutsData.reduce(
      (acc, item) => acc + (item.deliveredQty ?? 0),
      0
    ),
    scannedQuantity: cutsData.reduce(
      (acc, item) => acc + (item.scannedQuantity ?? 0),
      0
    ),
    wastageQty: cutsData.reduce((acc, item) => acc + (item.wastageQty ?? 0), 0),
    delivered: cutsData.reduce((acc, item) => acc + (item.delivered ?? 0), 0),
    sS_TotalRetailValue: cutsData.reduce(
      (acc, item) => acc + (item.sS_TotalRetailValue ?? 0),
      0
    ),
    wastage: cutsData.reduce((acc, item) => acc + (item.wastage ?? 0), 0),
  };

  // Calculate totals for Pots
  const potsTotals = {
    deliveredQty: potsData.reduce(
      (acc, item) => acc + (item.deliveredQty ?? 0),
      0
    ),
    scannedQuantity: potsData.reduce(
      (acc, item) => acc + (item.scannedQuantity ?? 0),
      0
    ),
    wastageQty: potsData.reduce((acc, item) => acc + (item.wastageQty ?? 0), 0),
    delivered: potsData.reduce((acc, item) => acc + (item.delivered ?? 0), 0),
    sS_TotalRetailValue: potsData.reduce(
      (acc, item) => acc + (item.sS_TotalRetailValue ?? 0),
      0
    ),
    wastage: potsData.reduce((acc, item) => acc + (item.wastage ?? 0), 0),
  };

  return (
    <div className="store-wastage">
      <Container>
        <Row className="gy-5">
          <Col lg={12}>
            <h2 className="page-header">Store Sales and Wastage</h2>
          </Col>
          {cutsData.length > 0 && (
            <Col lg={12}>
              <SingleCard pageTitle="Cuts">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Price Point</th>
                      <th>Delivered Qty</th>
                      <th>Scan Qty</th>
                      <th>Wastage Qty</th>
                      <th>Delivered $</th>
                      <th>Scan Sales $</th>
                      <th>Wastage $</th>
                      <th>Wastage %</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cutsData.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.sO_UnitPrice !== null &&
                          item.sO_UnitPrice !== undefined
                            ? `$${item.sO_UnitPrice.toFixed(2)}`
                            : ""}
                        </td>
                        <td>{item.deliveredQty ?? ""}</td>
                        <td>{item.scannedQuantity ?? ""}</td>
                        <td>{item.wastageQty ?? ""}</td>
                        <td>
                          {item.delivered !== null &&
                          item.delivered !== undefined
                            ? `$${item.delivered.toFixed(2)}`
                            : ""}
                        </td>
                        <td>
                          {item.sS_TotalRetailValue !== null &&
                          item.sS_TotalRetailValue !== undefined
                            ? `$${item.sS_TotalRetailValue.toFixed(2)}`
                            : ""}
                        </td>
                        <td>
                          {item.wastage !== null && item.wastage !== undefined
                            ? `$${item.wastage.toFixed(2)}`
                            : ""}
                        </td>
                        <td>
                          {item.wastagePerc !== null &&
                          item.wastagePerc !== undefined
                            ? `${item.wastagePerc}%`
                            : ""}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td>
                        <strong>{cutsTotals.deliveredQty}</strong>
                      </td>
                      <td>
                        <strong>{cutsTotals.scannedQuantity}</strong>
                      </td>
                      <td>
                        <strong>{cutsTotals.wastageQty}</strong>
                      </td>
                      <td>
                        <strong>{`$${cutsTotals.delivered.toFixed(2)}`}</strong>
                      </td>
                      <td>
                        <strong>{`$${cutsTotals.sS_TotalRetailValue.toFixed(
                          2
                        )}`}</strong>
                      </td>
                      <td>
                        <strong>{`$${cutsTotals.wastage.toFixed(2)}`}</strong>
                      </td>
                      <td>
                        {(
                          (cutsTotals.wastage / cutsTotals.delivered) *
                          100
                        ).toFixed(0)}
                        %
                      </td>
                      {/* Wastage % total doesn't make sense, so left blank */}
                    </tr>
                  </tbody>
                </Table>
              </SingleCard>
            </Col>
          )}
          {potsData.length > 0 && (
            <Col lg={12}>
              <SingleCard pageTitle="Pots">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Price Point</th>
                      <th>Delivered Qty</th>
                      <th>Scan Qty</th>
                      <th>Wastage Qty</th>
                      <th>Delivered $</th>
                      <th>Scan Sales $</th>
                      <th>Wastage $</th>
                      <th>Wastage %</th>
                    </tr>
                  </thead>
                  <tbody>
                    {potsData.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.sO_UnitPrice !== null &&
                          item.sO_UnitPrice !== undefined
                            ? `$${item.sO_UnitPrice.toFixed(2)}`
                            : ""}
                        </td>
                        <td>{item.deliveredQty ?? ""}</td>
                        <td>{item.scannedQuantity ?? ""}</td>
                        <td>{item.wastageQty ?? ""}</td>
                        <td>
                          {item.delivered !== null &&
                          item.delivered !== undefined
                            ? `$${item.delivered.toFixed(2)}`
                            : ""}
                        </td>
                        <td>
                          {item.sS_TotalRetailValue !== null &&
                          item.sS_TotalRetailValue !== undefined
                            ? `$${item.sS_TotalRetailValue.toFixed(2)}`
                            : ""}
                        </td>
                        <td>
                          {item.wastage !== null && item.wastage !== undefined
                            ? `$${item.wastage.toFixed(2)}`
                            : ""}
                        </td>
                        <td>
                          {item.wastagePerc !== null &&
                          item.wastagePerc !== undefined
                            ? `${item.wastagePerc}%`
                            : ""}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td>
                        <strong>{potsTotals.deliveredQty}</strong>
                      </td>
                      <td>
                        <strong>{potsTotals.scannedQuantity}</strong>
                      </td>
                      <td>
                        <strong>{potsTotals.wastageQty}</strong>
                      </td>
                      <td>
                        <strong>{`$${potsTotals.delivered.toFixed(2)}`}</strong>
                      </td>
                      <td>
                        <strong>{`$${potsTotals.sS_TotalRetailValue.toFixed(
                          2
                        )}`}</strong>
                      </td>
                      <td>
                        <strong>{`$${potsTotals.wastage.toFixed(2)}`}</strong>
                      </td>
                      <td>
                        {(
                          (potsTotals.wastage / potsTotals.delivered) *
                          100
                        ).toFixed(0)}
                        %
                      </td>
                      {/* Wastage % total doesn't make sense, so left blank */}
                    </tr>
                  </tbody>
                </Table>
              </SingleCard>
            </Col>
          )}
          <Col lg={12} className={"d-flex flex-row-reverse back-button"}>
            {" "}
            <Stack gap={3} direction="horizontal">
              <LinkContainer to={`/stores/${storeid}`}>
                <Button className="back-button" type="button">
                  {"Back"}
                </Button>
              </LinkContainer>
            </Stack>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
