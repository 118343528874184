import React, { useState, useCallback, useMemo } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { StoreDTO } from "../../../services/ApiClient";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import "./MissingOrders.scss";
import { AddStoreButtonRenderer } from "./AddStoreButtonRenderer";

interface IMissingOrdersProps {
  missingStores: StoreDTO[];
  orderType: number;
  orderDate: string;
  refreshGrid: () => void;
  approvalData: any;
}

export const MissingOrders = (props: IMissingOrdersProps) => {
  const [error, setError] = useState<null | string>(null);

  const setErrorCallback = useCallback((error: any) => {
    setError(error);
  }, []);

  const columnDefs = useMemo<ColDef[]>(() => {
    return [
      {
        headerName: "Store",
        field: "name",
        editable: false,
        flex: 10,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        sortable: true,
      },
      {
        headerName: "",
        field: "id",
        editable: false,
        flex: 1,
        cellRenderer: AddStoreButtonRenderer,
        cellRendererParams: {
          orderType: props.orderType,
          orderDate: props.orderDate,
          refreshCallback: props.refreshGrid,
          setError: setErrorCallback,
        },
      },
    ];
  }, [props.orderType, props.orderDate, props.refreshGrid, setErrorCallback]);

  return (
    <div className={"missing-orders"}>
      <AgGridReact
        className={`ag-theme-alpine ag-no-banding`}
        columnDefs={columnDefs}
        rowData={props.missingStores}
        domLayout="autoHeight"
        suppressHorizontalScroll
      />
      <Modal show={error?.length! > 0} onHide={() => setError(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setError(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
