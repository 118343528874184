import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import {
  ApiException,
  OrderClient,
  StoreNameDTO,
  UserClient,
  UserDTO,
} from "../../../services/ApiClient";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { NoCards } from "../../layouts/NoCards";
import { UserEditForm } from "./UserEditForm";
import "./Users.scss";

interface IUserEditProps {}

export const UserEdit = (props: IUserEditProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState<null | string>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [userData, setUserData] = useImmer<UserDTO | null>(null);
  const [stores, setStores] = useImmer<StoreNameDTO[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetching(true);
        const userId = params?.userEmail!;
        const currentUser = await new UserClient(
          process.env.REACT_APP_API_BASE
        ).getUser(userId);
        const stores = await new OrderClient(
          process.env.REACT_APP_API_BASE
        ).getStoreNames();

        if (typeof currentUser !== "undefined") {
          setUserData(currentUser!);
          setStores(stores);
          setIsLoaded(true);
          setIsFetching(false);
        } else {
          setError("User not found.");
        }
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setIsFetching(false);
        setError(JSON.parse(error.response).error);
      }
    };

    fetchData().catch(console.error);
  }, [params, setStores, setUserData]);

  const saveUser = useCallback(
    async (UserData: UserDTO) => {
      try {
        if (userData) {
          setIsSaving(true);
          await new UserClient(process.env.REACT_APP_API_BASE).saveUser(
            UserData
          );
          setIsSaving(false);
          navigate("/settings/users");
        }
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setError(JSON.parse(error.response).error);
      }
    },
    [navigate, userData]
  );

  /*
  const deleteItem = useCallback(async (UserData: UserDTO) => {
  
    try {
      setIsSaving(true);
      const result = await new UserClient(process.env.REACT_APP_API_BASE). //currently no endpoint for this
      setIsSaving(false);    
      navigate('/settings');     
    } catch (e: unknown) {
      const error = e as ApiException;
      console.log(error.message)
      setError(JSON.parse(error.response).error);
    }
  }, [UserData]);
  */

  return (
    <div className={"portal-settings"}>
      <NoCards pageTitle="">
        {!isFetching && isLoaded && stores ? (
          <>
            <Col md={12}>
              <h3>Edit user</h3>
            </Col>
            <Col lg={12}>
              <Card className="shadow-sm p-0" border="light">
                <Card.Body className="p-0 portal-settings-form">
                  {typeof userData !== "undefined" && userData && (
                    <UserEditForm
                      user={userData}
                      updateUser={saveUser}
                      isNew={false}
                      isSaving={isSaving}
                      stores={stores}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </>
        ) : (
          <CardPlaceholder />
        )}
      </NoCards>
      <Modal show={error?.length! > 0} onHide={() => setError(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setError(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
