import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import {
  ApiException,
  ItemAvailabilityMatrixClient,
  ItemAvailabilityViewDTO,
} from "../../../services/ApiClient";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { NoCards } from "../../layouts/NoCards";
import "./ProductAvailability.scss";
import { ProductAvailabilityForm } from "./ProductAvailabilityForm";

interface INewProductAvailabilityProps {}

//Required because the dates provided by the client are not valid js dates
//interface ItemAvailablityStringDate extends Omit<KooItemavailabilitytable,'effectiveFrom' | 'effectiveTo'> {
//  effectiveFrom: Date | string;
//  effectiveTo: Date | string;
//}

export const NewProductAvailability = (props: INewProductAvailabilityProps) => {
  const navigate = useNavigate();
  const [error, setError] = useState<null | string>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [availabilityData, setAvailabilityData] =
    useImmer<ItemAvailabilityViewDTO | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetching(true);
        //0 will get availability for all products, passing in the product redId will get a single product
        const result = await new ItemAvailabilityMatrixClient(
          process.env.REACT_APP_API_BASE
        ).getItemAvailability(0);

        //Update results to remove other availabilities to prevent unneeded updates.
        result.itemAvailabilities = undefined;

        result.itemAvailability = {
          recId: 0,
          itemId: "0",
          itemName: "",
          effectiveFrom: new Date().toISOString(),
          effectiveTo: new Date().toISOString(),
          itemAvailability: 0,
          special: 0,
          multiBuy: 0,
          dealCostPrice: 0,
          price: 0,
          incrementBy: 0,
          multiBuyNote: "",
          message: "",
          showMerchandizer: 0,
          boxlot: 0,
          availMon: 1,
          availTue: 1,
          availWed: 1,
          availThu: 1,
          availFri: 1,
          availSat: 1,
          availSun: 1,
          deleted: false,
        };

        setAvailabilityData(result);
        setIsLoaded(true);
        setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setIsFetching(false);
        setError(JSON.parse(error.response).error);
      }
    };

    fetchData().catch(console.error);
  }, [setAvailabilityData]);

  const updateItemAvailabilityDataState = useCallback(
    async (availabilityData: ItemAvailabilityViewDTO) => {
      if (availabilityData?.itemAvailability) {
        try {
          setIsSaving(true);

          //TO DO FIX THIS UP NICELY
          availabilityData.itemAvailability.itemName =
            availabilityData.products?.find(
              (product) =>
                product.itemId === availabilityData.itemAvailability?.itemId
            )?.itemName ?? "";

          await new ItemAvailabilityMatrixClient(
            process.env.REACT_APP_API_BASE
          ).saveItemAvailability(availabilityData!);

          navigate("/inventory/availability");
        } catch (e: unknown) {
          const error = e as ApiException;
          console.log(error.message);
          setError(JSON.parse(error.response).error);
          setIsSaving(false);
        }
      }
    },
    [navigate]
  );

  return (
    <div className={"portal-settings"}>
      <NoCards pageTitle="">
        {!isFetching && isLoaded ? (
          <>
            <Col md={12}>
              <h5>New product</h5>
            </Col>
            <Col lg={12}>
              <Card className="shadow-sm p-0" border="light">
                <Card.Body className="p-0 portal-settings-form">
                  {typeof availabilityData?.itemAvailability !== "undefined" &&
                    availabilityData?.itemAvailability && (
                      <ProductAvailabilityForm
                        avilabilityData={availabilityData!}
                        updateAvailabilityData={updateItemAvailabilityDataState}
                        isNew={true}
                        isSaving={isSaving}
                      />
                    )}
                </Card.Body>
              </Card>
            </Col>
          </>
        ) : (
          <CardPlaceholder />
        )}
      </NoCards>
      <Modal show={error?.length! > 0} onHide={() => setError(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setError(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
