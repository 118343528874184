/****************************************************************************************************
 * Imports
 ****************************************************************************************************/
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import {
  ApiException,
  OrderType,
  MarkdownClient,
  OrderDetailForMarkdownDTO,
  StocktakeClient,
} from "../../../services/ApiClient";
import { NoCards } from "../../layouts/NoCards";
import {
  Alert,
  Button,
  Card,
  Col,
  Modal,
  Stack,
  Form,
  InputGroup,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { MarkdownContext } from "./Markdown";
import { MarkdownItem } from "./MarkdownItem";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";

/****************************************************************************************************
 * Interfaces
 ****************************************************************************************************/
interface IEnterMarkdownProps {}
interface IModalDetails {
  title: string;
  message: string;
}

/****************************************************************************************************
 * Component
 ****************************************************************************************************/
export const EnterMarkdown = (props: IEnterMarkdownProps) => {
  //console.log("Rendering EnterMarkdown...");

  /************************************************************************************************
   * Context and other hooks
   ************************************************************************************************/
  const ctx = useContext(MarkdownContext)!;
  const navigate = useNavigate();

  /************************************************************************************************
   * States
   ************************************************************************************************/
  const [isFetching, setIsFetching] = useState(false);
  const [modalDetails, setModalDetails] = useState<IModalDetails>({
    title: "",
    message: "",
  });

  const [markdownDetails, setMarkdownDetails] = useImmer<
    OrderDetailForMarkdownDTO[] | null
  >(null);
  const [totalQty, setTotalQty] = useState<number>(0); // Total item quantity of the order
  const [searchQuery, setSearchQuery] = useState<string>(""); // Add state for search query

  /************************************************************************************************
   * Effects
   ************************************************************************************************/
  // Retrieve products ordered in the last 4 weeks
  useEffect(() => {
    const fetchOrderLineForMarkdown = async () => {
      try {
        setIsFetching(true);

        const storeId = ctx.storeNameDto!.id;
        const orderType: OrderType =
          OrderType[ctx.orderType as keyof typeof OrderType];

        if (ctx.isStocktake) {
          const orderedItems = await new StocktakeClient(
            process.env.REACT_APP_API_BASE
          ).getOrderDetailsForStocktake(storeId, 28, orderType);

          setMarkdownDetails(orderedItems);
        } else {
          const orderedItems = await new MarkdownClient(
            process.env.REACT_APP_API_BASE
          ).getOrderDetailsForMarkdown(storeId, 28, orderType);

          setMarkdownDetails(orderedItems);
        }
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log("fetchOrderLineForMarkdown error: " + error.message);
        setModalDetails({
          title: "Error",
          message: JSON.parse(error.response).errorMessages[0],
        });
      } finally {
        setIsFetching(false);
      }
    };

    fetchOrderLineForMarkdown().catch(console.error);
  }, [ctx.isStocktake, ctx.orderType, ctx.storeNameDto, setMarkdownDetails]);

  /************************************************************************************************
   * Support Functions
   ************************************************************************************************/
  const setItemComment = useCallback(
    (itemId: string, originalPrice: number, comment: string) => {
      setMarkdownDetails((draft) => {
        const itemToUpdate = draft?.find(
          (item) => item.itemId === itemId && item.unitPrice === originalPrice
        );
        if (itemToUpdate) {
          itemToUpdate.comment = comment;
        }
      });
    },
    []
  );

  const setItemPrice = useCallback(
    (itemId: string, originalPrice: number, price: number) => {
      setMarkdownDetails((draft) => {
        const itemToUpdate = draft?.find(
          (item) => item.itemId === itemId && item.unitPrice === originalPrice
        );
        if (itemToUpdate) {
          itemToUpdate.markdownPrice = price;
        }
      });
    },
    []
  );

  const setItemQty = useCallback(
    (itemId: string, originalPrice: number, quantity: number) => {
      setMarkdownDetails((draft) => {
        const itemToUpdate = draft?.find(
          (item) => item.itemId === itemId && item.unitPrice === originalPrice
        );
        if (itemToUpdate) {
          itemToUpdate.quantity = quantity;
        }
      });
    },
    []
  );

  const setItemTotalQty = useCallback((qtyToAdd: number) => {
    setTotalQty((prevQty) => {
      return prevQty + qtyToAdd;
    });
  }, []);

  const addMiscItem = useCallback(async () => {
    try {
      // Find number of existing misc items
      const miscItems = markdownDetails?.filter((item) => item.isMiscItem);
      const miscCount = miscItems ? miscItems.length : 0;

      let itemId: string = "";
      let itemName: string = "";

      switch (ctx.orderType) {
        case "Cuts":
          itemId = "RCMISC:" + (miscCount + 1);
          itemName = "Cuts Miscellaneous";
          break;
        case "Pots":
          itemId = "RPMISC:" + (miscCount + 1);
          itemName = "Pots Miscellaneous";
          break;
        case "Materials":
          itemId = "M-MISC:" + (miscCount + 1);
          itemName = "Materials Miscellaneous";
          break;
      }

      const newMisc: OrderDetailForMarkdownDTO = {
        storeId: ctx.storeNameDto!.id,
        storeName: ctx.storeNameDto!.name,
        itemId: itemId,
        itemName: itemName,
        comment: undefined,
        itemPhoto: "photo_not_available.jpg",
        unitPrice: 0,
        quantity: 0,
        markdownPrice: 0,
        isMiscItem: true,
      };

      setMarkdownDetails((draft) => {
        draft?.push(newMisc);
      });
    } catch (e: unknown) {
      const error = e as ApiException;
      console.log("addMiscItem error: " + error.message);
      setModalDetails({
        title: "Error",
        message: JSON.parse(error.response).errorMessages[0],
      });
    }
  }, [ctx.orderType, ctx.storeNameDto, markdownDetails, setMarkdownDetails]);

  const submitMarkdown = useCallback(async () => {
    try {
      if (ctx.isStocktake) {
        await new StocktakeClient(process.env.REACT_APP_API_BASE).addStocktakes(
          markdownDetails!
        );
        setModalDetails({
          title: "Success",
          message: "Stocktake submitted successfully.",
        });
      } else {
        await new MarkdownClient(process.env.REACT_APP_API_BASE).addMarkdowns(
          markdownDetails!
        );
        setModalDetails({
          title: "Success",
          message: "Markdown/throw-out submitted successfully.",
        });
      }
    } catch (e: unknown) {
      const error = e as ApiException;
      console.log("submitMarkdown error: " + error.message);
      setModalDetails({
        title: "Error",
        message: JSON.parse(error.response).errorMessages[0],
      });
    }
  }, [markdownDetails]);

  // Cancel Buttons
  const goHome = useCallback(() => navigate("/"), []);

  const setItemOriginalPrice = useCallback(
    (itemId: string, originalPrice: number) => {
      setMarkdownDetails((draft) => {
        const itemToUpdate = draft?.find((item) => item.itemId === itemId);
        if (itemToUpdate) {
          itemToUpdate.unitPrice = originalPrice;
        }
      });
    },
    [setMarkdownDetails]
  );

  /************************************************************************************************
   * Render
   ************************************************************************************************/
  return (
    <>
      <NoCards
        pageTitle={
          ctx.storeNameDto != null
            ? `${ctx.isStocktake ? "Stocktake" : "Markdown/Throwout"} for ${ctx.storeNameDto.name}`
            : ctx.isStocktake
              ? "Stocktake"
              : "Markdown/Throwout"
        }
      >
        {!ctx.isStocktake && (
          <Col lg={12}>
            <Alert key={"warning"} variant={"warning"}>
              Only markdown once, only record markdowns once
            </Alert>
          </Col>
        )}
        {ctx.isStocktake && (
          <Col lg={12}>
            <Alert key={"warning"} variant={"warning"}>
              Record markdowns but before new delivery.
            </Alert>
          </Col>
        )}

        <Col lg={12}>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">&#128269;</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Search by product name..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </InputGroup>
        </Col>

        {!isFetching && markdownDetails && markdownDetails.length > 0 ? (
          <>
            {markdownDetails
              .filter((markdownDetail) =>
                markdownDetail.itemName
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              )
              .map((markdownDetail, index) => (
                <Col lg={12} key={`markdownitem${index}`}>
                  <Card className="shadow-sm" border="light">
                    <MarkdownItem
                      itemId={markdownDetail.itemId!}
                      name={markdownDetail.itemName}
                      originalPrice={markdownDetail.unitPrice!}
                      markdownPrice={markdownDetail.markdownPrice}
                      image={markdownDetail.itemPhoto}
                      isMiscItem={
                        markdownDetail.itemName
                          ?.toLowerCase()
                          .includes("Miscellaneous".toLowerCase())!
                      }
                      note={markdownDetail.comment}
                      increment={1}
                      quantity={markdownDetail.quantity}
                      updateItemComment={setItemComment}
                      updateItemPrice={setItemPrice}
                      updateItemQty={setItemQty}
                      updateItemTotalQty={setItemTotalQty}
                      readonlyPrice={ctx.isStocktake}
                      updateOriginalPrice={setItemOriginalPrice}
                    />
                  </Card>
                </Col>
              ))}
          </>
        ) : null}
        {!isFetching &&
        (markdownDetails === null || markdownDetails?.length === 0) ? (
          <Col lg={12}>
            <Card className="shadow-sm" border="light">
              <div className="no-items">No items available.</div>
            </Card>
          </Col>
        ) : null}
        {isFetching ? (
          <Col lg={12}>
            <CardPlaceholder />
          </Col>
        ) : null}
        <Col lg={12} className={"d-flex flex-row-reverse"}>
          <Stack gap={3} direction="horizontal">
            <Button type="button" onClick={() => addMiscItem()}>
              {"Add Misc Item"}
            </Button>
            <LinkContainer to={`/`}>
              <Button type="button" variant="light">
                {"Cancel"}
              </Button>
            </LinkContainer>
            <Button
              type="submit"
              disabled={totalQty <= 0}
              onClick={() => submitMarkdown()}
            >
              {"Confirm"}
            </Button>
          </Stack>
        </Col>
      </NoCards>
      {/* Model to show error or confirmation message. Will always redirect back to home on close */}
      <Modal show={modalDetails.message.length > 0} onHide={() => goHome()}>
        <Modal.Header closeButton>
          <Modal.Title>{modalDetails.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalDetails.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => goHome()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
