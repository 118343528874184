import React, { useEffect, useState } from "react";
import { DeliveryDateDTO } from "../../../services/ApiClient";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

interface IDeliveryDatePickerProps {
  deliveryDates: DeliveryDateDTO[];
  currentValue: string;
  setDeliveryDate: (delvieryDate: DeliveryDateDTO) => void;
  size: "sm" | "lg" | undefined;
}

export const DeliveryDatePicker = (props: IDeliveryDatePickerProps) => {
  const [value, setValue] = useState<string | null>("");

  useEffect(() => {
    const currentDeliveryDate = props.currentValue;

    const nextDeliveryDateAsDateDTO = props.deliveryDates.find(
      (deliveryDate, index) => {
        return (
          deliveryDate.actualValue === currentDeliveryDate.substring(0, 10)
        );
      }
    );
    setValue(nextDeliveryDateAsDateDTO?.actualValue!); //2023-03-17
  }, [props.currentValue, props.deliveryDates]);

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    props.setDeliveryDate(props.deliveryDates[event.target.selectedIndex]);
  };

  return (
    <div className="delivery-picker">
      <InputGroup>
        <InputGroup.Text>Delivery date</InputGroup.Text>
        <Form.Select
          onChange={onChange}
          id={"deliveryDate"}
          size={props.size}
          value={value!}
          title="Select date"
        >
          {props.deliveryDates.map((deliveryDate, index) => (
            <option key={index} value={deliveryDate.actualValue}>
              {deliveryDate.displayValue}
            </option>
          ))}
        </Form.Select>
      </InputGroup>
    </div>
  );
};
