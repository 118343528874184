import React from "react";
import { Form } from "react-bootstrap";

interface OrderTypeDropdownProps {
  onSelect: (category: string) => void;
}

const OrderTypeDropdown: React.FC<OrderTypeDropdownProps> = ({ onSelect }) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onSelect(event.target.value);
  };

  return (
    <Form.Group controlId="categorySelect">
      <Form.Label>Order Type</Form.Label>
      <Form.Select onChange={handleChange} defaultValue="All">
        <option value="All">All</option>
        <option value="Cuts">Cuts</option>
        <option value="Pots">Pots</option>
      </Form.Select>
    </Form.Group>
  );
};

export default OrderTypeDropdown;
