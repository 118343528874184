import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ApiException,
  OrderClient,
  StoreNameDTO,
} from "../../../services/ApiClient";
import { Picker } from "../../common/picker/Picker";
import { SingleCard } from "../../layouts/SingleCard";

interface IStorePickerProps {
  pathStart: string;
  pathEnd: string;
}

export const StorePicker = (props: IStorePickerProps) => {
  const navigate = useNavigate();
  //const [error, setError] = useState<null | string>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  //const [isFetching, setIsFetching] = useState(false);
  const [stores, setStores] = useState<StoreNameDTO[]>([]);

  const [columnDefs] = useState([{ field: "name" }]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //setIsFetching(true);
        const result = await new OrderClient(
          process.env.REACT_APP_API_BASE
        ).getStoreNames();
        setStores(result);
        setIsLoaded(true);
        //setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        //setIsFetching(false);
        //setError(JSON.parse(error.response).error);
      }
    };

    fetchData().catch(console.error);
  }, []);

  const onRowSelected = useCallback(
    (selectedRow: any) => {
      navigate(
        `${props.pathStart}${selectedRow?.id?.toLowerCase()}${props.pathEnd}`,
        {
          state: {
            storeId: selectedRow.id,
            storeName: selectedRow.name,
          },
        }
      );
    },
    [navigate, props.pathEnd, props.pathStart]
  );

  return (
    <SingleCard pageTitle="Select store">
      {isLoaded && (
        <Picker
          rowData={stores}
          columnDefs={columnDefs}
          onRowSelected={onRowSelected}
        />
      )}
    </SingleCard>
  );
};
