import React, { useMemo } from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { ValueFormatterParams, ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

interface IBudgetSummaryProps {
  orderTotal: number;
  wtd: number;
  mtd: number;
  budget: number;
  orderType: string;
  hideTitle: boolean;
}

const formatCurrency = (params: ValueFormatterParams) => {
  if (typeof params.value == "undefined") {
    return `$${0.0}`;
  }

  return `$${params.value.toFixed(2)}`;
};

const formatPercentUsed = (params: ValueFormatterParams) => {
  let percentUsed = 0;

  if (params.data.budget === 0) {
    return "No budget set";
  }

  if (params.data.orderType === "Cuts") {
    percentUsed = (params.data.wtd / params.data.budget) * 100;
  } else {
    percentUsed = (params.data.mtd / params.data.budget) * 100;
  }

  return `${percentUsed.toFixed(2)}%`;
};

export const BudgetSummary = (props: IBudgetSummaryProps) => {
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "This order",
        field: "orderTotal",
        flex: 1,
        valueFormatter: formatCurrency,
      },
      ...(props.orderType === "Cuts"
        ? [
            {
              headerName: "WTD (incl this order)",
              field: "wtd",
              flex: 1,
              valueFormatter: formatCurrency,
            },
          ]
        : []),
      ...(props.orderType === "Pots"
        ? [
            {
              headerName: "MTD",
              field: "mtd",
              flex: 1,
              valueFormatter: formatCurrency,
            },
          ]
        : []),
      {
        headerName: "Budget",
        field: "budget",
        flex: 1,
        valueFormatter: formatCurrency,
      },
      {
        headerName: "% Used",
        field: "percentUsed",
        flex: 1,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        valueFormatter: formatPercentUsed,
      },
    ],
    [props.orderType]
  );

  return (
    <Col lg={12}>
      {!props.hideTitle! && <h5 className="section-heading">Budget summary</h5>}
      <Card className="shadow-sm p-0" border="light">
        <Card.Body className="p-0 available-products-card">
          <div className="ag-theme-alpine ag-row-pointer available-products-container">
            <AgGridReact
              columnDefs={columnDefs}
              rowData={[{ ...props }]}
              domLayout="autoHeight"
            />
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};
