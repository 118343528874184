import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

interface LayoutProps {
  pageTitle: string;
  fluid?: boolean;
  children: React.ReactNode;
  noHeader?: boolean;
}

export class SingleCard extends Component<LayoutProps> {
  static displayName = SingleCard.name;

  render() {
    return (
      <Container fluid={this.props.fluid}>
        <Row className="g-4">
          <Col lg={12}>
            <Row className="g-4">
              {!this.props.noHeader && (
                <Col lg={12}>
                  <h5>{this.props.pageTitle}</h5>
                </Col>
              )}
              <Col lg={12}>
                <Card className="shadow-sm p-0" border="light">
                  <Card.Body className="p-0">{this.props.children}</Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
