import React from "react";
import { Form } from "react-bootstrap";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

// Extend dayjs with the quarter plugin
dayjs.extend(quarterOfYear);

// Define the type for the component's props
interface QuarterDropdownProps {
  onSelect: (quarter: {
    label: string;
    startDate: string;
    endDate: string;
  }) => void;
}

// Create a function to generate the last 12 quarters based on NZ financial year
const getPrevious12Quarters = () => {
  const quarters = [];
  let currentDate = dayjs();

  // Adjust the quarter based on NZ financial year (April start)
  // April-June is Q1, July-Sept is Q2, Oct-Dec is Q3, Jan-March is Q4
  const nzQuarter = Math.floor(((currentDate.month() + 9) % 12) / 3) + 1;
  let currentQuarter = nzQuarter;
  let currentYear = currentDate.year();

  // If we're in Q4 (Jan-March), we're technically in the previous financial year
  if (currentDate.month() < 3) {
    currentYear--;
  }

  for (let i = 0; i < 4; i++) {
    // Calculate actual month based on NZ quarter
    const quarterStartMonth = ((currentQuarter - 1) * 3 + 3) % 12; // +3 to align with April start
    const startDate = dayjs()
      .year(currentYear)
      .month(quarterStartMonth)
      .startOf("month")
      .format("YYYY-MM-DD");

    const endDate = dayjs()
      .year(currentYear)
      .month(quarterStartMonth + 2)
      .endOf("month")
      .format("YYYY-MM-DD");

    quarters.push({
      label: `Q${currentQuarter} ${currentYear}`,
      startDate,
      endDate,
    });

    currentQuarter--;
    if (currentQuarter === 0) {
      currentQuarter = 4;
      currentYear--;
    }
  }

  return quarters;
};

const QuarterDropdown: React.FC<QuarterDropdownProps> = ({ onSelect }) => {
  const quarters = getPrevious12Quarters();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedQuarter = quarters.find(
      (q) => q.label === event.target.value
    );
    if (selectedQuarter) {
      onSelect(selectedQuarter);
    }
  };

  return (
    <Form.Group controlId="quarterSelect">
      <Form.Label>Quarter</Form.Label>
      <Form.Select onChange={handleChange} defaultValue="">
        <option value="" disabled>
          Select a quarter...
        </option>
        {quarters.map((quarter, index) => (
          <option key={index} value={quarter.label}>
            {quarter.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

export default QuarterDropdown;
