import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  ApiException,
  EStoreType,
  ManagerClient,
  OrderType,
  StoreTypeBudgetMultiplier,
} from "../../../services/ApiClient";
import "./BudgetMultipliers.scss";

interface IBudgetMultipliersProps {}

export const BudgetMultipliers = (props: IBudgetMultipliersProps) => {
  const navigate = useNavigate();
  //const [error, setError] = useState<null | string>(null);
  //const [isLoaded, setIsLoaded] = useState(false);
  //const [isFetching, setIsFetching] = useState(false);
  const [rowData, setRowData] = useState<StoreTypeBudgetMultiplier[]>([]);

  const defaultColDef = useMemo(
    () => ({
      sortable: false,
      flex: 1,
    }),
    []
  );

  const dateFormatter = (params: ValueFormatterParams) => {
    return dayjs(params.value).format("DD/MM/YY");
  };

  const orderFormatter = (params: ValueFormatterParams) => {
    return OrderType[params.value];
  };

  const storeTypeFormatter = (params: ValueFormatterParams) => {
    return EStoreType[params.value];
  };

  const EditCellRenderer = (props: ICellRendererParams): JSX.Element => {
    const editClicked = () => {
      navigate(`/settings/budgetmultipliers/${props.value}`);
    };

    return (
      <div className={"btn-container"}>
        <Button size="sm" variant="light" onClick={editClicked}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-pencil-square"
            viewBox="0 0 16 16"
          >
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
            <path
              fillRule="evenodd"
              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
            ></path>
          </svg>
          {" Edit"}
        </Button>
      </div>
    );
  };

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: "Store type",
      field: "storeType",
      valueFormatter: storeTypeFormatter,
    },
    {
      headerName: "Order type",
      field: "ordertype",
      valueFormatter: orderFormatter,
    },
    {
      headerName: "From",
      field: "from",
      valueFormatter: dateFormatter,
    },
    {
      headerName: "To",
      field: "to",
      valueFormatter: dateFormatter,
    },
    {
      headerName: "% increase",
      field: "pctIncrease",
    },
    {
      headerName: "",
      cellRenderer: EditCellRenderer,
      resizable: false,
      maxWidth: 140,
      field: "id",
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //setIsFetching(true);
        const result = await new ManagerClient(
          process.env.REACT_APP_API_BASE
        ).getStoreTypeBudgetMultipliers();
        setRowData(result);
        //setIsLoaded(true);
        //setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        //setIsFetching(false);
        //setError(JSON.parse(error.response).error);
      }
    };

    fetchData().catch(console.error);
  }, []);

  const onRowSelected = useCallback((selectedRow: any) => {
    //navigate(`/stores/${selectedRow?.id?.toLowerCase()}/settings`);
  }, []);

  return (
    <Row className="gy-3">
      <Col md={12}>
        <Stack gap={3} direction="horizontal">
          <h5>Store type budget multipliers</h5>
          <LinkContainer to={"/settings/budgetmultipliers/new"}>
            <Button className="ms-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-plus-lg"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                />
              </svg>{" "}
              New
            </Button>
          </LinkContainer>
        </Stack>
      </Col>
      <Col md={12}>
        <Card className="shadow-sm p-0" border="light">
          <Card.Body className="p-0 portal-settings-form">
            <div className="ag-theme-alpine ag-row-pointer budget-multipliers">
              <AgGridReact
                columnDefs={columnDefs}
                rowData={rowData}
                defaultColDef={defaultColDef}
                domLayout="autoHeight"
                onRowClicked={onRowSelected}
                rowSelection={"single"}
                pagination={false}
                paginationPageSize={100}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
