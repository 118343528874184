import { Formik } from "formik";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import {
  EStoreType,
  ItemAvailabilityViewDTO,
  ItemAvailablity,
} from "../../../services/ApiClient";
import { TableSelect } from "../../common/tableSelect/TableSelect";

interface IProductAvailabilityFormProps {
  avilabilityData: ItemAvailabilityViewDTO;
  updateAvailabilityData: (availabilityData: ItemAvailabilityViewDTO) => void;
  isNew: boolean;
  isSaving: boolean;
}

export const ProductAvailabilityForm = (
  props: IProductAvailabilityFormProps
) => {
  return (
    <div className={"portal-settings-form"}>
      <Formik
        //validationSchema={schema}
        onSubmit={props.updateAvailabilityData}
        //onSubmit={console.log}
        initialValues={props.avilabilityData}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          dirty,
          errors,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="">
              {props.isNew ? (
                <Form.Group className="mb-3">
                  <Form.Label>Product</Form.Label>
                  <TableSelect
                    columnName="Product"
                    columnKey="itemName"
                    returnValue="itemId"
                    values={values.products!.map((product) => ({
                      itemName: product.itemName,
                      itemId: product.itemId,
                    }))}
                    selectedValues={[]}
                    height={330}
                    name={"itemAvailability.itemId"}
                    singleSelect={true}
                    additionalDisplayColumnId={"itemId"}
                    additionalDisplayColumnName={"Item number"}
                  />
                </Form.Group>
              ) : (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label>Item number</Form.Label>
                    <Form.Control
                      type="text"
                      name="itemAvailability.itemNumber"
                      value={
                        values.itemAvailability?.itemId
                          ? values.itemAvailability?.itemId
                          : ""
                      }
                      onChange={handleChange}
                      disabled
                    />
                  </Form.Group>
                </>
              )}
              <Form.Group className="mb-3">
                <Form.Label>Item name</Form.Label>
                <Form.Control
                  type="text"
                  name="itemAvailability.itemName"
                  disabled
                  value={
                    values.itemAvailability?.itemId
                      ? values.products!.find(
                          (product) =>
                            product.itemId === values.itemAvailability?.itemId
                        )?.itemName
                      : ""
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Effective from</Form.Label>
                <Form.Control
                  type="date"
                  name="itemAvailability.effectiveFrom"
                  onChange={handleChange}
                  value={values.itemAvailability?.effectiveFrom?.substring(
                    0,
                    10
                  )}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Effective to</Form.Label>
                <Form.Control
                  type="date"
                  name="itemAvailability.effectiveTo"
                  onChange={handleChange}
                  value={values.itemAvailability?.effectiveTo?.substring(0, 10)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Order day</Form.Label>
                <div key={`inline-checkbox`} className="">
                  <Form.Check
                    inline
                    label={"Mon"}
                    name={"itemAvailability.availMon"}
                    type={"checkbox"}
                    //id={`checkbox-availMon`}
                    defaultChecked={values.itemAvailability?.availMon! > 0}
                    onChange={(e) =>
                      e.target.checked
                        ? setFieldValue(e.target.name, 1)
                        : setFieldValue(e.target.name, 0)
                    }
                  />
                  <Form.Check
                    inline
                    label={"Tue"}
                    name={"itemAvailability.availTue"}
                    type={"checkbox"}
                    id={`checkbox-availTue`}
                    defaultChecked={values.itemAvailability?.availTue! > 0}
                    onChange={(e) =>
                      e.target.checked
                        ? setFieldValue(e.target.name, 1)
                        : setFieldValue(e.target.name, 0)
                    }
                  />
                  <Form.Check
                    inline
                    label={"Wed"}
                    name={"itemAvailability.availWed"}
                    type={"checkbox"}
                    id={`checkbox-availWed`}
                    defaultChecked={values.itemAvailability?.availWed! > 0}
                    onChange={(e) =>
                      e.target.checked
                        ? setFieldValue(e.target.name, 1)
                        : setFieldValue(e.target.name, 0)
                    }
                  />
                  <Form.Check
                    inline
                    label={"Thu"}
                    name={"itemAvailability.availThu"}
                    type={"checkbox"}
                    id={`checkbox-availThu`}
                    defaultChecked={values.itemAvailability?.availThu! > 0}
                    onChange={(e) =>
                      e.target.checked
                        ? setFieldValue(e.target.name, 1)
                        : setFieldValue(e.target.name, 0)
                    }
                  />
                  <Form.Check
                    inline
                    label={"Fri"}
                    name={"itemAvailability.availFri"}
                    type={"checkbox"}
                    id={`checkbox-availFri`}
                    defaultChecked={values.itemAvailability?.availFri! > 0}
                    onChange={(e) =>
                      e.target.checked
                        ? setFieldValue(e.target.name, 1)
                        : setFieldValue(e.target.name, 0)
                    }
                  />
                  <Form.Check
                    inline
                    label={"Sat"}
                    name={"itemAvailability.availSat"}
                    type={"checkbox"}
                    id={`checkbox-availSat`}
                    defaultChecked={values.itemAvailability?.availSat! > 0}
                    onChange={(e) =>
                      e.target.checked
                        ? setFieldValue(e.target.name, 1)
                        : setFieldValue(e.target.name, 0)
                    }
                  />
                  <Form.Check
                    inline
                    label={"Sun"}
                    name={"itemAvailability.availSun"}
                    type={"checkbox"}
                    id={`checkbox-availSun`}
                    defaultChecked={values.itemAvailability?.availSun! > 0}
                    onChange={(e) =>
                      e.target.checked
                        ? setFieldValue(e.target.name, 1)
                        : setFieldValue(e.target.name, 0)
                    }
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Item availability</Form.Label>
                <Form.Select
                  onChange={handleChange}
                  id={"itemAvailability.itemAvailability"}
                  name={"itemAvailability.itemAvailability"}
                  value={values.itemAvailability?.itemAvailability}
                >
                  {Object.keys(ItemAvailablity)
                    .filter((x) => !parseInt(x) && x !== "0")
                    .map(
                      (
                        key: string //no clue wtf typescript is doing here
                      ) => (
                        <option
                          key={key}
                          value={
                            ItemAvailablity[key as keyof typeof ItemAvailablity]
                          }
                        >
                          {key}
                        </option>
                      )
                    )}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Price</Form.Label>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    name={"itemAvailability.price"}
                    type="number"
                    placeholder="0.00"
                    defaultValue={values.itemAvailability?.price}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Deal cost price</Form.Label>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    name={"itemAvailability.dealCostPrice"}
                    type="number"
                    placeholder="0.00"
                    defaultValue={values.itemAvailability?.dealCostPrice}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Increment by</Form.Label>
                <Form.Control
                  name={"itemAvailability.incrementBy"}
                  type="number"
                  placeholder="0.00"
                  defaultValue={values.itemAvailability?.incrementBy}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Box lot</Form.Label>
                <Form.Check
                  name={"itemAvailability.boxlot"}
                  type={"checkbox"}
                  id={`checkbox-box-lot`}
                  defaultChecked={values.itemAvailability?.boxlot! > 0}
                  onChange={(e) =>
                    e.target.checked
                      ? setFieldValue(e.target.name, 1)
                      : setFieldValue(e.target.name, 0)
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Special</Form.Label>
                <Form.Check
                  name={"itemAvailability.special"}
                  type={"checkbox"}
                  id={`checkbox-special`}
                  defaultChecked={values.itemAvailability?.special! > 0}
                  onChange={(e) =>
                    e.target.checked
                      ? setFieldValue(e.target.name, 1)
                      : setFieldValue(e.target.name, 0)
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Multibuy</Form.Label>
                <Form.Check
                  name={"itemAvailability.multiBuy"}
                  type={"checkbox"}
                  id={`checkbox-special`}
                  defaultChecked={values.itemAvailability?.multiBuy! > 0}
                  onChange={(e) =>
                    e.target.checked
                      ? setFieldValue(e.target.name, 1)
                      : setFieldValue(e.target.name, 0)
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Multibuy note</Form.Label>
                <Form.Control
                  name={"itemAvailability.multiBuyNote"}
                  type="text"
                  disabled={values.itemAvailability?.multiBuy! <= 0}
                  defaultValue={values.itemAvailability?.multiBuyNote}
                  onChange={handleChange}
                />
              </Form.Group>
              {/*<Form.Group className="mb-3">
                <Form.Label >Show merchandiser</Form.Label>
                <Form.Check
                  name={'itemAvailability.showMerchandizer'}
                  type={"checkbox"}
                  id={`checkbox-show-merchandiser`}
                  defaultChecked={values.itemAvailability?.showMerchandizer! > 0}
                  onChange={e => (e.target.checked) ? setFieldValue(e.target.name, 1) : setFieldValue(e.target.name, 0)}
                />
              </Form.Group>
              */}
              <Form.Group className="mb-3">
                <Form.Label>Merchandiser message</Form.Label>
                <Form.Control
                  name={"itemAvailability.message"}
                  as="textarea"
                  rows={3}
                  defaultValue={values.itemAvailability?.message ?? ""}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Store type</Form.Label>
                <TableSelect
                  columnName="Store type"
                  columnKey="storeType"
                  returnValue="storeTypeIndex"
                  values={Object.keys(EStoreType)
                    .filter((x) => !parseInt(x) && x !== "0")
                    .map(
                      (
                        key: string //no clue wtf typescript is doing here
                      ) => ({
                        storeType: key,
                        storeTypeIndex:
                          EStoreType[key as keyof typeof EStoreType],
                      })
                    )}
                  selectedValues={values.selectedStoreTypes!}
                  height={300}
                  name="selectedStoreTypes"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Sub segment</Form.Label>
                <TableSelect
                  columnName="Sub segment"
                  columnKey="segmentName"
                  returnValue="segmentName"
                  values={values.subSegments!.map((segment) => ({
                    segmentName: segment,
                  }))}
                  selectedValues={values.selectedSubSegments!}
                  height={300}
                  name="selectedSubSegments"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Store</Form.Label>
                <TableSelect
                  columnName="Stores"
                  columnKey="storeName"
                  returnValue="storeId"
                  values={values.stores!.map((store) => ({
                    storeName: store.name,
                    storeId: store.id,
                  }))}
                  selectedValues={values.selectedStores!}
                  height={500}
                  name="selectedStores"
                />
              </Form.Group>
              <Form.Group>
                <Stack gap={3} direction="horizontal">
                  <Button type="submit" disabled={props.isSaving || !dirty}>
                    {props.isSaving ? "Saving" : "Save"}
                  </Button>
                  <Button type="button" variant="light">
                    {"Cancel"}
                  </Button>
                </Stack>
              </Form.Group>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

/*
    recId: number;
    itemId: string;
    itemName: string;
    effectiveFrom: Date;
    effectiveTo: Date;
    itemAvailability: number;
    special: number;
    multiBuy: number;
    dealCostPrice: number;
    incrementBy: number;
    multiBuyNote: string;
    message: string;
    showMerchandizer: number;
    boxlot: number;
    availMon: number;
    availTue: number;
    availWed: number;
    availThu: number;
    availFri: number;
    availSat: number;
    availSun: number;

    */
