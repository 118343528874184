import { useMsal } from "@azure/msal-react";
import React, { useContext, useEffect, useState } from "react";
import { RoleContext } from "../../../App";
import { UserClient, UserRole } from "../../../services/ApiClient";
import {
  checkRolesInStorage,
  getRolesFromStorage,
  setRolesInStorage,
} from "../../../ultilities/StorageUtils";

interface IRouteGuardProps {
  requiredRoles: UserRole[];
  children: React.ReactNode;
}

export const RouteGuard = (props: IRouteGuardProps) => {
  const { instance } = useMsal();
  const [isAuthorised, setIsAuthorised] = useState(false);
  const [authorising, setAuthorising] = useState(true);
  const roleContext = useContext(RoleContext);

  useEffect(() => {
    const getRoles = async () => {
      const activeAccount =
        instance.getActiveAccount() || instance.getAllAccounts()[0];

      if (!checkRolesInStorage(activeAccount)) {
        const rolesResult = (await new UserClient(
          process.env.REACT_APP_API_BASE
        ).getCurrentUserRole()) as any; //typings not correct in API clinet

        //currently only returns a sinlge role
        if (rolesResult) {
          roleContext!.setRole!(rolesResult.role);
          setRolesInStorage(activeAccount, [rolesResult.role]);
        }
      }

      const hasRequiredRole = props.requiredRoles.some(
        (group: UserRole) => getRolesFromStorage(activeAccount)?.includes(group)
      );

      setIsAuthorised(hasRequiredRole);
      setAuthorising(false);
    };

    getRoles();
  }, [instance, props.requiredRoles, roleContext]);

  return (
    <>
      {!authorising && (
        <>
          {isAuthorised ? (
            <div>{props.children}</div>
          ) : (
            <div className="data-area-div">
              <h3>You are unauthorised to view this content.</h3>
            </div>
          )}
        </>
      )}
    </>
  );
};
