import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useImmer } from "use-immer";
import {
    ApiException,
    ItemAvailabilityMatrixClient,
    ItemDTO,
    OrderClient,
    OrderDTO,
    OrderDetailDTO,
    Pickpackstate,
} from "../../../services/ApiClient";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { NoCards } from "../../layouts/NoCards";
import { ConfirmPackModal } from "./ConfirmPackModal";
import OrderHeader from "./OrderHeader";
import "./PickOrder.scss";
import { PickOrderTable } from "./PickOrderTable";

interface IPickOrderProps {
    picking: boolean;
    packing: boolean;
}

export const PickOrder = (props: IPickOrderProps) => {
    //const [order, setOrder] = useImmer<OrderDetailDTO[] | null>(null);
    const [orderData, setOrderData] = useImmer<OrderDTO | null>(null);
    const params = useParams();
    //const [error, setError] = useState<null | string>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    //const [isSaving, setIsSaving] = useState(false);
    const [availableProducts, setAvailableProducts] = useState<ItemDTO[]>([]);
    const [selectedItem, setSelectedItem] = useState<ItemDTO | null>(null);
    const [addingItem, setAddingItem] = useState(false);
    const [isButtonEnabled, setButtonEnabled] = useState(false);
    const [isCompleteClicked, setCompleteClicked] = useState(false);
    //const [boxesPacked, setBoxesPacked] = useState<number | null>(null);
    const navigate = useNavigate();
    const gridRef = useRef<AgGridReact>(null);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsFetching(true);

                const { orderID } = params;
                const result = await new OrderClient().getOrder(orderID as unknown as number);

                if (result && result.orderDetails) {
                    const orderDetails = result.orderDetails;

                    if (orderDetails) {
                        setOrderData(result);
                        const allAvailItems = await new ItemAvailabilityMatrixClient().getAvailableItems(
                            result.orderType,
                            result.deliveryDate
                        );

                        let filteredItems = allAvailItems.filter((item) => {
                            const itemExists = orderDetails.find((orderItem) => orderItem.item?.recId === item.recId);
                            return !itemExists;
                        });

                        setAvailableProducts(filteredItems);
                    }
                }

                //mark as packing or picking
                let pickPackState;

                if (props.picking) {
                    pickPackState = Pickpackstate.Picking;
                } else if (props.packing) {
                    pickPackState = Pickpackstate.Packing;
                }

                await new OrderClient().pickPackOrder(Number(orderID!), pickPackState);

                setIsLoaded(true);
                setIsFetching(false);
            } catch (e: unknown) {
                const error = e as ApiException;
                console.log(error.message);
                setIsFetching(false);
                //setError(JSON.parse(error.response).error);
            }
        };

        fetchData().catch(console.error);
    }, [params, props.packing, props.picking, setOrderData]);

    useEffect(() => {
        if (orderData) {
            if (props.picking) {
                let initialButtonEnabledValues = orderData.orderDetails.map((orderItem) => {
                    const isDeleted = orderItem.deleted; // Check if CreditRequestDTO exists
                    const isPicked = orderItem.pickPackStatus === 1; // Check received status

                    return isDeleted || isPicked;
                });

                setButtonEnabled(initialButtonEnabledValues.every((value) => value === true));
            } else if (props.packing) {
                let initialButtonEnabledValues = orderData.orderDetails.map((orderItem) => {
                    const isDeleted = orderItem.deleted; // Check if CreditRequestDTO exists
                    const isPicked = orderItem.pickPackStatus === 2; // Check received status

                    return isDeleted || isPicked;
                });

                setButtonEnabled(initialButtonEnabledValues.every((value) => value === true));
            }
        }
    }, [orderData, props.packing, props.picking]);

    const getEmptyOrderDetailDTO = (currentItem: ItemDTO, orderId: number | undefined): OrderDetailDTO | undefined => {
        if (currentItem && orderId) {
            return {
                orderDetailId: 0,
                orderId: orderId,
                item: currentItem,
                quantity: 0,
                unitPrice: 0,
                total: 0,
                pickPackStatus: 0,
                received: false,
                deleted: false,
                itemId: Number(currentItem.recId),
                preOrder: 0,
                dealCostPrice: 0,
                price: 0,
            };
        } else {
            return undefined;
        }
    };

    //const updatePickedStatus = async (currentOrder: OrderDetailDTO) => {
    //    const order = await new OrderClient().pickPackOrderDetail(currentOrder);
    //    setOrder(order.orderDetails);
    //};

    const navigateNext = useCallback(async () => {
        if (props.picking) {
            await new OrderClient().releasePickPackOrder(orderData?.orderId, Pickpackstate.Picking);

            //Track previous item
            searchParams.set("PreviousItemId", orderData?.orderId.toString()!);

            navigate({
                pathname: `/pick/next`,
                search: `${searchParams.toString()}`,
            });
        } else if (props.packing) {
            await new OrderClient().releasePickPackOrder(orderData?.orderId, Pickpackstate.Packing);

            navigate({
                pathname: `/pack/next`,
                search: `${searchParams.toString()}`,
            });
        }
    }, [navigate, orderData?.orderId, props.packing, props.picking, searchParams]);

    const navigateBack = useCallback(async () => {
        if (props.picking) {
            await new OrderClient().releasePickPackOrder(orderData?.orderId, Pickpackstate.Picking);
            navigate({
                pathname: `/pick`,
                search: `${searchParams.toString()}`,
            });
        } else if (props.packing) {
            await new OrderClient().releasePickPackOrder(orderData?.orderId, Pickpackstate.Packing);

            navigate({
                pathname: `/pack`,
                search: `${searchParams.toString()}`,
            });
        }
    }, [navigate, orderData?.orderId, props.packing, props.picking, searchParams]);

    const handleItemSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedItemId = Number(e.target.value);
        const selectedItem = availableProducts.find((item) => item.recId === selectedItemId);
        if (selectedItem) {
            setSelectedItem(selectedItem);
        }
    };

    const handleAddItemClick = useCallback(async () => {
        if (selectedItem && orderData) {
            setAddingItem(true);
            const newOrderDetail = getEmptyOrderDetailDTO(selectedItem, orderData.orderId);

            let newOrderDetailDTO = await new OrderClient().saveOrderDetail(newOrderDetail as OrderDetailDTO);

            let itemToAdd = newOrderDetailDTO.orderDetails.find((item) => {
                return item!.item?.recId === selectedItem.recId;
            });

            if (itemToAdd) {
                setOrderData((draft) => {
                    draft!.orderDetails.push(itemToAdd!);
                });

                let filteredItems = availableProducts.filter((item) => {
                    return !(itemToAdd!.item?.recId === item.recId);
                });

                setAvailableProducts(filteredItems);

                setAddingItem(false);
            }
        }
    }, [availableProducts, orderData, selectedItem, setOrderData]);

    const handlePickOnly = async () => {
        const orderAfterPick = await new OrderClient().pickPackOrder(
            orderData?.orderId,
            Pickpackstate.Picked,
            undefined
        );

        setOrderData(orderAfterPick);

        handleSave();
    };

    const handleSave = async () => {
        const orderDetailsArray: OrderDetailDTO[] = [];

        if (gridRef.current) {
            const api = gridRef.current.api;

            api?.forEachNode((node) => {
                const data = node.data as OrderDetailDTO;
                orderDetailsArray.push(data);
            });

            const savedOrder = await new OrderClient().saveOrderDetailList(orderDetailsArray, orderData?.orderId);

            setOrderData(savedOrder);
        }
        navigateNext();
    };

    const handlePickAndPack = async (noBoxes: number) => {
        const orderDetailsArray: OrderDetailDTO[] = [];

        if (gridRef.current) {
            const api = gridRef.current.api;

            api?.forEachNode((node) => {
                const data = node.data as OrderDetailDTO;
                orderDetailsArray.push(data);
            });

            await new OrderClient().saveOrderDetailList(orderDetailsArray, orderData?.orderId);

            await new OrderClient().pickPackOrder(orderData?.orderId, Pickpackstate.Packed, noBoxes);
        }

        setCompleteClicked(false);
        navigateNext();
    };

    /*const handlePack = async (noBoxes: number) => {
    const orderAfterPack = await new OrderClient().pickPackOrder(
      orderData?.orderId,
      Pickpackstate.Packed,
      noBoxes
    );
    setOrderData(orderAfterPack);

    const orderDetailsArray: OrderDetailDTO[] = [];

    if (gridRef.current) {
      const api = gridRef.current.api;

      api?.forEachNode((node) => {
        const data = node.data as OrderDetailDTO;
        orderDetailsArray.push(data);
      });

      const savedOrder = await new OrderClient().saveOrderDetailList(
        orderDetailsArray,
        orderData?.orderId
      );

      setOrderData(savedOrder);
    }

    setCompleteClicked(false);
    navigateBack();
  };*/

    //const cancel = useCallback(async () => {
    //  if (props.picking) {
    //  } else if (props.packing) {
    //  }
    // }, [props.packing, props.picking]);

    return (
        <div className={"pick-order"}>
            <NoCards pageTitle="">
                {!isFetching && isLoaded && orderData ? (
                    <>
                        <Col lg={12}>
                            <OrderHeader order={orderData} />
                        </Col>
                        <Col lg={12}>
                            <Card className="shadow-sm" border="light">
                                <Card.Body className="p-0">
                                    <PickOrderTable
                                        orderId={orderData.orderId}
                                        order={orderData.orderDetails}
                                        setOrdersData={setOrderData}
                                        allSelected={isButtonEnabled}
                                        gridRef={gridRef}
                                        picking={props.picking}
                                        packing={props.packing}
                                    />
                                    <Col lg={12}>
                                        <Row className="align-items-center">
                                            <Col md={12}>
                                                <Stack direction="horizontal" gap={3}>
                                                    <div className="p-2">
                                                        <select
                                                            title="Select an item"
                                                            onChange={handleItemSelect}
                                                            value={selectedItem?.recId || ""}
                                                            className="form-select"
                                                        >
                                                            <option value="">Select an item</option>
                                                            {availableProducts.map((item) => (
                                                                <option key={item.recId} value={item.recId}>
                                                                    {item.itemName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="p-2">
                                                        <Button
                                                            onClick={handleAddItemClick}
                                                            variant="primary"
                                                            disabled={!selectedItem || addingItem}
                                                        >
                                                            {addingItem ? "Adding" : "Add Item"}
                                                        </Button>
                                                    </div>
                                                </Stack>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="gap-2  d-flex flex-wrap justify-content-end">
                            <Button
                                onClick={() => {
                                    navigateBack();
                                }}
                            >
                                Discard
                            </Button>
                            <Button
                                onClick={() => {
                                    handleSave();
                                }}
                            >
                                Save
                            </Button>
                            {props.picking && (
                                <>
                                    <Button onClick={handlePickOnly} disabled={!isButtonEnabled}>
                                        Pick only
                                    </Button>
                                    <Button onClick={() => setCompleteClicked(true)} disabled={!isButtonEnabled}>
                                        Pick and Pack
                                    </Button>
                                </>
                            )}
                            {props.packing && (
                                <>
                                    <Button onClick={() => setCompleteClicked(true)} disabled={!isButtonEnabled}>
                                        Complete
                                    </Button>
                                </>
                            )}
                        </Col>
                    </>
                ) : (
                    <CardPlaceholder />
                )}
            </NoCards>
            <ConfirmPackModal
                show={isCompleteClicked}
                onHide={() => setCompleteClicked(false)}
                handlePickAndPack={handlePickAndPack}
            />
        </div>
    );
};
