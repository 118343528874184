import {
  ColDef,
  RowClickedEvent,
  ValueFormatterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";
import { useCallback, useMemo, useRef } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { useNavigate } from "react-router-dom";
import {
  CreditRequestDTO,
  CreditrequestStatus,
} from "../../../services/ApiClient";
import Nav from "react-bootstrap/Nav";

interface IMerchandiseCreditRequestsTableProps {
  creditRequestData: CreditRequestDTO[];
  onNavItemClicked(eventKey: string): void;
  menuState: string;
}

export const MerchandiseCreditRequestsTable = (
  props: IMerchandiseCreditRequestsTableProps
) => {
  const navigate = useNavigate();
  //const [error, setError] = useState<null | string>(null);
  //const [isLoaded, setIsLoaded] = useState(false);
  //const [isFetching, setIsFetching] = useState(false);
  //const [rowData, setRowData] = useState<any[]>([]);
  const gridRef = useRef<AgGridReact<any>>(null);

  const onRowSelected = useCallback(
    (selectedRow: RowClickedEvent) => {
      const creditrequestdata = selectedRow.data;

      navigate(
        `/MerchandiseCreditRequests/${creditrequestdata.creditrequestid}`
      );
    },
    [navigate]
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
    }),
    []
  );

  const dateFormatter = useCallback((params: ValueFormatterParams) => {
    return dayjs(params.value).format("DD/MM/YY");
  }, []);

  const statusFormatter = useCallback((params: ValueFormatterParams) => {
    return CreditrequestStatus[params.value];
  }, []);

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Status",
        field: "status",
        minWidth: 100,
        maxWidth: 100,
        valueFormatter: statusFormatter,
      },
      {
        headerName: "Date",
        field: "requestedDate",
        minWidth: 100,
        maxWidth: 100,
        valueFormatter: dateFormatter,
      },
      {
        headerName: "Store",
        field: "storeName",
        minWidth: 180,
        maxWidth: 250,
      },
      {
        headerName: "Product Name",
        field: "productName",
        minWidth: 150,
        maxWidth: 250,
        multiLine: true,
      },
      {
        headerName: "Quantity",
        field: "quantity",
        minWidth: 110,
        maxWidth: 110,
      },
      {
        headerName:
          props.menuState === "pending" ? "Comment" : "Approve/Reject Reason",
        field: props.menuState === "pending" ? "comment" : "rejectReason",
        flex: 1,
        multiLine: true,
      },
    ],
    [dateFormatter, statusFormatter, props.menuState]
  );

  return (
    <Row className="gy-3">
      <Col md={12}>
        <Stack gap={3} direction="horizontal">
          <h5>Your Credit Requests</h5>
        </Stack>
      </Col>
      <Col md={12}>
        <Card className="p-0">
          <Card.Header>
            <Nav
              variant="tabs"
              defaultActiveKey={props.menuState}
              onSelect={(e) => props.onNavItemClicked(e!)}
            >
              <Nav.Item>
                <Nav.Link eventKey={"pending"}>Pending</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={"complete"}>
                  Approved/Rejected (90 days)
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Card.Body className="p-0 available-products-card border-light shadow-sm">
            <div className="ag-theme-alpine ag-row-pointer available-products-container">
              <AgGridReact
                columnDefs={columnDefs}
                rowData={props.creditRequestData}
                defaultColDef={defaultColDef}
                domLayout="autoHeight"
                onRowClicked={onRowSelected}
                ref={gridRef}
                rowSelection={"single"}
                pagination={true}
                paginationPageSize={10}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
