import { useAccount, useMsal } from "@azure/msal-react";
import { ColDef, RowStyle } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Form, InputGroup, Stack } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    ApiException,
    DeliveryDateDTO,
    OrderClient,
    OrderDTO,
    OrderType,
    Pickpackstate,
    UserRole,
} from "../../../services/ApiClient";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { DeliveryDatePicker } from "../../common/deliveryDatePicker/DeliveryDatePicker";
import { DeliveryModePicker } from "../../common/deliveryModePicker/DeliveryModePicker";
import { NavButtonRenderer } from "../../common/navButtonRenderer/NavButtonRenderer";
import { NoCards } from "../../layouts/NoCards";
import { SingleCard } from "../../layouts/SingleCard";
import { PickPackStatsTable } from "../pickPageStats/PickPackStatsTable";
import "./PickOrders.scss";
import { ReleaseButtonRenderer } from "../../common/navButtonRenderer/ReleaseButtonRenderer";
import { RoleContext } from "../../../App";

interface IPickOrderProps {
    picking: boolean;
    packing: boolean;
}

export const PickOrders = (props: IPickOrderProps) => {
    //const [error, setError] = useState<null | string>(null);
    //const [isLoaded, setIsLoaded] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [orders, setOrders] = useState<OrderDTO[] | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [deliveryModeOptions, setDeliveryModeOptions] = useState<string[]>(["ALL"]);
    const [possibleDeliveryDates, setPossibleDeliveryDates] = useState<DeliveryDateDTO[] | undefined>(undefined);
    const [currentDeliveryMode, setCurrentDeliveryMode] = useState<string | null>("ALL");
    const [currentDeliveryDate, setCurrentDeliveryDate] = useState<string | null>(searchParams.get("DeliveryDate"));
    const [currentDeliveryType, setCurrentDeliveryType] = useState<OrderType>(
        OrderType[searchParams.get("OrderType") as keyof typeof OrderType] ?? OrderType.Cuts
    );

    const [columnDefs, setColumnDefs] = useState<ColDef[] | undefined>();

    const navigate = useNavigate();

    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const navigateToPickOrder = useCallback(
        async (data: OrderDTO) => {
            if (props.picking) {
                await new OrderClient().pickPackOrder(data?.orderId, Pickpackstate.Picking, data.packedBox);
                navigate({
                    pathname: `/pick/${data.orderId}`,
                    search: `${searchParams.toString()}`,
                });
            } else if (props.packing) {
                await new OrderClient().pickPackOrder(data?.orderId, Pickpackstate.Packing, data.packedBox);
                navigate({
                    pathname: `/pack/${data.orderId}`,
                    search: `${searchParams.toString()}`,
                });
            }
        },
        [props.picking, props.packing, navigate, searchParams]
    );

    const role = useContext(RoleContext)?.role;

    const releasePicking = useCallback(
        async (data: OrderDTO) => {
            await new OrderClient().releasePicking(data?.orderId);
            await updatePickPackData();
        },
        [props.picking, props.packing, navigate, searchParams]
    );

    const releasePacking = useCallback(
        async (data: OrderDTO) => {
            await new OrderClient().releasePacking(data?.orderId);
            await updatePickPackData();
        },
        [props.picking, props.packing, navigate, searchParams]
    );

    useEffect(() => {
        setColumnDefs([
            {
                headerName: "Customer",
                field: "store.name",
                flex: 2,
            },
            {
                headerName: "Order Amount",
                field: "total",
                flex: 1,
            },
            ...(props.picking
                ? [
                      {
                          headerName: "Picker",
                          field: "pickingBy",
                          flex: 1,
                      },
                      {
                          headerName: "",
                          field: "orderId",
                          flex: 0.5,
                          cellRenderer: NavButtonRenderer,
                          cellRendererParams: {
                              label: "Pick",
                              navigate: navigateToPickOrder,
                          },
                      },
                      role === UserRole.Manager
                          ? {
                                headerName: "",
                                field: "orderId",
                                flex: 0.5,
                                cellRenderer: ReleaseButtonRenderer,
                                cellRendererParams: {
                                    label: "Release",
                                    email: account?.username,
                                    navigate: releasePicking,
                                },
                            }
                          : {},
                  ]
                : []),
            ...(props.packing
                ? [
                      {
                          headerName: "Packer",
                          field: "packingBy",
                          flex: 1,
                      },
                      {
                          headerName: "",
                          field: "orderId",
                          flex: 1,
                          cellRenderer: NavButtonRenderer,
                          cellRendererParams: {
                              label: "Pack",
                              navigate: navigateToPickOrder,
                          },
                      },
                      role === UserRole.Manager
                          ? {
                                headerName: "",
                                field: "orderId",
                                flex: 0.5,
                                cellRenderer: ReleaseButtonRenderer,
                                cellRendererParams: {
                                    label: "Release",
                                    email: account?.username,
                                    navigate: releasePacking,
                                },
                            }
                          : {},
                  ]
                : []),
        ]);
    }, [account, navigateToPickOrder, props.packing, props.picking, releasePicking, releasePacking]);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            lockPinned: true, // Dont allow pinning for this example
            suppressMovable: true,
        };
    }, []);

    const getRowStyle = (params: any): RowStyle => {
        if (params.data.picked && params.data.packingBy !== account?.username && params.data.packingBy !== null) {
            return { opacity: 0.5, pointerEvents: "none" };
        } else if (
            !params.data.picked &&
            params.data.pickingBy !== account?.username &&
            params.data.pickingBy !== null
        ) {
            return { opacity: 0.5, pointerEvents: "none" };
        }

        return {};
    };

    const updatePickPackData = useCallback(async () => {
        try {
            setIsFetching(true);

            let result;

            if (props.picking) {
                result = await new OrderClient(process.env.REACT_APP_API_BASE).getUnpickedOrders(
                    currentDeliveryType,
                    currentDeliveryMode ?? undefined,
                    currentDeliveryDate ?? undefined
                );
            } else if (props.packing) {
                result = await new OrderClient(process.env.REACT_APP_API_BASE).unpackedOrders(
                    currentDeliveryType,
                    currentDeliveryMode ?? undefined
                );
            }

            setOrders(result?.orders!);
            setPossibleDeliveryDates(result?.deliveryDates);
            setDeliveryModeOptions(result?.deliveryModeOptions!);

            if (!currentDeliveryDate) {
                setCurrentDeliveryDate(result?.deliveryDate ?? null);
            }

            //setIsLoaded(true);
            setIsFetching(false);
        } catch (e: unknown) {
            const error = e as ApiException;
            console.log(error.message);
            setIsFetching(false);
            //setError(JSON.parse(error.response).error);
        }
    }, [props, currentDeliveryType, currentDeliveryDate, currentDeliveryMode]);

    useEffect(() => {
        updatePickPackData();
    }, [currentDeliveryType, currentDeliveryDate, currentDeliveryMode, updatePickPackData]);

    useEffect(() => {
        if (currentDeliveryDate && props.picking) {
            const currentParam = searchParams.get("DeliveryDate");
            if (currentParam !== currentDeliveryDate) {
                setSearchParams((params) => {
                    params.set(
                        "DeliveryDate",
                        currentDeliveryDate.substring(0, 10)
                        //dayjs(currentDeliveryDate).format("DD-MM-YYYY") can't use dayjs as C# format incompatible
                    );
                    return params;
                });
            }
        }
    }, [currentDeliveryDate, props.picking, searchParams, setSearchParams]);

    useEffect(() => {
        if (currentDeliveryMode) {
            const currentParam = searchParams.get("DeliveryMode");
            const deliveryModeString = currentDeliveryMode;

            if (currentParam !== deliveryModeString) {
                setSearchParams((params) => {
                    params.set("DeliveryMode", deliveryModeString);
                    return params;
                });
            }
        }
    }, [currentDeliveryMode, searchParams, setSearchParams]);

    useEffect(() => {
        if (currentDeliveryType) {
            const currentParam = searchParams.get("DeliveryType");
            const orderType = OrderType[currentDeliveryType];

            if (currentParam !== orderType) {
                setSearchParams((params) => {
                    params.set("OrderType", orderType);
                    return params;
                });
            }
        }
    }, [currentDeliveryType, searchParams, setSearchParams]);

    const setDeliveryDate = useCallback(
        (delvieryDate: DeliveryDateDTO) => {
            setCurrentDeliveryDate(delvieryDate.actualValue);
        },
        [setCurrentDeliveryDate]
    );

    const setDeliveryMode = useCallback(
        (deliveryMode: string) => {
            setCurrentDeliveryMode(deliveryMode);
        },
        [setCurrentDeliveryMode]
    );

    return (
        <NoCards>
            <Row className={"g-4 pick-orders"}>
                <Col lg={12} className={"gx-5"}>
                    <h3>Pick/Pack Orders</h3>
                </Col>
                <Col md={12} lg={3} className={"gx-5 gy-3"}>
                    <InputGroup>
                        <InputGroup.Text>Order type</InputGroup.Text>
                        <Form.Select
                            onChange={(e) =>
                                setCurrentDeliveryType(OrderType[e.target.value as keyof typeof OrderType])
                            }
                            id={"deliveryType"}
                            size={"lg"}
                            defaultValue={OrderType[currentDeliveryType]}
                        >
                            <option key={OrderType.Cuts} value={"Cuts"}>
                                Cuts
                            </option>
                            <option key={OrderType.Materials} value={"Materials"}>
                                Materials
                            </option>
                        </Form.Select>
                    </InputGroup>
                </Col>
                <>
                    <Col md={12} lg={3} className={"gx-5 gy-3"}>
                        <DeliveryModePicker
                            selected={currentDeliveryMode ?? "ALL"}
                            size="lg"
                            setDeliveryMode={setDeliveryMode}
                            defaultValue="ALL"
                            options={deliveryModeOptions}
                        />
                    </Col>
                    {!props.packing && possibleDeliveryDates && currentDeliveryDate && (
                        <>
                            <Col md={12} lg={4} className={"gx-5 gy-3"}>
                                <DeliveryDatePicker
                                    deliveryDates={possibleDeliveryDates}
                                    currentValue={currentDeliveryDate}
                                    setDeliveryDate={setDeliveryDate}
                                    size="lg"
                                />
                            </Col>
                        </>
                    )}
                    {!props.packing && currentDeliveryDate && currentDeliveryType && currentDeliveryMode !== null && (
                        <Col lg={12} className={"gx-5 gy-4"}>
                            <Card className="shadow-sm" border="light">
                                <Card.Body className="p-0">
                                    <PickPackStatsTable
                                        date={currentDeliveryDate}
                                        orderType={currentDeliveryType}
                                        deliveryMode={currentDeliveryMode}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                    {!isFetching ? (
                        <>
                            <Col lg={12} className={"gx-5 gy-4"}>
                                <Card className="shadow-sm" border="light">
                                    <Card.Body className="p-0">
                                        <div className="ag-theme-alpine ag-row-pointer">
                                            <AgGridReact
                                                columnDefs={columnDefs}
                                                rowData={orders ?? []}
                                                domLayout="autoHeight"
                                                getRowStyle={getRowStyle}
                                                defaultColDef={defaultColDef}
                                                suppressDragLeaveHidesColumns={true}
                                            />
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={12} className={"gx-5 gy-4 d-flex flex-row-reverse"}>
                                <Stack gap={3} direction="horizontal">
                                    <LinkContainer to={"/"}>
                                        <Button type="button" variant="light">
                                            {"Back"}
                                        </Button>
                                    </LinkContainer>
                                </Stack>
                            </Col>
                        </>
                    ) : (
                        <Col lg={12} className={""}>
                            <SingleCard pageTitle={`Review orders`} fluid={true} noHeader={true}>
                                <CardPlaceholder />
                            </SingleCard>
                        </Col>
                    )}
                </>
            </Row>
        </NoCards>
    );
};
