import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import { NavMenu } from "../common/nav/NavMenu";
import "./Main.scss";

interface LayoutProps {
  children: React.ReactNode;
}

export class Main extends Component<LayoutProps> {
  static displayName = Main.name;

  render() {
    return (
      <div>
        <NavMenu />
        <Container className="main-container" fluid>
          {this.props.children}
        </Container>
      </div>
    );
  }
}
