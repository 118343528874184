import React, { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { ApiException, UserClient, UserDTO } from "../../../services/ApiClient";

interface IRetailManagerPickerProps {
  selected: UserDTO | undefined;
  size: "sm" | "lg" | undefined;
  setRetailManager: (user: UserDTO) => void;
}

export const RetailManagerPicker = (props: IRetailManagerPickerProps) => {
  //const [error, setError] = useState<null | string>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [retailManagers, setRetailManagers] = useState<UserDTO[] | undefined>(
    undefined
  );

  const getRetailManagers = useCallback(async () => {
    try {
      const result = await new UserClient(
        process.env.REACT_APP_API_BASE
      ).getRetailManagersForRegionalManager();
      const uniqueResult = result.filter(
        (v, i, a) => a.findIndex((v2) => v2.email === v.email) === i
      );
      setRetailManagers(uniqueResult);
      setIsLoaded(true);
    } catch (e: unknown) {
      const error = e as ApiException;
      console.log(error.message);
      //setError(JSON.parse(error.response).error);
    }
  }, []);

  useEffect(() => {
    getRetailManagers();
  }, [getRetailManagers]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const currentManager = retailManagers?.find(
        (retailManager) => retailManager.email === event.target.value
      );
      if (currentManager) {
        props.setRetailManager(currentManager);
      }
    },
    [retailManagers, props]
  );

  return (
    <InputGroup>
      <InputGroup.Text>Retail manager</InputGroup.Text>
      {isLoaded && (
        <Form.Select
          onChange={onChange}
          id={"deliveryDate"}
          size={props.size}
          defaultValue={""}
        >
          <option key={"all"} value={""}>
            All
          </option>

          {retailManagers?.map((retailManager) => (
            <option key={retailManager.email} value={retailManager.email}>
              {retailManager.name}
            </option>
          ))}
        </Form.Select>
      )}
    </InputGroup>
  );
};
