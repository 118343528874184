import React from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { PickPackStatsTable } from "./PickPackStatsTable";
import "./PickPackStats.scss";

export const PickPackStats = () => {
  return (
    <Col className="" xs={12} sm={12} md={12} lg={12}>
      <Card className="shadow-sm p-0" border="light">
        <Card.Body className="p-0">
          <PickPackStatsTable />
        </Card.Body>
      </Card>
    </Col>
  );
};
