import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

interface LayoutProps {
  pageTitle?: string;
  children: React.ReactNode;
  className?: string;
}

export class NoCards extends Component<LayoutProps> {
  static displayName = NoCards.name;

  render() {
    return (
      <Container className={this.props.className}>
        <Row className="g-4">
          <Col lg={12}>
            <h3>
              {typeof this.props.pageTitle !== "undefined"
                ? this.props.pageTitle
                : ""}
            </h3>
          </Col>
          {this.props.children}
        </Row>
      </Container>
    );
  }
}
