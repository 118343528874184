import { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { useParams } from "react-router-dom";
import {
  ApiException,
  OrderClient,
  StoreKpi,
} from "../../../services/ApiClient";
import QuarterDropdown from "./QuarterDropdown";
import OrderTypeDropdown from "./OrderTypeDropdown";
import "./StoreKpis.scss";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { Button, Stack } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";

// Extend dayjs with the quarter plugin
dayjs.extend(quarterOfYear);

interface IStoreKpiProps {}

const getCurrentQuarter = () => {
  const currentQuarter = dayjs().quarter();
  const currentYear = dayjs().year();
  const startDate = dayjs()
    .quarter(currentQuarter)
    .startOf("quarter")
    .format("YYYY-MM-DD");
  const endDate = dayjs()
    .quarter(currentQuarter)
    .endOf("quarter")
    .startOf("week")
    .add(6, "day")
    .format("YYYY-MM-DD");
  return {
    label: `Q${currentQuarter} ${currentYear}`,
    startDate,
    endDate,
  };
};

export const StoreKpis = (props: IStoreKpiProps) => {
  const params = useParams();
  const [kpiData, setKpiData] = useState<StoreKpi[] | null>(null);
  const [selectedQuarter, setSelectedQuarter] = useState<{
    label: string;
    startDate: string;
    endDate: string;
  }>(getCurrentQuarter);
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedCategory, setSelectedCategory] = useState<string>("All");

  useEffect(() => {
    const fetchKpis = async (
      startDate: string,
      endDate: string,
      category: string
    ) => {
      try {
        setLoading(true);
        const orderClient = new OrderClient(process.env.REACT_APP_API_BASE);
        const kpiResults = await orderClient.getStoreKPIs(
          category,
          params.storeid,
          startDate,
          endDate
        );

        setKpiData(kpiResults);
        setLoading(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.error(error.message);
      }
    };

    fetchKpis(
      selectedQuarter.startDate,
      selectedQuarter.endDate,
      selectedCategory
    );
  }, [params.storeid, selectedQuarter, selectedCategory]);

  const handleQuarterSelect = (quarter: {
    label: string;
    startDate: string;
    endDate: string;
  }) => {
    console.log("Selected Quarter:", quarter);
    setSelectedQuarter(quarter);
  };

  const handleCategorySelect = (category: string) => {
    console.log("Selected Category:", category);
    setSelectedCategory(category);
  };

  return (
    <div className={"store-kpis"}>
      <Row className="gy-5">
        <Col lg={12}>
          <Container>
            <h2 className="page-header">Store KPIs</h2>
          </Container>
        </Col>
        <Col xs={12} lg={12}>
          <Container>
            <Card className="shadow-sm" border="light">
              <Card.Body className="card-body">
                <div className="selectors">
                  <Stack gap={3} direction="horizontal">
                    <QuarterDropdown onSelect={handleQuarterSelect} />
                    <OrderTypeDropdown onSelect={handleCategorySelect} />
                  </Stack>
                </div>
                <>
                  {loading ? (
                    <CardPlaceholder />
                  ) : (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Week End</th>
                          <th>Ordered</th>
                          <th>Sales Actual</th>
                          <th>Sales Target</th>
                          <th>Var $</th>
                          <th>Waste %</th>
                          <th>Waste Target %</th>
                        </tr>
                      </thead>
                      <tbody>
                        {kpiData &&
                          kpiData.map((kpi) => (
                            <tr key={kpi.targetEndDate}>
                              <td>
                                {dayjs(kpi.targetEndDate).format("DD/MM/YYYY")}
                              </td>
                              <td>
                                {kpi.portalSales > 0
                                  ? `$${kpi.portalSales.toLocaleString()}`
                                  : ""}
                              </td>
                              <td>
                                {kpi.scannedSales > 0
                                  ? `$${kpi.scannedSales.toLocaleString()}`
                                  : ""}
                              </td>
                              <td>${kpi.salesTarget.toLocaleString()}</td>
                              <td>
                                {kpi.scannedSales > 0
                                  ? `$${kpi.variance.toLocaleString()}`
                                  : ""}
                              </td>
                              <td>
                                {kpi.scannedSales > 0
                                  ? `${kpi.wastePerc}%`
                                  : ""}
                              </td>
                              <td>{kpi.wastageTarget * 100}%</td>
                            </tr>
                          ))}
                        {kpiData &&
                          kpiData.length > 0 &&
                          (() => {
                            const nonZeroKpis = kpiData.filter(
                              (kpi) => kpi.scannedSales > 0
                            );
                            return (
                              <tr className="table-active fw-bold">
                                <td>Total</td>
                                <td>
                                  $
                                  {nonZeroKpis
                                    .reduce(
                                      (sum, kpi) => sum + kpi.portalSales,
                                      0
                                    )
                                    .toLocaleString()}
                                </td>
                                <td>
                                  $
                                  {nonZeroKpis
                                    .reduce(
                                      (sum, kpi) => sum + kpi.scannedSales,
                                      0
                                    )
                                    .toLocaleString()}
                                </td>
                                <td>
                                  $
                                  {nonZeroKpis
                                    .reduce(
                                      (sum, kpi) => sum + kpi.salesTarget,
                                      0
                                    )
                                    .toLocaleString()}
                                </td>
                                <td>
                                  $
                                  {nonZeroKpis
                                    .reduce((sum, kpi) => sum + kpi.variance, 0)
                                    .toLocaleString()}
                                </td>
                                <td>
                                  {Math.round(
                                    nonZeroKpis.reduce(
                                      (sum, kpi) => sum + kpi.wastePerc,
                                      0
                                    ) / nonZeroKpis.length
                                  )}
                                  %
                                </td>
                                <td>
                                  {Math.round(
                                    nonZeroKpis.reduce(
                                      (sum, kpi) =>
                                        sum + kpi.wastageTarget * 100,
                                      0
                                    ) / nonZeroKpis.length
                                  )}
                                  %
                                </td>
                              </tr>
                            );
                          })()}
                      </tbody>
                    </Table>
                  )}
                </>
              </Card.Body>
            </Card>
            <Col lg={12} className={"d-flex flex-row-reverse back-button"}>
              <Stack gap={3} direction="horizontal">
                <LinkContainer to={`/stores/${params.storeid}`}>
                  <Button type="button">{"Back"}</Button>
                </LinkContainer>
              </Stack>
            </Col>
          </Container>
        </Col>
      </Row>
    </div>
  );
};
