import { useCallback, useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { ReactComponent as NoteIcon } from "../../../assets/chat_add_on_FILL0_wght400_GRAD0_opsz48.svg";
import { ItemAvailablity } from "../../../services/ApiClient";

interface IOrderItemProps {
    itemId: number;
    name: string;
    type: string;
    description: string;
    quantity: number;
    increment: number;
    image: string;
    price: number;
    qtyAvailable: number;
    note: string | undefined;
    updateItemQty: (itemId: number, quanity: number, price: number) => void;
    updateItemTotalQty: (qtyToAdd: number) => void;
    updateItemNote: (itemId: number, value: string | undefined, price: number) => void;
    updateItemPrice?: (itemId: number, price: number, oldPrice: number) => void;
    boxLot: boolean;
    special: boolean;
    multibuy: boolean;
    multibuyNote: string;
    avilability: ItemAvailablity;
    preorder: boolean;
    requiredNote: boolean;
    requiredPrice?: boolean;
    directQtyEdit?: boolean; //allow qty to be edited directly, bypasses increments.
    showCostPrice?: boolean;
    costPrice?: number;
}

export const OrderItem = (props: IOrderItemProps) => {
    const [showItemNote, setShowItemNote] = useState<Boolean>(false);
    const [showImage, setShowImage] = useState<Boolean>(false);

    useEffect(() => {
        const showIamge = props.image?.length > 0 && props.image !== "photo_not_available.jpg";
        setShowImage(showIamge);
    }, [props.image]);

    const increaseQty = useCallback(() => {
        props.updateItemQty(props.itemId, props.quantity + props.increment, props.price);
        props.updateItemTotalQty(props.increment);
    }, [props]);

    const decreaseQty = useCallback(() => {
        props.updateItemQty(props.itemId, props.quantity - props.increment, props.price);
        props.updateItemTotalQty(-props.increment);
    }, [props]);

    const updateQty = useCallback(
        (value: number) => {
            props.updateItemQty(props.itemId, value, props.price);
            props.updateItemTotalQty(value);
        },
        [props]
    );

    const setItemNote = useCallback(
        (value: string) => {
            props.updateItemNote(props.itemId, value, props.price);
        },
        [props]
    );

    const toggleItemNote = useCallback(() => {
        if (showItemNote) {
            props.updateItemNote(props.itemId, undefined, props.price);
        }
        setShowItemNote((prevNote) => !prevNote);
    }, [props, showItemNote]);

    const updatePrice = useCallback(
        (price: string) => {
            if (props.updateItemPrice) {
                props.updateItemPrice(props.itemId, Number(price), props.price);
            }
        },
        [props]
    );

    return (
        <>
            <Row className="order-item m-4">
                <Col xs={12} lg={9} xl={10} className={"mb-3 mb-lg-0"}>
                    <Stack direction="horizontal" gap={4}>
                        {showImage && <Image src={props.image} rounded fluid className="order-item-thumb" />}
                        <Row>
                            <Col xs={12} lg={"auto"}>
                                <h5 className={`item-title-${ItemAvailablity[props.avilability]?.toLowerCase()}`}>
                                    {props.name}
                                    {props.price ? ` - $${props.price}` : ""}
                                </h5>
                            </Col>
                            <Col xs={12} lg={"auto"}>
                                <Stack gap={3} direction="horizontal">
                                    {props.special && (
                                        <h5>
                                            <Badge pill bg="primary" className={"m-0"}>
                                                special
                                            </Badge>
                                        </h5>
                                    )}
                                    {props.boxLot && (
                                        <h5>
                                            <Badge pill bg="dark" className={"m-0"}>
                                                box lot
                                            </Badge>
                                        </h5>
                                    )}
                                    {props.multibuy && (
                                        <h5>
                                            <Badge pill bg="info" className={"m-0"}>
                                                multibuy
                                            </Badge>
                                        </h5>
                                    )}
                                    {props.preorder && (
                                        <h5>
                                            <Badge pill bg="primary" className={"m-0"}>
                                                pre-order
                                            </Badge>
                                        </h5>
                                    )}
                                </Stack>
                            </Col>
                            {(props.description?.length > 0 || props.multibuyNote?.length > 0) && (
                                <Col xs={12} className={"mb-3"}>
                                    <div>{props.description}</div>
                                    <div>{props.multibuyNote}</div>
                                </Col>
                            )}
                            <Col xs={12}>
                                <Stack gap={3} direction="horizontal">
                                    {!props.requiredNote && (
                                        <Button
                                            variant="light"
                                            title="Add"
                                            size="sm"
                                            onClick={() => toggleItemNote()}
                                            //disabled={props.qtyAvailable <= 0 || props.qtyAvailable - props.quantity < props.increment} //feedback fro DF that not needed
                                        >
                                            <NoteIcon width={16} height={16} />
                                            {`Add note`}
                                        </Button>
                                    )}
                                    {props.showCostPrice && <span>Cost price: ${props.costPrice}</span>}
                                </Stack>
                                <Stack gap={3} direction="horizontal">
                                    {(showItemNote || props.requiredNote) && (
                                        <Form.Control
                                            maxLength={1024}
                                            onChange={(e) => setItemNote(e.currentTarget.value)}
                                            className={"mt-2"}
                                            type="text"
                                            placeholder="Please enter a note..."
                                            defaultValue={props.note}
                                        />
                                    )}
                                    {props.requiredPrice && (
                                        <InputGroup className="fd-input-container mt-2">
                                            <InputGroup.Text>$</InputGroup.Text>
                                            <Form.Control
                                                type="number"
                                                placeholder="0"
                                                defaultValue={props.price}
                                                min="0.00"
                                                max="10000.00"
                                                step="0.01"
                                                onBlur={(e) => updatePrice(e.currentTarget.value)}
                                            />
                                        </InputGroup>
                                    )}
                                </Stack>
                            </Col>
                        </Row>
                    </Stack>
                </Col>
                <Col
                    xs={12}
                    lg={3}
                    xl={2}
                    className={"px-2 align-items-center d-justify-content-center d-lg-justify-content-flex-end d-flex"}
                >
                    <div className="order-item-qty-button-cnt">
                        <InputGroup>
                            <Button
                                className="order-item-qty-button"
                                variant="primary"
                                title="Remove"
                                id="decrease-quantity"
                                size="sm"
                                onClick={decreaseQty}
                                disabled={props.quantity <= 0 || props.preorder}
                            >
                                -
                            </Button>
                            {props.directQtyEdit ? (
                                <Form.Control
                                    type="number"
                                    placeholder="0"
                                    min="0.00"
                                    max="10000.00"
                                    step="1"
                                    disabled={false}
                                    value={props.quantity}
                                    onChange={(e) => updateQty(Number(e.currentTarget.value))}
                                />
                            ) : (
                                <Form.Control type="text" placeholder="0" disabled={true} value={props.quantity} />
                            )}

                            <Button
                                className="order-item-qty-button"
                                variant="primary"
                                title="Add"
                                id="increase-quantity"
                                size="sm"
                                onClick={increaseQty}
                                disabled={props.preorder}
                                //disabled={props.qtyAvailable <= 0 || props.qtyAvailable - props.quantity < props.increment} //feedback fro DF that not needed
                            >
                                +
                            </Button>
                        </InputGroup>
                    </div>
                </Col>
            </Row>
        </>
    );
};
