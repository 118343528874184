import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import Button from "react-bootstrap/Button";
import "./DeleteItemRenderer.scss";

interface IDeleteItemRendererProps {
  deleteItemCallback: (itemId: number | string) => void;
}

export const DeleteItemRenderer = (
  props: ICellRendererParams & IDeleteItemRendererProps,
) => {
  return (
    <>
      {props.value && (
        <div className="delete-button-container">
          <Button
            size="sm"
            onClick={() => props.deleteItemCallback(props.data)}
          >
            Delete
          </Button>
        </div>
      )}
    </>
  );
};
