import {
  CellClassParams,
  CellEditRequestEvent,
  EditableCallbackParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ItemDTO, OrderDTO } from "../../../services/ApiClient";
import { IColumnType } from "./IColumnType";

interface ISummaryTableProps {
  availableItems: ItemDTO[];
  orders: OrderDTO[];
  alignedGrids: AgGridReact<any>[] | undefined;
  updateStockTake: (itemId: number, qty: number) => void;
  updateIncomming: (itemId: number, qty: number) => void;
}

export const SummaryTable = forwardRef<AgGridReact, ISummaryTableProps>(
  (props: ISummaryTableProps, ref) => {
    const [summaryColumnDefs, setSummaryColumnDefs] = useState([]);
    const [summaryRowData, setSummaryRowData] = useState<any[]>([]);
    const params = useParams();

    const setSummaryColumns = useCallback(
      (availableItems: ItemDTO[]) => {
        const columnDefs = [
          {
            headerName: "T",
            field: "teamLeadReviewed",
            pinned: "left",
            editable: false,
            width: 40,
            cellClass: "edit-indicator-cell",
            headerClass: "edit-indicator-heading",
            sortable: true,
          },
          {
            headerName: "M",
            field: "managerChanged",
            pinned: "left",
            editable: false,
            width: 40,
            cellClass: "edit-indicator-cell",
            headerClass: "edit-indicator-heading",
          },
          {
            headerName: "Customer",
            field: "store.name",
            pinned: "left",
            editable: false,
            width: 190,
            sortable: true,
            resizable: true,
          },
          {
            headerName: "This order",
            field: "total",
            pinned: "left",
            editable: false,
            width: 105,
            autoHeaderHeight: true,
            wrapHeaderText: true,
          },
          ...(params.orderType === "cut"
            ? [
                {
                  headerName: "WTD",
                  field: "store.totalWeekToDate",
                  pinned: "left",
                  editable: false,
                  width: 84,
                },
              ]
            : []),
          ...(params.orderType === "pots"
            ? [
                {
                  headerName: "MTD",
                  field: "store.totalMonth",
                  pinned: "left",
                  editable: false,
                  width: 84,
                },
              ]
            : []),
          {
            headerName: "Budget",
            field: "store.budget",
            pinned: "left",
            editable: false,
            width: 94,
          },
          {
            headerName: "Used",
            field: "used",
            pinned: "left",
            editable: false,
            width: 84,
            sortable: true,
            cellStyle: {
              fontWeight: 700,
              fontSize: "13px",
              overflow: "visible",
              direction: "rtl",
            },
          },
        ] as any;

        availableItems.forEach((item) => {
          columnDefs.push({
            headerName: `${item.itemName}`,
            field: `${item.recId}`,
            autoHeaderHeight: true,
            wrapHeaderText: true,
            width: 120,
            sortable: true,
            type: "editableColumn",
          });
        });

        setSummaryColumnDefs(columnDefs);
      },
      [params.orderType]
    );

    const setSummaryRows = useCallback((availableItems: ItemDTO[]) => {
      const rowsMap = new Map([
        [
          "stockTake",
          {
            teamLeadReviewed: "",
            managerChanged: "",
            thisOrder: "",
            store: {
              name: "",
              totalWeekToDate: "",
              totalMonth: "",
            },
            budget: "",
            used: "Stock take",
          } as any,
        ],
        [
          "incoming",
          {
            used: "Incoming",
          } as any,
        ],
        [
          "available",
          {
            used: "Available",
          } as any,
        ],
        [
          "orderd",
          {
            used: "Ordered",
          } as any,
        ],
        [
          "variance",
          {
            used: "Variance",
          } as any,
        ],
      ]);

      //const flatDetails = props.orders.reduce((preValue, currentValue) => {
      //  return [...preValue, ...currentValue.orderDetails];
      //}, [] as OrderDetailDTO[]);
      availableItems.forEach((item) => {
        const columnTotal = rowsMap.get("orderd");
        columnTotal![item.recId!] = item.totalOrdered;

        const stockTakeRow = rowsMap.get("stockTake");
        stockTakeRow![item.recId!] = item.stocktake;

        const incomingRow = rowsMap.get("incoming");
        incomingRow![item.recId!] = item.incoming;

        const availableRow = rowsMap.get("available");
        availableRow![item.recId!] = item.stocktake! + item.incoming!;

        const orderdRow = rowsMap.get("orderd");
        orderdRow![item.recId!] = item.totalOrdered;

        const variance = rowsMap.get("variance");
        variance![item.recId!] =
          item.stocktake! + item.incoming! - item.totalOrdered!;
      });

      const rowsArr = Array.from(rowsMap.values());

      setSummaryRowData(rowsArr);
    }, []);

    useEffect(() => {
      setSummaryColumns(props.availableItems);
    }, [props.availableItems, setSummaryColumns]);

    useEffect(() => {
      setSummaryRows(props.availableItems);
    }, [props.availableItems, props.orders, setSummaryRows]);

    const isSummaryCellEditable = useCallback(
      (params: EditableCallbackParams | CellClassParams) => {
        switch (params.data.used) {
          case "Available":
            return false;
          case "Ordered":
            return false;
          case "Variance":
            return false;
          default:
            return true;
        }
      },
      []
    );

    const summaryColumnTypes = useMemo<IColumnType>(() => {
      return {
        editableColumn: {
          editable: (params: EditableCallbackParams<any>) => {
            return isSummaryCellEditable(params);
          },
          cellStyle: (params: CellClassParams<any>) => {
            if (!isSummaryCellEditable(params)) {
              return { backgroundColor: "#D9F7FA" };
            }
          },
        },
      };
    }, [isSummaryCellEditable]);

    const onCellEditRequest = useCallback(
      (event: CellEditRequestEvent) => {
        //const data = event.data;
        const field = parseInt(event.colDef.field!);
        const newValue = parseInt(event.newValue);
        if (field && !isNaN(newValue)) {
          //TO DO: update this to use custom row ID/hidden column
          if (event.data.used === "Stock take") {
            props.updateStockTake(field, newValue);
          } else if (event.data.used === "Incoming") {
            props.updateIncomming(field, newValue);
          }
        }
      },
      [props]
    );

    return (
      <div className="summary-table-container">
        <AgGridReact
          ref={ref}
          className={`ag-theme-alpine ag-no-header ag-no-banding ag-no-borders-pinned `}
          columnDefs={summaryColumnDefs}
          columnTypes={summaryColumnTypes}
          rowData={summaryRowData}
          headerHeight={-1}
          domLayout="autoHeight"
          //onGridReady={gridRendered}
          //onGridColumnsChanged={columnsChanged}
          alignedGrids={props.alignedGrids ? props.alignedGrids : undefined}
          //suppressHorizontalScroll
          columnHoverHighlight={true}
          singleClickEdit={true}
          onCellEditRequest={onCellEditRequest}
          readOnlyEdit={true}
          maintainColumnOrder={true}
        />
      </div>
    );
  }
);
