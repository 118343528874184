import React, { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { Main } from "./components/layouts/Main";
import { UserRole } from "./services/ApiClient";

// MSAL imports
import { IPublicClientApplication, InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { CustomNavigationClient } from "./ultilities/NavigationClient";

type AppProps = {
  pca: IPublicClientApplication;
};

interface IRoleContext {
  role: UserRole | null;
  setRole?: React.Dispatch<React.SetStateAction<UserRole | null>>;
}

export const RoleContext = React.createContext<IRoleContext | null>(null);

function App({ pca }: AppProps) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  const [role, setRole] = useState<UserRole | null>(null);

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
        <RoleContext.Provider value={{ role, setRole }}>
          <Main>
            <Routes>
              {AppRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={element} />;
              })}
            </Routes>
          </Main>
        </RoleContext.Provider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;
