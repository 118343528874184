import { ICellRendererParams } from "ag-grid-community";
import { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export const CustomNameWastageRenderer = (
  props: ICellRendererParams
): JSX.Element => {
  const [aboveWaste, setAboveWaste] = useState<boolean>(false);
  const [wasteageTarget, setWaste] = useState<string>("");

  useEffect(() => {
    setWaste(props.data.store.wastageTarget);
    console.log(props.data.store.wastage);
    setAboveWaste(true);
    if (
      props.data.store.wastageTarget > 0 &&
      props.data.store.wastage > 0 &&
      props.data.store.wastage > props.data.store.wastageTarget + 2
    ) {
      setAboveWaste(true);
    }
  }, [props.data]);

  return (
    <OverlayTrigger
      key={"top"}
      placement={"top"}
      overlay={
        <Tooltip id={`tooltip-wastage`}>
          <strong>{wasteageTarget}%</strong>
        </Tooltip>
      }
    >
      <div className={aboveWaste ? "order-title-above-waste" : ""}>
        {props.valueFormatted ? props.valueFormatted : props.value}
      </div>
    </OverlayTrigger>
  );
};
