import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { SingleCard } from "../../layouts/SingleCard";
import { LinkContainer } from "react-router-bootstrap";
import "./NotFound.scss";

interface IStoreKpiProps {}

export const NotFound = (props: IStoreKpiProps) => {
  return (
    <SingleCard pageTitle="Page not found" noHeader={true}>
      <Container className="not-found">
        <Row>
          <Col sm={12}>
            <div className="background">
              <h1 className="text-center ">404</h1>
            </div>
            <div className="content-box">
              <h3 className="h2">Look like you're lost</h3>
              <p>the page you are looking for not is not avaible!</p>
              <LinkContainer to={""}>
                <Button>Go to home</Button>
              </LinkContainer>
            </div>
          </Col>
        </Row>
      </Container>
    </SingleCard>
  );
};
