import { CACHE_TTL_IN_MS } from "../authConfig";
import { UserRole } from "../services/ApiClient";
import { AccountInfo } from "@azure/msal-common";

export const setRolesInStorage = (
  account: AccountInfo,
  roles: UserRole[],
): void => {
  const newEntry = {
    Roles: roles,
    lastAccessed: Date.now(),
    expiresOn: Date.now() + CACHE_TTL_IN_MS,
    sourceTokenId: account.idTokenClaims!["uti"],
  };

  sessionStorage.setItem(
    `fdrole.${account.idTokenClaims!.aud}.${account.idTokenClaims!.oid}`,
    JSON.stringify(newEntry),
  );
};

export const checkRolesInStorage = (account: AccountInfo): boolean => {
  const storageEntry = sessionStorage.getItem(
    `fdrole.${account.idTokenClaims!.aud}.${account.idTokenClaims!.oid}`,
  );

  if (!storageEntry) return false;

  const parsedStorageEntry = JSON.parse(storageEntry);
  return (
    parsedStorageEntry.Roles &&
    parsedStorageEntry.expiresOn >= Date.now() &&
    parsedStorageEntry.sourceTokenId === account.idTokenClaims!["uti"]
  );
};

export const getRolesFromStorage = (
  account: AccountInfo,
): UserRole[] | null => {
  const storageEntry = sessionStorage.getItem(
    `fdrole.${account.idTokenClaims!.aud}.${account.idTokenClaims!.oid}`,
  );

  if (!storageEntry) return null;

  return JSON.parse(storageEntry).Roles;
};

export const clearRolesInStorage = (account: AccountInfo): void => {
  for (var key in sessionStorage) {
    if (
      key.startsWith(
        `fdrole.${account.idTokenClaims!.aud}.${account.idTokenClaims!.oid}`,
      )
    ) {
      sessionStorage.removeItem(key);
    }
  }
};
