/****************************************************************************************************
 * Imports
 ****************************************************************************************************/
import { useCallback, useContext, useEffect, useState } from "react";
import {
  ApiException,
  UserClient,
  StoreNameDTO,
} from "../../../services/ApiClient";
import { Button, Card, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { Formik, FormikValues } from "formik";
import { LinkContainer } from "react-router-bootstrap";
import { NoCards } from "../../layouts/NoCards";
import { useNavigate } from "react-router-dom";
import { MarkdownContext } from "./Markdown";

/****************************************************************************************************
 * Component
 ****************************************************************************************************/
export const NewMarkdown = () => {
  //console.log("Rendering NewMarkdown...");

  /************************************************************************************************
   * Context and other hooks
   ************************************************************************************************/
  const ctx = useContext(MarkdownContext)!;
  const navigate = useNavigate();

  /************************************************************************************************
   * States
   ************************************************************************************************/
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [userStores, setUserStores] = useState<StoreNameDTO[]>([]);

  /************************************************************************************************
   * Effects
   ************************************************************************************************/
  // Prepars data for the component
  useEffect(() => {
    //console.log("Executing NewMarkdown useEffect...");
    const fetchUserStore = async () => {
      try {
        setIsFetching(true);

        // Get the store ID and Name of the current user
        // Assuming only Merchandiser uses this page and each Merchandiser has only one store.
        const currentUser = await new UserClient(
          process.env.REACT_APP_API_BASE
        ).getCurrentUser();

        if (currentUser.userStores && currentUser.userStores.length > 0) {
          setUserStores(currentUser.userStores);
          ctx.setStoreNameDto!(currentUser.userStores[0]);
        } else {
          setError("You do not have any store.");
        }
      } catch (e: unknown) {
        const error = e as ApiException;
        setError(JSON.parse(error.response).errorMessages[0]);
      } finally {
        setIsFetching(false);
      }
    };

    fetchUserStore().catch(console.error);
  }, [ctx.setStoreNameDto]);

  /************************************************************************************************
   * Events
   ************************************************************************************************/
  // Next Button
  const updateMarkdownSettings = useCallback(
    (values: FormikValues) => {
      ctx.setOrderType!(values.orderType);
      ctx.setPageMode!("enter");
      ctx.setStoreNameDto!(userStores.find((x) => x.id === values.userStore)!);
    },
    [ctx.setOrderType, ctx.setPageMode, ctx.setStoreNameDto, userStores]
  );

  // Cancel Button
  const goHome = useCallback(() => navigate("/"), []);

  /************************************************************************************************
   * Render
   ************************************************************************************************/
  return (
    <>
      <NoCards pageTitle={ctx.pageName}>
        <Row>
          {!isFetching && (
            <Col lg={12} className={"gx-4 gy-4"}>
              <Card className="shadow-sm" border="light">
                <Card.Body className="p-4">
                  <div className="transfer-settings-card-inner">
                    <Formik
                      onSubmit={updateMarkdownSettings}
                      initialValues={{
                        orderType: ctx?.orderType,
                        userStore: ctx.storeNameDto?.id,
                      }}
                    >
                      {({
                        handleSubmit,
                        values,
                        setFieldValue,
                        handleChange,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                          <Row className="">
                            <Form.Group className="mb-3">
                              <Form.Label>Store</Form.Label>
                              <Form.Select
                                onChange={handleChange}
                                id={"userStore"}
                                name={"userStore"}
                                value={values.userStore}
                              >
                                {userStores.map((value) => (
                                  <option key={value.id} value={value.id}>
                                    {value.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>

                            {/* Order Type Radio Buttons */}
                            <Form.Group>
                              <Form.Label>Select order type</Form.Label>
                              <div key={`inline-checkbox`} className="mb-3">
                                <Form.Check
                                  inline
                                  id={`cutsOrderType`}
                                  label={"Cuts"}
                                  name={"orderType"}
                                  type={"radio"}
                                  defaultChecked={values.orderType === "Cuts"}
                                  onChange={(e) =>
                                    e.target.checked
                                      ? setFieldValue(e.target.name, "Cuts")
                                      : setFieldValue(e.target.name, false)
                                  }
                                />
                                <Form.Check
                                  inline
                                  label={"Pots"}
                                  name={"orderType"}
                                  type={"radio"}
                                  id={`potsOrderType`}
                                  defaultChecked={values.orderType === "Pots"}
                                  onChange={(e) =>
                                    e.target.checked
                                      ? setFieldValue(e.target.name, "Pots")
                                      : setFieldValue(e.target.name, false)
                                  }
                                />
                                <Form.Check
                                  inline
                                  label={"Materials"}
                                  name={"orderType"}
                                  type={"radio"}
                                  id={`materialsOrderType`}
                                  defaultChecked={
                                    values.orderType === "Materials"
                                  }
                                  onChange={(e) =>
                                    e.target.checked
                                      ? setFieldValue(
                                          e.target.name,
                                          "Materials"
                                        )
                                      : setFieldValue(e.target.name, false)
                                  }
                                />
                              </div>
                            </Form.Group>
                            {/* Next and Cancel Buttons */}
                            <Form.Group>
                              <Stack gap={3} direction="horizontal">
                                <Button type="submit">Next</Button>
                                <LinkContainer to={"/"}>
                                  <Button type="button" variant="light">
                                    Cancel
                                  </Button>
                                </LinkContainer>
                              </Stack>
                            </Form.Group>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>
      </NoCards>

      {/* Model to show error message */}
      <Modal show={error?.length! > 0} onHide={() => goHome()}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => goHome()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
