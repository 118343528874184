import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
//import { EDeliveryMode } from "../../../services/ApiClient";

interface IDeliveryModePickerProps {
    selected: string;
    size: "sm" | "lg" | undefined;
    defaultValue: string;
    setDeliveryMode: (delvieryMode: string) => void;
    options: string[];
}

export const DeliveryModePicker = (props: IDeliveryModePickerProps) => {
    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        props.setDeliveryMode(event.target.value);
    };

    return (
        <InputGroup>
            <InputGroup.Text>Delivery mode</InputGroup.Text>
            <Form.Select onChange={onChange} id={"deliveryMode"} size={props.size} defaultValue={props.defaultValue}>
                {props.options.map((option: string) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </Form.Select>
        </InputGroup>
    );
};
