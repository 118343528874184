import { Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import {
  ApiException,
  FloralSetting,
  ManagerClient,
} from "../../../services/ApiClient";
//import "./PortalSettingsForm.scss";
import { LinkContainer } from "react-router-bootstrap";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";

interface IPortalSettingsFormProps {}

interface IStoreSetting {
  MaterialsOrderDay?: string;
  PotsOrderDay?: string;
  WasteLimitPctCuts?: number;
  WasteLimitPctPots?: number;
}

export const PortalSettingsForm = (props: IPortalSettingsFormProps) => {
  const [error, setError] = useState<null | string>(null);
  //const [isLoaded, setIsLoaded] = useState(false);
  //const [isFetching, setIsFetching] = useState(false);
  const [settings, setSettings] = useState<IStoreSetting | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [saved, setSaved] = useState(false);

  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        //setIsFetching(true);
        const result = await new ManagerClient(
          process.env.REACT_APP_API_BASE
        ).getPortalSettings();
        const resultObj: Record<string, any> = {};

        result.map((obj) => {
          return (resultObj[obj.key] = obj.value);
        });

        setSettings(resultObj as IStoreSetting);
        //setIsLoaded(true);
        //setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        //setIsFetching(false);
        setError(JSON.parse(error.response).error);
      }
    };

    fetchData().catch(console.error);
  }, []);

  const updateSettings = useCallback(
    async (
      values: IStoreSetting,
      formikHelpers: FormikHelpers<IStoreSetting>
    ) => {
      const portalSettingArr = Object.keys(values).map((objKey) => {
        return {
          key: `${objKey}`,
          value: `${values[objKey as keyof typeof settings]}`,
        };
      }) as FloralSetting[];

      try {
        setIsSaving(true);

        await new ManagerClient(
          process.env.REACT_APP_API_BASE
        ).savePortalSettings(portalSettingArr);

        setSaved(true);
        setIsSaving(false);
        formikHelpers.resetForm({ values });
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        //setIsFetching(false);
        setError(JSON.parse(error.response).error);
      }
    },
    []
  );

  const getSaveLabel = useCallback(
    (isDirty: boolean): string => {
      if (!isDirty && saved) {
        return "Saved";
      } else if (isSaving) {
        return "Saving";
      } else {
        return "Save";
      }
    },
    [isSaving, saved]
  );

  return (
    <div className={"portal-settings-form"}>
      {settings ? (
        <Formik
          //validationSchema={schema}
          onSubmit={updateSettings}
          initialValues={settings}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            dirty,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="">
                <Form.Label>Order day</Form.Label>
                <Form.Group className="mb-3" as={Col} md="6">
                  <FloatingLabel controlId="floatingSelect" label="Pots">
                    <Form.Select
                      name="PotsOrderDay"
                      value={values.PotsOrderDay}
                      onChange={handleChange}
                    >
                      {weekDays.map((day) => (
                        <option key={day}>{day}</option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6">
                  <FloatingLabel controlId="floatingSelect" label="Materials">
                    <Form.Select
                      name={"MaterialsOrderDay"}
                      value={values.MaterialsOrderDay}
                      onChange={handleChange}
                    >
                      {weekDays.map((day) => (
                        <option key={day}>{day}</option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Label>Waste limit %</Form.Label>
                <Form.Group className="mb-3" as={Col} md="12">
                  <InputGroup>
                    <InputGroup.Text>Pots</InputGroup.Text>
                    <Form.Control
                      type="number"
                      aria-label="Pots wasteage %"
                      name={"WasteLimitPctPots"}
                      value={values.WasteLimitPctPots}
                      onChange={handleChange}
                    />
                    <InputGroup.Text id="percentage">%</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="12">
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Cuts</InputGroup.Text>
                    <Form.Control
                      type="number"
                      aria-label="Cuts wasteage %"
                      name="WasteLimitPctCuts"
                      value={values.WasteLimitPctCuts}
                      onChange={handleChange}
                    />
                    <InputGroup.Text id="percentage">%</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Stack gap={3} direction="horizontal">
                    <Button type="submit" disabled={isSaving || !dirty}>
                      {getSaveLabel(dirty)}
                    </Button>
                    <LinkContainer to={""}>
                      <Button type="button" variant="light">
                        {"Cancel"}
                      </Button>
                    </LinkContainer>
                  </Stack>
                </Form.Group>
              </Row>
            </Form>
          )}
        </Formik>
      ) : (
        <CardPlaceholder />
      )}
      <Modal show={error?.length! > 0} onHide={() => setError(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setError(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
