import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useImmer } from "use-immer";
import {
  ApiException,
  CreditRequestDTO,
  OrderClient,
} from "../../../services/ApiClient";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { NoCards } from "../../layouts/NoCards";
import "./CreditRequests.scss";
import { ReviewCreditTable } from "./CreditRequestsTable";

interface IReviewCreditRequestsProps {}

export const ReviewCreditRequests = (props: IReviewCreditRequestsProps) => {
  const [error, setError] = useState<null | string>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  //const [isSaving, setIsSaving] = useState(false);
  const [creditRequestData, setCreditRequestData] = useImmer<
    CreditRequestDTO[] | null
  >(null);
  const [menuState, setMenuState] = useState<string>("pending");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetching(true);

        if (menuState === "pending") {
          const result = await new OrderClient().getOpenCreditRequests();
          setCreditRequestData(result);
        } else {
          const result = await new OrderClient().getCompletedCreditRequests();
          setCreditRequestData(result);
        }

        setIsLoaded(true);
        setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setIsFetching(false);
        setError(JSON.parse(error.response).error);
      }
    };

    fetchData().catch(console.error);
  }, [menuState, setCreditRequestData]);

  const onNavItemClicked = useCallback((eventKey: string) => {
    setMenuState(eventKey);
  }, []);

  return (
    <div className={"credit-requests"}>
      <NoCards pageTitle="">
        {!isFetching && isLoaded && creditRequestData ? (
          <>
            <Col lg={12}>
              <ReviewCreditTable
                creditRequestData={creditRequestData}
                onNavItemClicked={onNavItemClicked}
                menuState={menuState}
              />
            </Col>
          </>
        ) : (
          <CardPlaceholder />
        )}
      </NoCards>
      <Modal show={error?.length! > 0} onHide={() => setError(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setError(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
