import { Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { LinkContainer } from "react-router-bootstrap";
import { useParams } from "react-router-dom";
import {
  ApiException,
  ManagerClient,
  StoreSettingDTO,
} from "../../../services/ApiClient";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { SingleCard } from "../../layouts/SingleCard";
import "./StoreSettings.scss";

interface IStoreSettingsProps {}

export const StoreSettings = (props: IStoreSettingsProps) => {
  const params = useParams();
  const [error, setError] = useState<null | string>(null);
  //const [isLoaded, setIsLoaded] = useState(false);
  //const [isFetching, setIsFetching] = useState(false);
  const [storeSetting, setStoreSetting] = useState<StoreSettingDTO | null>(
    null
  );
  const [isSaving, setIsSaving] = useState(false);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //setIsFetching(true);
        const result = await new ManagerClient(
          process.env.REACT_APP_API_BASE
        ).getStoreSetting(params.storeid);
        setStoreSetting(result);
        //setIsLoaded(true);
        //setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        //setIsFetching(false);
        setError(JSON.parse(error.response).error);
      }
    };

    fetchData().catch(console.error);
  }, [params.storeid]);

  const updateSettings = useCallback(
    async (
      values: StoreSettingDTO,
      formikHelpers: FormikHelpers<StoreSettingDTO>
    ) => {
      try {
        setIsSaving(true);

        await new ManagerClient(
          process.env.REACT_APP_API_BASE
        ).saveStoreSetting(values);

        setIsSaving(false);
        setSaved(true);
        formikHelpers.resetForm({ values });
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        //setIsFetching(false);
        setError(JSON.parse(error.response).error);
      }
    },
    []
  );

  const getSaveLabel = useCallback(
    (isDirty: boolean): string => {
      if (!isDirty && saved) {
        return "Saved";
      } else if (isSaving) {
        return "Saving";
      } else {
        return "Save";
      }
    },
    [isSaving, saved]
  );

  return (
    <SingleCard pageTitle={`Store Settings: ${storeSetting?.name}`}>
      <div className={"store-settings"}>
        {storeSetting ? (
          <Formik
            //validationSchema={schema}
            onSubmit={updateSettings}
            initialValues={storeSetting}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
              dirty,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="">
                  <Form.Group>
                    <Form.Label>Cuts order day</Form.Label>
                    <div key={`inline-checkbox`} className="mb-3">
                      <Form.Check
                        inline
                        label={"Monday"}
                        name={"cutsMon"}
                        type={"checkbox"}
                        id={`cutsMon`}
                        defaultChecked={values.cutsMon}
                        onChange={(e) =>
                          e.target.checked
                            ? setFieldValue(e.target.name, true)
                            : setFieldValue(e.target.name, false)
                        }
                      />
                      <Form.Check
                        inline
                        label={"Tuesday"}
                        name={"cutsTue"}
                        type={"checkbox"}
                        id={`cutsTue`}
                        defaultChecked={values.cutsTue}
                        onChange={(e) =>
                          e.target.checked
                            ? setFieldValue(e.target.name, true)
                            : setFieldValue(e.target.name, false)
                        }
                      />
                      <Form.Check
                        inline
                        label={"Wednesday"}
                        name={"cutsWed"}
                        type={"checkbox"}
                        id={`cutsWed`}
                        defaultChecked={values.cutsWed}
                        onChange={(e) =>
                          e.target.checked
                            ? setFieldValue(e.target.name, true)
                            : setFieldValue(e.target.name, false)
                        }
                      />
                      <Form.Check
                        inline
                        label={"Thursday"}
                        name={"cutsThu"}
                        type={"checkbox"}
                        id={`cutsThu`}
                        defaultChecked={values.cutsThu}
                        onChange={(e) =>
                          e.target.checked
                            ? setFieldValue(e.target.name, true)
                            : setFieldValue(e.target.name, false)
                        }
                      />
                      <Form.Check
                        inline
                        label={"Friday"}
                        name={"cutsFri"}
                        type={"checkbox"}
                        id={`cutsFri`}
                        defaultChecked={values.cutsFri}
                        onChange={(e) =>
                          e.target.checked
                            ? setFieldValue(e.target.name, true)
                            : setFieldValue(e.target.name, false)
                        }
                      />
                      <Form.Check
                        inline
                        label={"Saturday"}
                        name={"cutsSat"}
                        type={"checkbox"}
                        id={`cutsSat`}
                        defaultChecked={values.cutsSat}
                        onChange={(e) =>
                          e.target.checked
                            ? setFieldValue(e.target.name, true)
                            : setFieldValue(e.target.name, false)
                        }
                      />
                      <Form.Check
                        inline
                        label={"Sunday"}
                        name={"cutsSun"}
                        type={"checkbox"}
                        id={`cutsSun`}
                        defaultChecked={values.cutsSun}
                        onChange={(e) =>
                          e.target.checked
                            ? setFieldValue(e.target.name, true)
                            : setFieldValue(e.target.name, false)
                        }
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Store message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="message"
                      value={values.message}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Override budgets</Form.Label>
                    <Form.Check
                      label={"Cuts"}
                      name={"overrideCutsBudget"}
                      type={"checkbox"}
                      id={`overrideCutsBudget`}
                      defaultChecked={values.overrideCutsBudget}
                      onChange={(e) =>
                        e.target.checked
                          ? setFieldValue(e.target.name, true)
                          : setFieldValue(e.target.name, false)
                      }
                    />
                    <InputGroup className="mb-3">
                      <Form.Control
                        disabled={!values.overrideCutsBudget}
                        name="overrideCutsPct"
                        value={values.overrideCutsPct}
                        onChange={handleChange}
                      />
                      <InputGroup.Text id="percentage">%</InputGroup.Text>
                    </InputGroup>
                    <InputGroup>
                      <Form.Check
                        label={"Pots"}
                        name={"overridePotsBudget"}
                        type={"checkbox"}
                        id={`overridePotsBudget`}
                        defaultChecked={values.overridePotsBudget}
                        onChange={(e) =>
                          e.target.checked
                            ? setFieldValue(e.target.name, true)
                            : setFieldValue(e.target.name, false)
                        }
                      />
                      <InputGroup>
                        <Form.Control
                          disabled={!values.overridePotsBudget}
                          name="overridePotsPct"
                          value={values.overridePotsPct}
                          onChange={handleChange}
                        />
                        <InputGroup.Text id="percentage">%</InputGroup.Text>
                      </InputGroup>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Show missing store</Form.Label>
                    <Form.Check
                      label={"Pots"}
                      name={"showMissingPots"}
                      type={"checkbox"}
                      id={`showMissingPots`}
                      defaultChecked={values.showMissingPots}
                      onChange={(e) =>
                        e.target.checked
                          ? setFieldValue(e.target.name, true)
                          : setFieldValue(e.target.name, false)
                      }
                    />
                    <Form.Check
                      label={"Materials"}
                      name={"showMissingMaterials"}
                      type={"checkbox"}
                      id={`showMissingMaterials`}
                      defaultChecked={values.showMissingMaterials}
                      onChange={(e) =>
                        e.target.checked
                          ? setFieldValue(e.target.name, true)
                          : setFieldValue(e.target.name, false)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Use auto hard budget</Form.Label>
                    <Form.Check
                      label={"Cuts"}
                      name={"hardBudgetCuts"}
                      type={"checkbox"}
                      id={`hardBudgetCuts`}
                      defaultChecked={values.hardBudgetCuts}
                      onChange={(e) =>
                        e.target.checked
                          ? setFieldValue(e.target.name, true)
                          : setFieldValue(e.target.name, false)
                      }
                    />
                    <Form.Check
                      label={"Pots"}
                      name={"hardBudgetPots"}
                      type={"checkbox"}
                      id={`hardBudgetPots`}
                      defaultChecked={values.hardBudgetPots}
                      onChange={(e) =>
                        e.target.checked
                          ? setFieldValue(e.target.name, true)
                          : setFieldValue(e.target.name, false)
                      }
                    />
                    <Form.Check
                      label={"Materials"}
                      name={"hardBudgetMaterials"}
                      type={"checkbox"}
                      id={`hardBudgetMaterials`}
                      defaultChecked={values.hardBudgetMaterials}
                      onChange={(e) =>
                        e.target.checked
                          ? setFieldValue(e.target.name, true)
                          : setFieldValue(e.target.name, false)
                      }
                    />
                  </Form.Group>
                  <Form.Group>
                    <Stack gap={3} direction="horizontal">
                      <Button type="submit" disabled={isSaving || !dirty}>
                        {getSaveLabel(dirty)}
                      </Button>
                      <LinkContainer to={"/storesettings"}>
                        <Button type="button" variant="light">
                          {"Cancel"}
                        </Button>
                      </LinkContainer>
                    </Stack>
                  </Form.Group>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <CardPlaceholder />
        )}
      </div>
      <Modal show={error?.length! > 0} onHide={() => setError(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setError(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </SingleCard>
  );
};
