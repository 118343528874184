import { ICellRendererParams } from "ag-grid-community";
import React, { useCallback } from "react";
import { OrderDetailDTO } from "../../../services/ApiClient";

interface ICheckBoxRendererProps {
  updateRecieved: (
    currentOrderID: number,
    currentOrderDetailID: number,
    value: boolean
  ) => void;
}

export const CheckboxRenderer = (
  props: ICellRendererParams & ICheckBoxRendererProps
) => {
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      const data = props.data as OrderDetailDTO;
      props.updateRecieved(
        data.orderId,
        data.orderDetailId,
        event.currentTarget.checked
      );
    },
    [props]
  );

  return (
    <>
      {!props.data.deleted ? (
        <input
          type="checkbox"
          onClick={onClick}
          defaultChecked={props.value}
          disabled={props.data.deleted}
        />
      ) : (
        ""
      )}
    </>
  );
};
