import { Formik } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { useNavigate, useParams } from "react-router-dom";
//import "./ProductAvailabilityStores.scss";
import dayjs from "dayjs";
import { RoleContext } from "../../../App";
import {
  ApiException,
  CreditRequestDTO,
  CreditrequestStatus,
  FileuploadClient,
  OrderClient,
  UserRole,
} from "../../../services/ApiClient";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { SingleCard } from "../../layouts/SingleCard";
import { creditReasons } from "../currentOrders/CreateCreditRequest";

interface ICreditRequestDetailsFormProps {
  //creditRequestDetailsData: CreditRequestDTO;
  creditrequestdata?: CreditRequestDTO;
  //    updateCreditOutcome: (crItem: CreditRequestDTO) => void;
}

export const CreditRequestDetails = (props: ICreditRequestDetailsFormProps) => {
  const navigate = useNavigate();
  //const [error, setError] = useState<null | string>(null);
  //const [isLoaded, setIsLoaded] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [creditRequestItem, setCreditRequestItem] =
    useState<CreditRequestDTO | null>(null);
  const [images, setImages] = useState<string[]>([]);
  const userRole = useContext(RoleContext)?.role;
  //const [isImageFetching, setIsImageFetching] = useState(false);
  const params = useParams();
  const role = useContext(RoleContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetching(true);

        const result = await new OrderClient().getOpenCreditRequest(
          Number(params.creditrequestid!)
        );
        setCreditRequestItem(result);

        //setIsLoaded(true);
        setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setIsFetching(false);
        //setError(JSON.parse(error.response).error);
      }
    };

    fetchData().catch(console.error);
  }, [params.creditrequestid]);

  const approveCredit = useCallback(
    async (values: CreditRequestDTO) => {
      try {
        await new OrderClient().approveCreditRequest(values);

        navigate(`/reviewcreditrequests`);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        //setError(JSON.parse(error.response).error);
      }
    },
    [navigate]
  );

  const rejectCredit = useCallback(
    async (values: CreditRequestDTO) => {
      try {
        await new OrderClient().rejectCreditRequest(values);
        navigate(`/reviewcreditrequests`);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        //setError(JSON.parse(error.response).error);
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (creditRequestItem?.photo!) {
      //setIsImageFetching(true);

      const fetchData = async () => {
        try {
          let photos = creditRequestItem?.photo?.split(";").slice(0, -1);
          let photoUrls: string[] = [];

          if (photos!.length! === 0 && creditRequestItem?.photo!.length! > 0) {
            photos = [creditRequestItem?.photo!];
          }

          if (photos!.length! > 0) {
            await Promise.all(
              photos!.map(async (photo) => {
                const result = await new FileuploadClient().get(photo);
                const imageUrl = (await result!.data.text()).replaceAll(
                  '"',
                  ""
                );
                photoUrls.push(imageUrl);
              })
            );

            setImages(photoUrls);
          }
        } catch (e: unknown) {
          const error = e as ApiException;
          console.log(error.message);
          setIsFetching(false);
          //setError(JSON.parse(error.response).error);
        }
      };

      fetchData().catch(console.error);

      //setIsImageFetching(false);
    }
  }, [creditRequestItem?.photo]);

  const markdownPrice = useCallback(
    (price: number, originalPrice: number, status: number) => {
      if (price && originalPrice && status > 1) {
        return originalPrice - price;
      }
      return price;
    },
    []
  );

  return (
    <>
      {!isFetching ? (
        <SingleCard pageTitle="Review Credit Request">
          <div className="portal-settings-form">
            {creditRequestItem && (
              <Formik initialValues={creditRequestItem} onSubmit={() => {}}>
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                  setFieldValue,
                  dirty,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <fieldset disabled={values.status > 1}>
                      <Row className="">
                        <Form.Group className="mb-3">
                          <Form.Label>Requested by</Form.Label>
                          <Form.Control
                            type="text"
                            name="requestedBy"
                            disabled
                            value={values.requestedBy}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Store</Form.Label>
                          <Form.Control
                            type="text"
                            name="storeName"
                            disabled
                            value={values.storeName}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Sales order</Form.Label>
                          <Form.Control
                            type="text"
                            name="salesOrderId"
                            disabled
                            value={values.salesOrderId}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Delivery date</Form.Label>
                          <Form.Control
                            type="text"
                            name="deliveryDate"
                            disabled
                            value={dayjs(values.deliveryDate).format(
                              "DD/MM/YYYY"
                            )}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Product</Form.Label>
                          <Form.Control
                            type="text"
                            name="productName"
                            disabled
                            value={values.productName}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Quantity</Form.Label>
                          <Form.Control
                            type="number"
                            name="quantity"
                            onChange={handleChange}
                            disabled={userRole !== 2}
                            value={values.quantity || ""}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Original price</Form.Label>
                          <InputGroup className="fd-input-container">
                            <InputGroup.Text id="basic-addon1">
                              $
                            </InputGroup.Text>
                            <Form.Control
                              type="number"
                              name="orginalPrice"
                              disabled
                              value={values.originalPrice || ""}
                            />
                          </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Markdown/credit price</Form.Label>
                          <InputGroup className="fd-input-container">
                            <InputGroup.Text id="basic-addon1">
                              $
                            </InputGroup.Text>
                            <Form.Control
                              type="number"
                              name="price"
                              onChange={handleChange}
                              value={
                                markdownPrice(
                                  values.price,
                                  values.originalPrice,
                                  values.status
                                ) || ""
                              }
                            />
                          </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Reason</Form.Label>
                          <Form.Select
                            onChange={handleChange}
                            id={"reason"}
                            name={"reason"}
                            value={values.reason}
                            disabled={userRole !== 2}
                          >
                            {creditReasons.map((reason) => (
                              <option key={reason} value={reason}>
                                {reason}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Photos</Form.Label>
                          {images!.length! > 0 ? (
                            <>
                              {images!.map((image) => (
                                <div>
                                  <img
                                    width={"80%"}
                                    src={image}
                                    title=""
                                    alt=""
                                  />
                                </div>
                              ))}
                            </>
                          ) : (
                            <div>No photos</div>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Comment</Form.Label>
                          <Form.Control
                            as="textarea"
                            name="comment"
                            rows={3}
                            disabled
                            value={values.comment || ""}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Status</Form.Label>
                          <Form.Control
                            as="select"
                            name="status"
                            disabled
                            value={values.status || ""}
                          >
                            {Object.keys(CreditrequestStatus).map((key) => (
                              <option
                                key={key}
                                value={
                                  CreditrequestStatus[
                                    key as keyof typeof CreditrequestStatus
                                  ]
                                }
                              >
                                {key}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Reject comment</Form.Label>
                          <Form.Control
                            type="text"
                            id="rejectReason"
                            name="rejectReason"
                            value={values.rejectReason || ""}
                            onChange={handleChange}
                          />
                        </Form.Group>

                        <div className="d-flex justify-content-between">
                          {role?.role === UserRole.Manager && (
                            <Button
                              onClick={() => {
                                approveCredit(values);
                              }}
                            >
                              Approve
                            </Button>
                          )}
                          <Button
                            disabled={!values.rejectReason}
                            onClick={() => {
                              rejectCredit(values);
                            }}
                          >
                            Reject
                          </Button>
                        </div>
                      </Row>
                    </fieldset>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </SingleCard>
      ) : (
        <Container>
          <Row xs={1} md={3} lg={3} className="g-4">
            <Col lg={12}>
              <h5>Review Credit Request</h5>
            </Col>
            <Col xs={12} lg={12}>
              <Card className="shadow-sm p-0" border="light">
                <Card.Body className="p-0 available-products-card">
                  <CardPlaceholder />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
