import React, { useContext, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import { LinkContainer } from "react-router-bootstrap";
import { RoleContext } from "../../../App";
import { UserRole } from "../../../services/ApiClient";
import "./NavCard.scss";

interface INavCardProps {
  title: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> & {
    title?: string | undefined;
  };
  navigateTo: string;
  requiredRoles: UserRole[];
}

export const NavCard = (props: INavCardProps) => {
  const Icon = props.icon;
  const [isAuthorized, setIsAuthorized] = useState(false);
  const roleContext = useContext(RoleContext);

  useEffect(() => {
    const getRoles = async () => {
      const hasRequiredRole = props.requiredRoles.some(
        (role: UserRole) => roleContext!.role === role
      );

      setIsAuthorized(hasRequiredRole);
    };
    getRoles();
  }, [props.requiredRoles, roleContext]);

  return (
    <>
      {isAuthorized && (
        <Col className="" xs={12} sm={12} md={6} lg={3}>
          <Card className="shadow-sm nav-card" border="light">
            <LinkContainer to={props.navigateTo}>
              <Card.Body as="a">
                <Stack direction="horizontal" gap={3}>
                  <Icon className="svg-icon" />
                  <span className="nav-card-title">{props.title}</span>
                </Stack>
              </Card.Body>
            </LinkContainer>
          </Card>
        </Col>
      )}
    </>
  );
};
