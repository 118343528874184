/****************************************************************************************************
 * Imports
 ****************************************************************************************************/
import React, { useState } from "react";

import { NewMarkdown } from "./NewMarkdown";
import { EnterMarkdown } from "./EnterMarkdown";
import { StoreNameDTO } from "../../../services/ApiClient";

import "./Markdown.scss";

/****************************************************************************************************
 * Interfaces
 ****************************************************************************************************/
interface IMarkdownProps {
  isStocktake: boolean;
  pageName: string;
}

interface IMarkdownContext {
  pageMode: "new" | "enter";
  setPageMode?: React.Dispatch<React.SetStateAction<"new" | "enter">>;
  storeNameDto?: StoreNameDTO | null;
  setStoreNameDto?: React.Dispatch<React.SetStateAction<StoreNameDTO | null>>;
  orderType: "Cuts" | "Pots" | "Materials" | null;
  setOrderType?: React.Dispatch<
    React.SetStateAction<"Cuts" | "Pots" | "Materials">
  >;
  isStocktake: boolean;
  pageName: string;
}

/****************************************************************************************************
 * Context
 ****************************************************************************************************/
export const MarkdownContext = React.createContext<IMarkdownContext | null>(
  null
);

/****************************************************************************************************
 * Component
 ****************************************************************************************************/
export const Markdown = (props: IMarkdownProps) => {
  console.log("Rendering Markdown...");
  /************************************************************************************************
   * States
   ************************************************************************************************/
  const [pageMode, setPageMode] = useState<"new" | "enter">("new");
  const [storeNameDto, setStoreNameDto] = useState<StoreNameDTO | null>(null);
  const [orderType, setOrderType] = useState<"Cuts" | "Pots" | "Materials">(
    "Cuts"
  );
  const isStocktake = props.isStocktake;
  const pageName = props.pageName;

  /************************************************************************************************
   * Render
   ************************************************************************************************/
  return (
    <MarkdownContext.Provider
      value={{
        pageMode,
        setPageMode,
        storeNameDto,
        setStoreNameDto,
        orderType,
        setOrderType,
        isStocktake,
        pageName,
      }}
    >
      <div className="new-transfer">
        {pageMode === "new" && <NewMarkdown />}
        {pageMode === "enter" && <EnterMarkdown />}
      </div>
    </MarkdownContext.Provider>
  );
};
