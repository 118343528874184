import { useCallback, useMemo, useRef } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { useNavigate } from "react-router-dom";
//import "./ProductAvailabilityStores.scss";
import {
  ColDef,
  ICellRendererParams,
  RowClickedEvent,
  ValueFormatterParams,
  ValueSetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";
import { LinkContainer } from "react-router-bootstrap";
import {
  ItemAvailablity,
  KooItemavailabilitytable,
} from "../../../services/ApiClient";
import { DeleteItemRenderer } from "../../common/deleteItemRenderer/DeleteItemRenderer";
import { CheckboxRenderer } from "./CheckboxRenderer";

interface IProductAvailabilityTable {
  availabilityTable: KooItemavailabilitytable[];
  updateAvailabilityItem: (availabilityTablr: KooItemavailabilitytable) => void;
  deleteAvailabilityItem: (
    itemavailabilitytable: KooItemavailabilitytable
  ) => void;
}

export const ProductAvailabilityTable = (props: IProductAvailabilityTable) => {
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact<any>>(null);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
    }),
    []
  );

  /*
    recId: number;
    itemId: string;
    itemName: string;
    effectiveFrom: Date;
    effectiveTo: Date;
    itemAvailability: number;
    special: number;
    multiBuy: number;
    dealCostPrice: number;
    incrementBy: number;
    multiBuyNote: string;
    message: string;
    showMerchandizer: number;
    boxlot: number;
    availMon: number;
    availTue: number;
    availWed: number;
    availThu: number;
    availFri: number;
    availSat: number;
    availSun: number;
  */
  const dateFormatter = (params: ValueFormatterParams) => {
    return dayjs(params.value).format("DD/MM/YY");
  };

  const availabilityFormatter = (params: ValueFormatterParams) => {
    return ItemAvailablity[params.value];
  };

  const checkBoxValueSetter = useCallback(
    (params: ValueSetterParams) => {
      //Don't update the value in the grid data as its immutible. We will update it via parent state.
      const colId = params.column.getColId();
      const updateObj = { ...params.data };
      updateObj[colId] = params.newValue;

      props.updateAvailabilityItem(updateObj);

      return true;
    },
    [props]
  );

  const priceFormatter = (params: ValueFormatterParams) => {
    return `$${params.value.toFixed(2)}`;
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Item number",
        field: "itemId",
        flex: 5,
        //checkboxSelection: true
      },
      {
        headerName: "Item name",
        field: "itemName",
        flex: 9,
      },
      {
        headerName: "Price",
        field: "price",
        flex: 4,
        valueFormatter: priceFormatter,
      },
      {
        headerName: "Effective from",
        field: "effectiveFrom",
        flex: 6,
        type: "dateGreaterThanColumn",
        valueFormatter: dateFormatter,
      },
      {
        headerName: "Effective to",
        field: "effectiveTo",
        flex: 6,
        type: "dateLessThanColumn",
        valueFormatter: dateFormatter,
      },
      {
        headerName: "Availability",
        field: "itemAvailability",
        flex: 6,
        valueFormatter: availabilityFormatter,
      },
      {
        headerName: "Special",
        field: "special",
        flex: 4,
        cellRenderer: CheckboxRenderer,
        valueSetter: checkBoxValueSetter,
      },
      {
        headerName: "Multibuy",
        field: "multiBuy",
        flex: 4,
        cellRenderer: CheckboxRenderer,
        valueSetter: checkBoxValueSetter,
      },
      {
        headerName: "",
        field: "recId",
        flex: 4,
        filter: false,
        cellRendererSelector: (params: ICellRendererParams<any>) => {
          const button = {
            component: DeleteItemRenderer,
          };
          return button;
        },
        cellRendererParams: {
          deleteItemCallback: props.deleteAvailabilityItem,
        },
      },
    ],
    [checkBoxValueSetter, props.deleteAvailabilityItem]
  );

  const columnTypes = useMemo<{
    [key: string]: ColDef;
  }>(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateGreaterThanColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            if (cellValue == null) return -1;
            const cellDate = new Date(cellValue);
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
          defaultOption: "greaterThan",
        },
        inRangeFloatingFilterDateFormat: "Do MMM YYYY",
      },
      dateLessThanColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            if (cellValue == null) return -1;
            const cellDate = new Date(cellValue);
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
          defaultOption: "lessThan",
        },
        inRangeFloatingFilterDateFormat: "Do MMM YYYY",
      },
    };
  }, []);

  const onRowSelected = useCallback(
    (selectedRow: RowClickedEvent) => {
      const selectedColumn = selectedRow!
        .api!.getFocusedCell()!
        .column!.getColId();

      //Only navigate if slecting anything but recId, special and multiBuy
      if (
        selectedColumn !== "recId" &&
        selectedColumn !== "special" &&
        selectedColumn !== "multiBuy"
      ) {
        navigate(`/inventory/availability/${selectedRow?.data.recId}`);
      }
    },
    [navigate]
  );

  /*
  const onUpdateSpecial = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      //navigate(`/stores/${selectedRow?.id?.toLowerCase()}/settings`);
    },
    []
  );

  const onUpdateMultibuy = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      //navigate(`/stores/${selectedRow?.id?.toLowerCase()}/settings`);
    },
    []
  );

  const onCellValueChanged = useCallback((event: CellValueChangedEvent) => {
    props.updateAvailabilityItem(event.data);
  }, []);
  */

  return (
    <Row className="gy-3">
      <Col md={12}>
        <Stack gap={3} direction="horizontal">
          <h5>Available products</h5>
          <Stack gap={3} direction="horizontal" className="ms-auto">
            {
              //<Button type="button" variant="primary" onClick={props.saveAvailabilityItems}>
              //Save
              //</Button>
            }
            <LinkContainer to={"/inventory/availability/new"}>
              <Button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-plus-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                  />
                </svg>{" "}
                Add product
              </Button>
            </LinkContainer>
          </Stack>
        </Stack>
      </Col>
      <Col md={12}>
        <Card className="shadow-sm p-0" border="light">
          <Card.Body className="p-0 available-products-card">
            <div className="ag-theme-alpine ag-row-pointer available-products-container">
              <AgGridReact
                columnDefs={columnDefs}
                columnTypes={columnTypes}
                rowData={props.availabilityTable ?? []}
                defaultColDef={defaultColDef}
                domLayout="autoHeight"
                onRowClicked={onRowSelected}
                ref={gridRef}
                rowSelection={"single"}
                pagination={true}
                paginationPageSize={10}
                //onCellValueChanged={onCellValueChanged}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
