import { ICellRendererParams } from "ag-grid-community";
import { useCallback } from "react";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import { useSearchParams } from "react-router-dom";
import "./NavButtonRenderer.scss";

interface IReleaseButtonRendererProps {
    label: string;
    email: string;
    to?: string;
    includeQueryString?: boolean;
    navigate?: (data: unknown) => void;
}

export const ReleaseButtonRenderer = (props: ICellRendererParams & IReleaseButtonRendererProps) => {
    console.log("Conway 1", props);

    const [searchParams] = useSearchParams();

    const onClick = useCallback(() => {
        props.navigate && props.navigate(props.data);
    }, [props]);

    if (
        (props.data.picking && props.data.packingBy != props.email) ||
        (props.data.packing && props.data.packingBy != props.email)
    ) {
        return (
            <div className="nav-button-container">
                <Button
                    onClick={onClick}
                    size="sm"
                    style={{
                        pointerEvents: "auto",
                        backgroundColor: "#f00",
                        borderColor: "#f00",
                        opacity: 1,
                        color: "#fff",
                    }}
                >
                    {props.label}
                </Button>
            </div>
        );
    } else {
        return <></>;
    }
};
