import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  ApiException,
  UserClient,
  UserDTO,
  UserRole,
  OrderClient,
} from "../../../services/ApiClient";
import "./Users.scss";

interface IUsersProps {}

export const Users = (props: IUsersProps) => {
  const navigate = useNavigate();
  //const [error, setError] = useState<null | string>(null);
  //const [isLoaded, setIsLoaded] = useState(false);
  //const [isFetching, setIsFetching] = useState(false);
  const [rowData, setRowData] = useState<UserDTO[]>([]);

  const defaultColDef = useMemo(
    () => ({
      sortable: false,
      flex: 1,
    }),
    []
  );

  const deleteUser = useCallback(async (user: UserDTO) => {
    try {
      await new UserClient(process.env.REACT_APP_API_BASE).deleteUser(
        user.email
      );
      const result = await new UserClient(
        process.env.REACT_APP_API_BASE
      ).getUsers();
      setRowData(result);
    } catch (e: unknown) {
      const error = e as ApiException;
      console.log(error.message);
    }
  }, []);

  const userRoleFormatter = (params: ValueFormatterParams) => {
    return UserRole[params.value];
  };

  const EditCellRenderer = (props: ICellRendererParams): JSX.Element => {
    const editClicked = () => {
      navigate(`/settings/users/${props.value}`);
    };

    return (
      <div className={"btn-container"}>
        <Button size="sm" variant="light" onClick={editClicked}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-pencil-square"
            viewBox="0 0 16 16"
          >
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
            <path
              fillRule="evenodd"
              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
            ></path>
          </svg>
          {" Edit"}
        </Button>
      </div>
    );
  };

  const DeleteCellRenderer = (props: ICellRendererParams): JSX.Element => {
    const deleteClicked = () => {
      deleteUser(props.data as UserDTO);
    };

    return (
      <div className={"btn-container"}>
        <Button size="sm" variant="light" onClick={deleteClicked}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-trash"
            viewBox="0 0 16 16"
          >
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
          </svg>
          {" Delete"}
        </Button>
      </div>
    );
  };

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: "Name",
      field: "name",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      sortable: true,
    },
    {
      headerName: "Email",
      field: "email",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      sortable: true,
    },
    {
      headerName: "Role",
      field: "role",
      valueFormatter: userRoleFormatter,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      sortable: true,
    },
    {
      headerName: "",
      cellRenderer: EditCellRenderer,
      resizable: false,
      maxWidth: 140,
      field: "email",
    },
    {
      headerName: "",
      cellRenderer: DeleteCellRenderer,
      resizable: false,
      maxWidth: 140,
      field: "delete",
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //setIsFetching(true);
        const result = await new UserClient(
          process.env.REACT_APP_API_BASE
        ).getUsers();
        setRowData(result);
        //setIsLoaded(true);
        //setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        //setIsFetching(false);
        //setError(JSON.parse(error.response).error);
      }
    };

    fetchData().catch(console.error);
  }, []);

  const onRowSelected = useCallback((selectedRow: any) => {
    //navigate(`/stores/${selectedRow?.id?.toLowerCase()}/settings`);
  }, []);

  return (
    <Container>
      <Row className="gy-3">
        <Col md={12}>
          <Stack gap={3} direction="horizontal">
            <h3>Users</h3>
            <LinkContainer to={"/settings/users/new"}>
              <Button className="ms-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-plus-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                  />
                </svg>{" "}
                New
              </Button>
            </LinkContainer>
          </Stack>
        </Col>
        <Col md={12}>
          <Card className="shadow-sm p-0" border="light">
            <Card.Body className="p-0 portal-settings-form">
              <div className="ag-theme-alpine ag-row-pointer budget-multipliers">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  domLayout="autoHeight"
                  onRowClicked={onRowSelected}
                  rowSelection={"single"}
                  pagination={false}
                  paginationPageSize={100}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
