import React, { useCallback } from "react";
import { ICellRendererParams } from "ag-grid-community";
import Button from "react-bootstrap/Button";
import { OrderType } from "../../../services/ApiClient";
import { useNavigate } from "react-router-dom";

interface IAddStoreButtonRenderer {
  storeId: string;
  orderType: OrderType;
  orderDate: string;
  refreshCallback: () => void;
  setError: (error: any) => void;
}

export const AddStoreButtonRenderer = (
  props: ICellRendererParams & IAddStoreButtonRenderer
) => {
  const navigate = useNavigate();
  const addStore = useCallback(async () => {
    navigate(
      `/stores/${props.data.id.toLocaleLowerCase()}/orders/${OrderType[
        props.orderType
      ].toLocaleLowerCase()}/${props.orderDate?.toLowerCase()}`
    );
  }, [props, navigate]);

  return (
    <div className={"btn-container"}>
      <Button size="sm" variant="primary" onClick={() => addStore()}>
        {"Add store"}
      </Button>
    </div>
  );
};
