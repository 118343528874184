import {
  CellClassParams,
  CellEditRequestEvent,
  ColDef,
  EditableCallbackParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import { ItemDTO, OrderDTO, OrderDetailDTO } from "../../../services/ApiClient";
import { CustomNameWastageRenderer } from "./CustomNameWastageRenderer";
import { EditIndicatorCellRenderer } from "./EditIndicator";
import { IColumnType } from "./IColumnType";

interface IOrdersTableProps {
  availableItems: ItemDTO[];
  orders: OrderDTO[];
  alignedGrids: AgGridReact<any>[] | undefined;
  updateOrder: (
    orderDetailId: number,
    orderId: number,
    orderItemRecId: number,
    newValue: number,
    oldValue: number
  ) => void;
}

export const OrderTable = forwardRef<AgGridReact, IOrdersTableProps>(
  (props: IOrdersTableProps, ref) => {
    const params = useParams();
    const [orderColumnDefs, setOrderColumnDefs] = useState([]);
    const [rowData, setRowdata] = useState<any[]>([]);

    //Manage header float
    const [tableTopRef, topInView] = useInView();
    const [tableBottomRef, bottomInView] = useInView();
    const [tableRef, tableInView] = useInView();

    const defaultOrderColumnDefs = useMemo<ColDef>(() => {
      return {
        type: "editableColumn",
      };
    }, []);

    const setOrderColumns = useCallback(
      (availableItems: ItemDTO[]) => {
        const columnDefs = [
          {
            headerName: "T",
            field: "teamLeadReviewed",
            pinned: "left",
            editable: false,
            width: 40,
            cellClass: "edit-indicator-cell",
            cellRenderer: EditIndicatorCellRenderer,
            headerClass: "edit-indicator-heading",
            sortable: true,
          },
          {
            headerName: "M",
            field: "managerChanged",
            pinned: "left",
            editable: false,
            width: 40,
            cellClass: "edit-indicator-cell",
            cellRenderer: EditIndicatorCellRenderer,
            headerClass: "edit-indicator-heading",
            sortable: true,
          },
          {
            headerName: "Customer",
            field: "store.name",
            pinned: "left",
            editable: false,
            width: 190,
            sortable: true,
            resizable: true,
            filter: true,
            cellRenderer: CustomNameWastageRenderer,
          },
          {
            headerName: "This order",
            field: "total",
            pinned: "left",
            editable: false,
            width: 105,
            autoHeaderHeight: true,
            wrapHeaderText: true,
            sortable: true,
            valueFormatter: (params: ValueFormatterParams) => {
              return params.value.toFixed(2);
            },
          },
          ...(params.orderType === "cut"
            ? [
                {
                  headerName: "WTD",
                  field: "store.totalWeekToDate",
                  pinned: "left",
                  editable: false,
                  width: 84,
                  sortable: true,
                  valueFormatter: (params: ValueFormatterParams) => {
                    return params.value.toFixed(2);
                  },
                },
              ]
            : []),
          ...(params.orderType === "pots"
            ? [
                {
                  headerName: "MTD",
                  field: "store.totalMonth",
                  pinned: "left",
                  editable: false,
                  width: 84,
                  sortable: true,
                  valueFormatter: (params: ValueFormatterParams) => {
                    return params.value.toFixed(2);
                  },
                },
              ]
            : []),
          {
            headerName: "Budget",
            field: "store.budget",
            pinned: "left",
            editable: false,
            width: 94,
            sortable: true,
            valueGetter: budgetValueGetter,
          },
          {
            headerName: "Used",
            field: "used",
            pinned: "left",
            editable: false,
            width: 84,
            sortable: true,
            valueGetter: usedValueGetter,
            valueFormatter: usedValueFormatter,
            type: "numericColumn",
          },
        ] as any;

        availableItems.forEach((item) => {
          columnDefs.push({
            headerName: `${item.itemName} $${item.price}`,
            field: `${item.recId}`,
            //editable: true,
            autoHeaderHeight: true,
            wrapHeaderText: true,
            width: 120,
            sortable: true,
          });
        });

        setOrderColumnDefs(columnDefs);
      },
      [setOrderColumnDefs, params]
    );

    useEffect(() => {
      setOrderColumns(props.availableItems);
    }, [props.availableItems, setOrderColumns]);

    const setOrderRows = useCallback(
      (orders: OrderDTO[]) => {
        const rows: any[] = [];

        orders.forEach((order) => {
          const row = { ...order } as any;

          order?.orderDetails?.forEach((orderDetails) => {
            row[orderDetails.item?.recId!] = orderDetails.quantity;
          });

          rows.push(row);
        });

        setRowdata(rows);
      },
      [setRowdata]
    );

    useEffect(() => {
      setOrderRows(props.orders);
    }, [props.orders, setOrderRows]);

    const usedValueGetter = (params: ValueGetterParams) => {
      return parseFloat(params.data.store.budgetUsed);
    };

    const usedValueFormatter = (params: ValueFormatterParams) => {
      return params.value.toFixed(0) + "%";
    };

    const budgetValueGetter = (params: ValueGetterParams) => {
      return parseFloat(params.data.store.budget.toFixed(0));
    };

    const orderColumnTypes = useMemo<IColumnType>(() => {
      return {
        editableColumn: {
          editable: (params: EditableCallbackParams<any>) => {
            return isOrderCellEditable(params);
          },
          cellStyle: (params: CellClassParams<any>) => {
            if (!isOrderCellEditable(params)) {
              return { backgroundColor: "#D9F7FA" };
            }
          },
        },
      };
    }, []);

    const isOrderCellEditable = (
      params: EditableCallbackParams | CellClassParams
    ) => {
      //return true;
      return (
        params.data.posted !== 1 &&
        params.data.confirmed !== 1 &&
        !(params.data.picking || params.data.picked)
      );
    };

    const onCellEditRequest = useCallback(
      (event: CellEditRequestEvent) => {
        //const data = event.data;
        const field = parseInt(event.colDef.field!);
        const newValue = parseInt(event.newValue);
        const oldValue = parseInt(event.oldValue);
        const orderDetail = event.data.orderDetails.find(
          (orderDetail: OrderDetailDTO) => orderDetail.itemId === field
        );
        const orderDetailId = orderDetail.orderDetailId ?? 0;

        if (field >= 0 && newValue >= 0) {
          props.updateOrder(
            orderDetailId,
            event.data.orderId,
            field,
            newValue,
            oldValue
          );
        }
      },
      [props]
    );

    return (
      <>
        <div ref={tableTopRef} />
        <div
          ref={tableRef}
          className={`
                    ${!topInView && tableInView ? " sticky-header" : ""}
                    ${
                      tableInView &&
                      !bottomInView &&
                      !(topInView && bottomInView)
                        ? " sticky-scroll"
                        : ""
                    }
                    `}
        >
          <AgGridReact
            ref={ref}
            className={`ag-theme-alpine`}
            defaultColDef={defaultOrderColumnDefs}
            columnDefs={orderColumnDefs}
            columnTypes={orderColumnTypes}
            rowData={rowData}
            domLayout="autoHeight"
            alignedGrids={props.alignedGrids ? props.alignedGrids : undefined}
            singleClickEdit={true}
            columnHoverHighlight={true}
            onCellEditRequest={onCellEditRequest}
            readOnlyEdit={true}
            maintainColumnOrder={true}
          />
        </div>
        <div ref={tableBottomRef} />
      </>
    );
  }
);
