import React from "react";
import Stack from "react-bootstrap/Stack";
import Spinner from "react-bootstrap/Spinner";
import "./CardPlaceholder.scss";

export const CardPlaceholder = () => {
  return (
    <div className="card-placeholder">
      <Stack className="col-md-5 mx-auto align-items-center">
        <Spinner animation="border" variant="primary" />
      </Stack>
    </div>
  );
};
