import React, { useCallback, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { UserRole } from "../../../services/ApiClient";

import { NavCard } from "../../common/navCard/NavCard";
import { NavCardRow } from "../../common/navCard/NavCardRow";

import { ReactComponent as CutIcon } from "../../../assets/local_florist_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as PotsIcon } from "../../../assets/potted_plant_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as MaterialsIcon } from "../../../assets/shopping_basket_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as TotalOrderedIcon } from "../../../assets/shopping_cart_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as StoreIcon } from "../../../assets/store_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as KpiIcon } from "../../../assets/speed_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as WasteageIcon } from "../../../assets/auto_delete_FILL1_wght700_GRAD200_opsz48.svg";
import { ReactComponent as PhotoIcon } from "../../../assets/add_a_photo_FILL1_wght700_GRAD200_opsz48.svg";
import { useParams } from "react-router-dom";
import { OrderClient } from "../../../services/ApiClient";

export const OrderingHome = () => {
  const params = useParams();
  const [storeName, setStoreName] = useState<string>("");

  const getStoreName = useCallback(async () => {
    const storeNames = await new OrderClient(
      process.env.REACT_APP_API_BASE
    ).getStoreNames();
    const storeName = storeNames.find(
      (storeName) => storeName.id.toLowerCase()! === params.storeid
    )?.name;

    if (storeName) {
      setStoreName(storeName);
    }
  }, [params.storeid]);

  useEffect(() => {
    getStoreName();
  });

  return (
    <Container>
      <Row xs={1} md={3} lg={3} className="g-4">
        <Col lg={12}>
          <h5 className="m-0 mt-4">{storeName}</h5>
        </Col>
        <NavCardRow
          title={"Reports"}
          requiredRoles={[
            UserRole.Merchandiser,
            UserRole.RetailManager,
            UserRole.Manager,
            UserRole.RegionalManager,
          ]}
        >
          <NavCard
            title={"KPIs"}
            icon={KpiIcon}
            navigateTo={"kpi"}
            requiredRoles={[
              UserRole.Merchandiser,
              UserRole.RetailManager,
              UserRole.Manager,
              UserRole.RegionalManager,
            ]}
          />
          <NavCard
            title={"Wastage report"}
            icon={WasteageIcon}
            navigateTo={"wastage"}
            requiredRoles={[
              UserRole.Merchandiser,
              UserRole.RetailManager,
              UserRole.Manager,
              UserRole.RegionalManager,
            ]}
          />
        </NavCardRow>
        <NavCardRow
          title={"Orders"}
          requiredRoles={[
            UserRole.Merchandiser,
            UserRole.InStoreStaff,
            UserRole.RetailManager,
            UserRole.Manager,
            UserRole.RegionalManager,
          ]}
        >
          <NavCard
            title={"Order cuts"}
            icon={CutIcon}
            navigateTo={"orders/cut"}
            requiredRoles={[
              UserRole.Merchandiser,
              UserRole.InStoreStaff,
              UserRole.RetailManager,
              UserRole.Manager,
              UserRole.RegionalManager,
            ]}
          />
          <NavCard
            title={"Order pots"}
            icon={PotsIcon}
            navigateTo={"orders/pots"}
            requiredRoles={[
              UserRole.Merchandiser,
              UserRole.InStoreStaff,
              UserRole.RetailManager,
              UserRole.Manager,
              UserRole.RegionalManager,
            ]}
          />
          <NavCard
            title={"Order materials"}
            icon={MaterialsIcon}
            navigateTo={"orders/materials"}
            requiredRoles={[
              UserRole.Merchandiser,
              UserRole.InStoreStaff,
              UserRole.RetailManager,
              UserRole.Manager,
              UserRole.RegionalManager,
            ]}
          />
          <NavCard
            title={"Current orders"}
            icon={TotalOrderedIcon}
            navigateTo={"orders"}
            requiredRoles={[
              UserRole.Merchandiser,
              UserRole.InStoreStaff,
              UserRole.RetailManager,
              UserRole.Manager,
              UserRole.RegionalManager,
            ]}
          />
        </NavCardRow>
        <NavCardRow
          title={"Shift photos"}
          requiredRoles={[
            UserRole.Merchandiser,
            UserRole.RetailManager,
            UserRole.Manager,
            UserRole.RegionalManager,
          ]}
        >
          <NavCard
            title={"Send photo start of shift"}
            icon={PhotoIcon}
            navigateTo={"shiftphotos/start"}
            requiredRoles={[
              UserRole.Merchandiser,
              UserRole.RetailManager,
              UserRole.Manager,
              UserRole.RegionalManager,
            ]}
          />
          <NavCard
            title={"Send photo end of shift"}
            icon={PhotoIcon}
            navigateTo={"shiftphotos/end"}
            requiredRoles={[
              UserRole.Merchandiser,
              UserRole.RetailManager,
              UserRole.Manager,
              UserRole.RegionalManager,
            ]}
          />
        </NavCardRow>
        <NavCardRow
          title={"Change store"}
          requiredRoles={[
            UserRole.Merchandiser,
            UserRole.RetailManager,
            UserRole.Manager,
            UserRole.RegionalManager,
          ]}
        >
          <NavCard
            title={"Select store"}
            icon={StoreIcon}
            navigateTo={"/stores"}
            requiredRoles={[
              UserRole.Merchandiser,
              UserRole.InStoreStaff,
              UserRole.RetailManager,
              UserRole.Manager,
              UserRole.RegionalManager,
            ]}
          />
        </NavCardRow>
      </Row>
    </Container>
  );
};
