/****************************************************************************************************
 * Imports
 ****************************************************************************************************/
import { useCallback, useEffect, useState } from "react";
import { ReactComponent as NoteIcon } from "../../../assets/chat_add_on_FILL0_wght400_GRAD0_opsz48.svg";

import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

/****************************************************************************************************
 * Interfaces
 ****************************************************************************************************/
interface IMarkdownItemProps {
  itemId: string;
  name: string;
  originalPrice: number;
  markdownPrice: number;
  image: string;
  note: string | undefined;
  increment: number;
  quantity: number;
  updateItemComment: (
    itemId: string,
    originalPrice: number,
    comment: string
  ) => void;
  updateItemPrice?: (
    itemId: string,
    originalPrice: number,
    markdownPrice: number
  ) => void;
  updateItemQty: (
    itemId: string,
    originalPrice: number,
    quanity: number
  ) => void;
  updateItemTotalQty: (qtyToAdd: number) => void;
  readonlyPrice: boolean;
  isMiscItem?: boolean;
  updateOriginalPrice?: (itemId: string, originalPrice: number) => void;
}

/****************************************************************************************************
 * Component
 ****************************************************************************************************/
export const MarkdownItem = (props: IMarkdownItemProps) => {
  //console.log("Rendering MarkdownItem...");

  /************************************************************************************************
   * States
   ************************************************************************************************/
  const [showItemNote, setShowItemNote] = useState<Boolean>(false);
  const [showImage, setShowImage] = useState<Boolean>(false);

  /************************************************************************************************
   * Effects
   ************************************************************************************************/
  useEffect(() => {
    const showIamge =
      props.image?.length > 0 && props.image !== "photo_not_available.jpg";
    setShowImage(showIamge);
  }, [props.image]);

  /************************************************************************************************
   * Support Functions
   ************************************************************************************************/
  const toggleItemNote = useCallback(() => {
    if (showItemNote) {
      props.updateItemComment(props.itemId, props.originalPrice, "");
    }
    setShowItemNote((prevNote) => !prevNote);
  }, [props, showItemNote]);

  const setItemNote = useCallback(
    (comment: string) => {
      props.updateItemComment(props.itemId, props.originalPrice, comment);
    },
    [props]
  );

  const updateMarkdownPrice = useCallback(
    (price: string) => {
      if (props.updateItemPrice) {
        props.updateItemPrice(props.itemId, props.originalPrice, Number(price));
      }
    },
    [props]
  );

  const updateOriginalPrice = useCallback(
    (price: string) => {
      if (props.updateOriginalPrice) {
        props.updateOriginalPrice(props.itemId, Number(price));
      }
    },
    [props]
  );

  const increaseQty = useCallback(() => {
    props.updateItemQty(
      props.itemId,
      props.originalPrice,
      props.quantity + props.increment
    );
    props.updateItemTotalQty(props.increment);
  }, [props]);

  const decreaseQty = useCallback(() => {
    props.updateItemQty(
      props.itemId,
      props.originalPrice,
      props.quantity - props.increment
    );
    props.updateItemTotalQty(-props.increment);
  }, [props]);

  const updateQty = useCallback(
    (qty: number) => {
      props.updateItemQty(props.itemId, props.originalPrice, qty);
    },
    [props]
  );

  /************************************************************************************************
   * Render
   ************************************************************************************************/
  return (
    <>
      <Row className="order-item m-4">
        <Col xs={12} lg={9} xl={9} className={"mb-3 mb-lg-0"}>
          <Stack direction="horizontal" gap={4}>
            {showImage && (
              <Image
                src={props.image}
                rounded
                fluid
                className="order-item-thumb"
              />
            )}
            <Row>
              <Col xs={12} lg={"auto"}>
                <h5 className={"item-title-normal"}>
                  {props.name}
                  {props.originalPrice ? ` - $${props.originalPrice}` : ""}
                </h5>
              </Col>
              <Col xs={12}>
                <Stack gap={3} direction="horizontal">
                  {!props.isMiscItem && (
                    <Button
                      variant="light"
                      title="Add"
                      size="sm"
                      onClick={() => toggleItemNote()}
                    >
                      <NoteIcon width={16} height={16} />
                      {`Add note`}
                    </Button>
                  )}
                </Stack>
                <Stack gap={3} direction="horizontal">
                  {(showItemNote || props.isMiscItem) && (
                    <Form.Control
                      maxLength={1024}
                      onChange={(e) => setItemNote(e.currentTarget.value)}
                      className={"mt-2"}
                      type="text"
                      placeholder="Product name.."
                      defaultValue={props.note}
                    />
                  )}
                  {props.isMiscItem && (
                    <InputGroup className="fd-input-container mt-2">
                      <InputGroup.Text>Original $</InputGroup.Text>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        defaultValue={props.originalPrice}
                        min="0.00"
                        max="10000.00"
                        step="0.01"
                        onBlur={(e) =>
                          updateOriginalPrice(e.currentTarget.value)
                        }
                      />
                    </InputGroup>
                  )}
                  {!props.readonlyPrice && (
                    <InputGroup className="fd-input-container mt-2">
                      <InputGroup.Text>Markdown $</InputGroup.Text>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        defaultValue={props.markdownPrice}
                        min="0.00"
                        max="10000.00"
                        step="0.01"
                        onBlur={(e) =>
                          updateMarkdownPrice(e.currentTarget.value)
                        }
                        readOnly={props.readonlyPrice && !props.isMiscItem}
                        disabled={props.readonlyPrice && !props.isMiscItem}
                        hidden={props.readonlyPrice && !props.isMiscItem}
                      />
                    </InputGroup>
                  )}
                </Stack>
              </Col>
            </Row>
          </Stack>
        </Col>
        <Col
          xs={12}
          lg={3}
          xl={3}
          className={
            "px-2 align-items-center d-justify-content-center d-lg-justify-content-flex-end d-flex"
          }
        >
          <div className="order-item-qty-button-cnt">
            <InputGroup>
              <Button
                className="order-item-qty-button"
                variant="primary"
                title="Remove"
                id="decrease-quantity"
                size="sm"
                onClick={decreaseQty}
                disabled={props.quantity <= 0}
              >
                -
              </Button>
              <Form.Control
                type="number"
                placeholder="0"
                min="0.00"
                max="10000.00"
                step="1"
                disabled={false}
                value={props.quantity}
                onChange={(e) => updateQty(Number(e.currentTarget.value))}
              />
              <Button
                className="order-item-qty-button"
                variant="primary"
                title="Add"
                id="increase-quantity"
                size="sm"
                onClick={increaseQty}
              >
                +
              </Button>
            </InputGroup>
          </div>
        </Col>
      </Row>
    </>
  );
};
