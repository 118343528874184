import React, { useCallback, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import { LinkContainer } from "react-router-bootstrap";
import "./NavMenu.scss";
import Logo from "../../../assets/FreshDirectLogo-white.png";
import Stack from "react-bootstrap/Stack";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { useMsal, useAccount } from "@azure/msal-react";

interface INavMenuProps {}

export const NavMenu = (props: INavMenuProps) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");

  useEffect(() => {
    if (account && account.name) {
      setName(account.name.split(" ")[0]);
    } else {
      setName("");
    }
  }, [account]);

  const signOut = useCallback(() => {
    instance.logoutRedirect();
  }, [instance]);

  return (
    <header>
      <Navbar variant="dark" className={"nav-menu flex-sm-row"}>
        <Container>
          <LinkContainer to={"/"}>
            <Navbar.Brand href="/">
              <Stack direction="horizontal" gap={4}>
                <Image
                  src={Logo}
                  width="160px"
                  className="d-inline-block align-top nav-menu-logo"
                  alt="Fresh Direct logo"
                />{" "}
                <div className="vr nav-vr d-none d-md-block" />
                <div className="nav-menu-app-name d-none d-md-block">
                  Ordering Portal
                </div>
                <DropdownButton title={name} className="ms-auto">
                  <Dropdown.Item eventKey="1" onClick={signOut}>
                    Logout
                  </Dropdown.Item>
                </DropdownButton>
              </Stack>
            </Navbar.Brand>
          </LinkContainer>
        </Container>
      </Navbar>
    </header>
  );
};
