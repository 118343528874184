import { ICellRendererParams } from "ag-grid-community";
import React, { useCallback, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import { OrderDetailDTO } from "../../../services/ApiClient";

interface ICommentsRendererProps {
  updateComment: (currentOrder: OrderDetailDTO) => void;
  pickPackStatus: number;
}

export const CommentsRenderer = (
  props: ICellRendererParams & ICommentsRendererProps
) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused] = React.useState(false);

  console.log(props);

  const onBlur = useCallback(
    (e: React.ChangeEvent<any>) => {
      const data = { ...props.data };
      const colId = props.column?.getColId()!;
      data[colId] = e.target.value;
      props.updateComment(data);
    },
    [props]
  );

  useEffect(() => {
    if (focused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focused]);

  const CommentsField = () => {
    return (
      <Form.Control
        type="text"
        defaultValue={props.value}
        onBlur={onBlur}
        ref={inputRef}
      />
    );
  };

  return (
    <>
      {props.data.pickPackStatus === props.pickPackStatus ||
      props.data.deleted ? (
        <>{props.value}</>
      ) : (
        <div className="fd-input-container">
          <CommentsField />
        </div>
      )}
    </>
  );
};
