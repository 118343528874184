import { useState, useEffect, useCallback } from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  ApiException,
  CreditRequestDTO,
  OrderClient,
  CreditrequestStatus,
} from "../../../services/ApiClient";
import { NoCards } from "../../layouts/NoCards";
import { MerchandiseCreditRequestsTable } from "./MerchandiseCreditRequestsTable";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { useImmer } from "use-immer";
import "./MerchandiseCreditRequests.scss";

interface IMerchandiseCreditRequestsProps {}

export const MerchandiseCreditRequests = (
  props: IMerchandiseCreditRequestsProps
) => {
  const [error, setError] = useState<null | string>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [allCreditRequestData, setAllCreditRequestData] = useImmer<
    CreditRequestDTO[] | null
  >(null);
  const [filteredCreditRequestData, setFilteredCreditRequestData] = useImmer<
    CreditRequestDTO[] | null
  >(null);
  const [menuState, setMenuState] = useState<string>("pending");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetching(true);
        const result = await new OrderClient().getMerchandiserCreditRequests();
        setAllCreditRequestData(result);
        setIsLoaded(true);
        setIsFetching(false);
      } catch (e: unknown) {
        const error = e as ApiException;
        console.log(error.message);
        setIsFetching(false);
        setError(JSON.parse(error.response).error);
      }
    };

    fetchData().catch(console.error);
  }, [setAllCreditRequestData]);

  useEffect(() => {
    if (allCreditRequestData) {
      const filtered = allCreditRequestData.filter((item) =>
        menuState === "pending"
          ? item.status === CreditrequestStatus.New
          : item.status !== CreditrequestStatus.New
      );
      setFilteredCreditRequestData(filtered);
    }
  }, [menuState, allCreditRequestData, setFilteredCreditRequestData]);

  const onNavItemClicked = useCallback((eventKey: string) => {
    setMenuState(eventKey);
  }, []);

  return (
    <div className={""}>
      <NoCards pageTitle="">
        {!isFetching && isLoaded && filteredCreditRequestData ? (
          <>
            <Col lg={12}>
              <MerchandiseCreditRequestsTable
                creditRequestData={filteredCreditRequestData}
                onNavItemClicked={onNavItemClicked}
                menuState={menuState}
              />
            </Col>
          </>
        ) : (
          <CardPlaceholder />
        )}
      </NoCards>
      <Modal show={error?.length! > 0} onHide={() => setError(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setError(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
