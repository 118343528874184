import { useCallback, useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { RoleContext } from "../../../App";
import {
    ApiException,
    DeliveryDateDTO,
    NewOrderViewDTO,
    OrderClient,
    OrderType,
    UserRole,
} from "../../../services/ApiClient";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { NoCards } from "../../layouts/NoCards";
import { ConfirmOrder } from "./ConfirmOrder";
import "./NewOrder.scss";
import { NewOrderForm } from "./NewOrderForm";

interface INewOrderProps {}

export const NewOrder = (props: INewOrderProps) => {
    const params = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState<null | string>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [order, setOrder] = useImmer<NewOrderViewDTO | null>(null);
    const [possibleDeilveryDates, setPossibleDilveryDates] = useState<
        DeliveryDateDTO[]
    >([]);
    const [currentDeliveryDate, setCurrentDeliveryDate] =
        useState<DeliveryDateDTO | null>(null);
    const [hasUpdated, setHasUpdated] = useState<boolean>(false);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [orderTotal, setOrderTotal] = useState<number>(0);
    const [orderTotalQty, setOrderTotalQty] = useState<number>(0);
    const [saving, setSaving] = useState<boolean>(false);
    const roleContext = useContext(RoleContext);

    useEffect(() => {
        const fetchNewOrder = async () => {
            try {
                setIsFetching(true);

                const orderType = params.orderType;
                let orderIndex;

                if (orderType === "pots") {
                    orderIndex = 0;
                } else if (orderType === "cut" || orderType === "cuts") {
                    orderIndex = 1;
                } else if (orderType === "materials") {
                    orderIndex = 2;
                }

                if (params.deliveryDate) {
                    const newOrder = await new OrderClient(
                        process.env.REACT_APP_API_BASE
                    ).initNewOrder(params.storeid, orderIndex, params.deliveryDate);
                    setOrder(newOrder);
                    setPossibleDilveryDates(newOrder.deliveryDates);
                } else if (currentDeliveryDate?.actualValue) {
                    const filterDate = currentDeliveryDate?.actualValue;
                    const newOrder = await new OrderClient(
                        process.env.REACT_APP_API_BASE
                    ).initNewOrder(params.storeid, orderIndex, filterDate);
                    setOrder(newOrder);
                } else {
                    const newOrder = await new OrderClient(
                        process.env.REACT_APP_API_BASE
                    ).initNewOrder(params.storeid, orderIndex);
                    setOrder(newOrder);
                    setPossibleDilveryDates(newOrder.deliveryDates);
                }

                setIsLoaded(true);
                setIsFetching(false);
            } catch (e: unknown) {
                const error = e as ApiException;
                console.log(error.message);
                setIsFetching(false);
                setError(JSON.parse(error.response).errorMessages[0]);
            }
        };

        fetchNewOrder().catch(console.error);
    }, [
        currentDeliveryDate,
        params.deliveryDate,
        params.orderType,
        params.storeid,
        setOrder,
    ]);

    const setDeliveryDate = useCallback(
        (delvieryDate: DeliveryDateDTO) => {
            setCurrentDeliveryDate(delvieryDate);
        },
        [setCurrentDeliveryDate]
    );

    const setItemQty = useCallback(
        (itemId: number, quanity: number, price: number) => {
            setOrder((draft) => {
                const updateOrderItem = draft?.order.orderDetails.find(
                    (orderDetail) => orderDetail.itemId === itemId
                );
                if (updateOrderItem) {
                    updateOrderItem.quantity = quanity;
                }
            });
            setHasUpdated(true);
        },
        [setOrder]
    );

    const backToOrder = useCallback(async () => {
        setConfirm(false);
    }, [setConfirm]);

    const saveOrder = useCallback(async () => {
        try {
            if (order && hasUpdated) {
                setSaving(true);
                await new OrderClient(process.env.REACT_APP_API_BASE).confirmOrder(
                    order!
                );
                navigate(`/stores/${params.storeid}/orders`);
            }
        } catch (e: unknown) {
            const error = e as ApiException;
            setError(JSON.parse(error.response).error);
        }
    }, [order, hasUpdated, setSaving, navigate, params.storeid]);

    const setConfirmState = useCallback(async () => {
        const orderTotal = order?.order.orderDetails.reduce((sum, item) => {
            return sum + item.unitPrice * item.quantity;
        }, 0);
        setOrderTotal(orderTotal!);
        setConfirm(true);
    }, [setConfirm, order]);

    const setItemTotalQty = useCallback(
        (qtyToAdd: number) => {
            setOrderTotalQty((prevQty) => {
                return prevQty + qtyToAdd;
            });
        },
        [setOrderTotalQty]
    );

    const setItemNoteState = useCallback(
        (itemId: number, value: string | undefined, price: number) => {
            setOrder((draft) => {
                const updateOrderItem = draft?.order.orderDetails.find(
                    (orderDetail) => orderDetail.itemId === itemId
                );
                if (updateOrderItem) {
                    updateOrderItem.comment = value;
                }
            });
            setHasUpdated(true);
        },
        [setOrder]
    );

    return (
        <>
            {!isFetching && isLoaded ? (
                <>
                    {!confirm ? (
                        <NewOrderForm
                            order={order!}
                            setItemQty={setItemQty}
                            setDelvieryDate={setDeliveryDate}
                            confirmOrder={setConfirmState}
                            possibleDeliveryDates={possibleDeilveryDates}
                            currentDeliveryDate={currentDeliveryDate!}
                            updateItemTotalQty={setItemTotalQty}
                            totalOrderQty={orderTotalQty}
                            setItemNote={setItemNoteState}
                            showCostPrice={roleContext?.role === UserRole.InStoreStaff}
                        />
                    ) : (
                        <ConfirmOrder
                            orderType={OrderType[order?.order.orderType!]}
                            orderDate={order?.order.deliveryDate!}
                            orderData={order?.order.orderDetails!}
                            budget={order?.order.store?.budget!}
                            budgetUsedWtd={order?.order.store?.totalWeekToDate!}
                            budgetUsedMtd={order?.order.store?.totalMonth!}
                            orderTotal={orderTotal!}
                            saving={saving}
                            confirmOrder={saveOrder}
                            backToOrder={backToOrder}
                            storeName={order?.order.store?.name!}
                        />
                    )}
                </>
            ) : (
                <NoCards>
                    <Col xs={12} lg={12}>
                        <Card className="shadow-sm p-0" border="light">
                            <Card.Body className="p-0 available-products-card">
                                <CardPlaceholder />
                            </Card.Body>
                        </Card>
                    </Col>
                </NoCards>
            )}

            <Modal show={error?.length! > 0} onHide={() => setError(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{error}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setError(null)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
