import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

interface IConfirmPackModalProps {
  show: boolean;
  onHide: (hide: boolean) => void;
  handlePickAndPack: (noBoxes: number) => void;
}

export const ConfirmPackModal = (props: IConfirmPackModalProps) => {
  const [boxesPacked, setBoxesPacked] = useState<number>(0);

  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide(false)}
      backdrop="static"
      keyboard={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Complete Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3 align-items-center">
          <Col md={12}>
            <h4 className="fw-normal">How many boxes were packed?</h4>
            <input
              type="number"
              value={boxesPacked === null ? "" : boxesPacked.toString()}
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value === "" ? null : parseInt(value, 10);
                setBoxesPacked(parsedValue ?? 0);
              }}
              className="form-control"
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide(false)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => props.handlePickAndPack(boxesPacked)}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
