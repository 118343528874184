import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import Button from "react-bootstrap/Button";

interface IZeroButtonProps {
  zeroValuesForItem: (recId: number) => void;
  recId: number;
}

export const ZeroButton = (props: ICellRendererParams & IZeroButtonProps) => {
  return (
    <>
      {props.recId && (
        <div className="zero-button-container">
          <Button
            size="sm"
            onClick={() => props.zeroValuesForItem(props.recId)}
          >
            Zero
          </Button>
        </div>
      )}
    </>
  );
};
