import dayjs from "dayjs";
import { Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import {
    ApiException,
    ItemDTO,
    OrderDetailDTO,
    OrderType,
    StoreDTO,
    VanTransferClient,
    VanTransferStoreListDTO,
} from "../../../services/ApiClient";
import { CardPlaceholder } from "../../common/cardPlaceholder/CardPlaceholder";
import { NoCards } from "../../layouts/NoCards";
import { ConfirmTransfer } from "./ConfirmTransfer";
import "./NewTransfer.scss";
import { NewTransferForm } from "./NewTransferForm";

interface INewTransferProps {}

interface ITransferSettings {
    transferType: string;
    fromStore: string;
    toStore: string;
}

export const NewTransfer = (props: INewTransferProps) => {
    const navigate = useNavigate();
    const [error, setError] = useState<null | string>(null);
    //const [isLoaded, setIsLoaded] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const [hasUpdated, setHasUpdated] = useState<boolean>(false);

    const [confirm, setConfirm] = useState<boolean>(false);
    const [orderTotal, setOrderTotal] = useState<number>(0);
    const [orderTotalQty, setOrderTotalQty] = useState<number>(0);

    //const [isSaving, setIsSaving] = useState(false);
    //const [saved, setSaved] = useState(false);

    const [transferStores, setTransferStores] =
        useState<VanTransferStoreListDTO | null>(null);
    const [transferType, setTransferType] = useState<string | null>(null);
    const [fromStore, setFromStore] = useState<string | null>(null);
    const [toStore, setToStore] = useState<string | null>(null);
    const [selectedToStoreDTO, setSelectedToStoreDTO] = useState<StoreDTO | null>(
        null
    );
    const [showOrderForm, setShowOrderForm] = useState<boolean>(false);

    const [orderDetails, setOrderDetails] = useImmer<OrderDetailDTO[] | null>(
        null
    );
    const [orderSaving, setOrderSaving] = useState<boolean>(false);

    useEffect(() => {
        const fetchNewTransferOptions = async () => {
            try {
                setIsFetching(true);

                const stores = await new VanTransferClient(
                    process.env.REACT_APP_API_BASE
                ).getVanTransferStore();

                //Backend is sometimes returning duplicates. Filter them out here.
                stores.nonvanStores = stores.nonvanStores.filter(
                    (a, i) =>
                        stores.nonvanStores.findIndex((s) => a.name === s.name) === i
                );
                stores.vanStores = stores.vanStores.filter(
                    (a, i) => stores.vanStores.findIndex((s) => a.name === s.name) === i
                );

                setTransferStores(stores);
                setSelectedToStoreDTO(stores.nonvanStores[0]);

                //setIsLoaded(true);
                setIsFetching(false);
            } catch (e: unknown) {
                const error = e as ApiException;
                console.log(error.message);
                setIsFetching(false);
                setError(JSON.parse(error.response).errorMessages[0]);
            }
        };

        fetchNewTransferOptions().catch(console.error);
    }, []);

    const getEmptyOrderDetailDTO = (
        currentItem: ItemDTO,
        orderId: number | undefined
    ): OrderDetailDTO => {
        return {
            orderDetailId: 0,
            orderId: orderId ?? 0,
            item: currentItem,
            quantity: 0,
            unitPrice: currentItem.price,
            total: 0,
            pickPackStatus: 0,
            received: false,
            deleted: false,
            itemId: Number(currentItem.recId),
            preOrder: 0,
            dealCostPrice: 0,
            price: currentItem.price,
        };
    };

    useEffect(() => {
        if (showOrderForm && fromStore && transferType) {
            const fetchNewTransfer = async () => {
                try {
                    setIsFetching(true);

                    const orderType: OrderType =
                        OrderType[transferType as keyof typeof OrderType];
                    const availableItems = await new VanTransferClient(
                        process.env.REACT_APP_API_BASE
                    ).getAvailableProductsForVanStore(fromStore, orderType);

                    let orderDetailDTO: OrderDetailDTO[] = availableItems.map((item) => {
                        return getEmptyOrderDetailDTO(item, 0);
                    });

                    setOrderDetails(orderDetailDTO);
                    const targetStore = await new VanTransferClient(process.env.REACT_APP_API_BASE).getTargetStore(
                        toStore,
                        orderType
                    );

                    setSelectedToStoreDTO(targetStore ?? null);

                    //setIsLoaded(true);
                    setIsFetching(false);
                } catch (e: unknown) {
                    const error = e as ApiException;
                    console.log(error.message);
                    setIsFetching(false);
                    setError(JSON.parse(error.response).errorMessages[0]);
                }
            };

            fetchNewTransfer().catch(console.error);
        }
    }, [showOrderForm, fromStore, toStore, transferType, setOrderDetails]);

    const setItemQty = useCallback(
        (itemId: number, quanity: number, price: number) => {
            setOrderDetails((draft) => {
                const updateOrderItem = draft?.find(
                    (orderDetail) =>
                        orderDetail.itemId === itemId && orderDetail.unitPrice === price
                );
                if (updateOrderItem) {
                    updateOrderItem.quantity = quanity;
                }
            });
            setHasUpdated(true);
        },
        [setOrderDetails]
    );

    const setItemPrice = useCallback(
        (itemId: number, price: number, oldPrice: number) => {
            setOrderDetails((draft) => {
                const updateOrderItem = draft?.find(
                    (orderDetail) =>
                        orderDetail.itemId === itemId && orderDetail.unitPrice === oldPrice
                );
                if (updateOrderItem) {
                    updateOrderItem.unitPrice = price;
                }
            });
            setHasUpdated(true);
        },
        [setOrderDetails]
    );

    const backToOrder = useCallback(async () => {
        setConfirm(false);
    }, [setConfirm]);

    const saveOrder = useCallback(async () => {
        try {
            if (orderDetails && hasUpdated) {
                setOrderSaving(true);
                const orderType = OrderType[transferType! as keyof typeof OrderType];

                await new VanTransferClient(
                    process.env.REACT_APP_API_BASE
                ).createVanTransfer(orderDetails, fromStore!, toStore!, orderType);

                navigate(`/`);
            }
        } catch (e: unknown) {
            const error = e as ApiException;
            setError(JSON.parse(error.response).error);
        }
    }, [orderDetails, hasUpdated, transferType, fromStore, toStore, navigate]);

    const setConfirmState = useCallback(async () => {
        const orderTotal = orderDetails?.reduce((sum, item) => {
            return sum + item.unitPrice * item.quantity;
        }, 0);
        setOrderTotal(orderTotal!);
        setConfirm(true);
    }, [setConfirm, orderDetails]);

    const setItemTotalQty = useCallback(
        (qtyToAdd: number) => {
            setOrderTotalQty((prevQty) => {
                return prevQty + qtyToAdd;
            });
        },
        [setOrderTotalQty]
    );

    const setItemNoteState = useCallback(
        (itemId: number, value: string | undefined, price: number) => {
            setOrderDetails((draft) => {
                const updateOrderItem = draft?.find(
                    (orderDetail) =>
                        orderDetail.itemId === itemId && orderDetail.unitPrice === price
                );
                if (updateOrderItem) {
                    updateOrderItem.comment = value;
                }
            });
            setHasUpdated(true);
        },
        [setOrderDetails]
    );

    const updateTransferSettings = useCallback(
        (values: ITransferSettings) => {
            setToStore(values.toStore);
            setFromStore(values.fromStore);
            setTransferType(values.transferType);

            const transferStore = transferStores!.nonvanStores.find(
                (store) => store.id.toLowerCase() === values.toStore!.toLocaleLowerCase()
            );

            setSelectedToStoreDTO(transferStore ?? null);
            setShowOrderForm(true);
        },
        [
            setToStore,
            setFromStore,
            setTransferType,
            setShowOrderForm,
            transferStores,
        ]
    );

    /*const getSaveLabel = useCallback(
    (isDirty: boolean): string => {
    if (!isDirty && saved) {
    return "Saved";
    } else if (isSaving) {
    return "Saving";
    } else {
    return "Save";
    }
    },
    [isSaving, saved]
    );*/

    return (
        <div className="new-transfer">
            <NoCards
                pageTitle={`New van transfer${
                    selectedToStoreDTO && showOrderForm
                    ? " to " + selectedToStoreDTO.name
                    : ""
                }`}
            >
                <Row>
                    {transferStores && !showOrderForm && !isFetching && (
                        <Col lg={12} className={"gx-4 gy-4"}>
                            <Card className="shadow-sm" border="light">
                                <Card.Body className="p-4">
                                    <div className="transfer-settings-card-inner">
                                        <Formik
                                            //validationSchema={schema}
                                            onSubmit={updateTransferSettings}
                                            initialValues={{
                                                transferType: "Cuts",
                                                fromStore: transferStores.vanStores[0]?.id.toString(),
                                                toStore: transferStores.nonvanStores[0]?.id.toString(),
                                            }}
                                        >
                                            {({
                                                handleSubmit,
                                                handleChange,
                                                handleBlur,
                                                values,
                                                touched,
                                                isValid,
                                                errors,
                                                setFieldValue,
                                                dirty,
                                            }) => (
                                                <Form noValidate onSubmit={handleSubmit}>
                                                    <Row className="">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Transfer from</Form.Label>
                                                            <Form.Select
                                                                onChange={handleChange}
                                                                id={"fromStore"}
                                                                name={"fromStore"}
                                                                value={values.fromStore}
                                                            >
                                                                {transferStores.vanStores.map((value) => (
                                                                    <option
                                                                        key={value.id + "from"}
                                                                        value={value.id}
                                                                    >
                                                                        {value.name}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Transfer to</Form.Label>
                                                            <Form.Select
                                                                onChange={handleChange}
                                                                id={"toStore"}
                                                                name={"toStore"}
                                                                value={values.toStore}
                                                            >
                                                                {transferStores.nonvanStores.map((value) => (
                                                                    <option
                                                                        key={value.id + "to"}
                                                                        value={value.id}
                                                                    >
                                                                        {value.name}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label>Order type</Form.Label>
                                                            <div key={`inline-checkbox`} className="mb-3">
                                                                <Form.Check
                                                                    inline
                                                                    label={"Cuts"}
                                                                    name={"transferType"}
                                                                    type={"radio"}
                                                                    id={`cutsTransferType`}
                                                                    defaultChecked={
                                                                        values.transferType === "Cuts" ||
                                                                        values.transferType === ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        e.target.checked
                                                                            ? setFieldValue(e.target.name, "Cuts")
                                                                            : setFieldValue(e.target.name, false)
                                                                    }
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label={"Pots"}
                                                                    name={"transferType"}
                                                                    type={"radio"}
                                                                    id={`potsTransferType`}
                                                                    defaultChecked={
                                                                        values.transferType === "Pots"
                                                                    }
                                                                    onChange={(e) =>
                                                                        e.target.checked
                                                                            ? setFieldValue(e.target.name, "Pots")
                                                                            : setFieldValue(e.target.name, false)
                                                                    }
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label={"Materials"}
                                                                    name={"transferType"}
                                                                    type={"radio"}
                                                                    id={`materialsTransferType`}
                                                                    defaultChecked={
                                                                        values.transferType === "Materials"
                                                                    }
                                                                    onChange={(e) =>
                                                                        e.target.checked
                                                                            ? setFieldValue(
                                                                                e.target.name,
                                                                                "Materials"
                                                                            )
                                                                            : setFieldValue(e.target.name, false)
                                                                    }
                                                                />
                                                            </div>
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Stack gap={3} direction="horizontal">
                                                                <Button type="submit">Next</Button>
                                                                <LinkContainer to={"/"}>
                                                                    <Button type="button" variant="light">
                                                                        Cancel
                                                                    </Button>
                                                                </LinkContainer>
                                                            </Stack>
                                                        </Form.Group>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                    {showOrderForm && orderDetails && !confirm && (
                        <NewTransferForm
                            orderDetails={orderDetails}
                            setItemQty={setItemQty}
                            confirmOrder={setConfirmState}
                            updateItemTotalQty={setItemTotalQty}
                            totalOrderQty={orderTotalQty}
                            setItemNote={setItemNoteState}
                            orderType={transferType!}
                            setItemPrice={setItemPrice}
                        />
                    )}
                    {confirm && (
                        <ConfirmTransfer
                            orderType={transferType!}
                            orderDate={dayjs().format("DD/MM/YYYY")}
                            orderData={orderDetails!}
                            budget={selectedToStoreDTO?.budget!}
                            budgetUsedWtd={selectedToStoreDTO!.totalWeekToDate!}
                            budgetUsedMtd={selectedToStoreDTO!.totalMonth!}
                            orderTotal={orderTotal!}
                            saving={orderSaving}
                            confirmOrder={saveOrder}
                            backToOrder={backToOrder}
                            storeName={toStore!}
                        />
                    )}
                    {isFetching && (
                        <Col xs={12} lg={12} className={"gx-4 gy-4"}>
                            <Card className="shadow-sm p-0" border="light">
                                <Card.Body className="p-0 available-products-card">
                                    <CardPlaceholder />
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Row>
            </NoCards>
            <Modal show={error?.length! > 0} onHide={() => setError(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{error}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setError(null)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
