import { Formik } from "formik";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { LinkContainer } from "react-router-bootstrap";
import { StoreNameDTO, UserDTO, UserRole } from "../../../services/ApiClient";
import { TableSelect } from "../../common/tableSelect/TableSelect";

interface IUserEditFormProps {
  user: UserDTO;
  updateUser: (user: UserDTO) => void;
  //deleteUser?: (user: UserDTO) => void;
  stores: StoreNameDTO[];
  isNew: boolean;
  isSaving: boolean;
}

export const UserEditForm = (props: IUserEditFormProps) => {
  return (
    <div className={"portal-settings-form"}>
      <Formik
        //validationSchema={schema}
        onSubmit={props.updateUser}
        //onSubmit={console.log}
        initialValues={props.user}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          dirty,
          errors,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="">
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name={"email"}
                  disabled={!props.isNew}
                  type="text"
                  placeholder=""
                  defaultValue={values.email}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name={"name"}
                  type="text"
                  placeholder=""
                  defaultValue={values.name}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Role</Form.Label>
                <Form.Select
                  onChange={handleChange}
                  id={"kooTraderRole"}
                  name={"kooTraderRole"}
                  value={values.kooTraderRole}
                >
                  {Object.keys(UserRole)
                    .filter((x) => !parseInt(x) && x !== "0")
                    .map((key: string) => (
                      <option
                        key={key}
                        value={UserRole[key as keyof typeof UserRole]}
                      >
                        {key}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Stores</Form.Label>
                <TableSelect
                  columnName="Name"
                  columnKey="name"
                  returnValue="id"
                  values={props.stores}
                  selectedValues={
                    (values.userStores?.map((store) => store.id) as any) ?? []
                  }
                  height={800}
                  name="storeIDs"
                />
              </Form.Group>
              <Form.Group>
                <Stack gap={3} direction="horizontal">
                  <LinkContainer to={"/settings/users"}>
                    <Button type="button" variant="light">
                      {"Cancel"}
                    </Button>
                  </LinkContainer>
                  <Button type="submit" disabled={props.isSaving || !dirty}>
                    {props.isSaving ? "Saving" : "Save"}
                  </Button>
                </Stack>
              </Form.Group>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};
