import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import { LinkContainer } from "react-router-bootstrap";
import { useParams } from "react-router-dom";
import {
    DeliveryDateDTO,
    ItemType,
    NewOrderViewDTO,
    OrderType,
} from "../../../services/ApiClient";
import { DeliveryDatePicker } from "../../common/deliveryDatePicker/DeliveryDatePicker";
import { NoCards } from "../../layouts/NoCards";
import "./NewOrder.scss";
import { OrderItem } from "./OrderItem";

interface INewOrderProps {
    order: NewOrderViewDTO;
    setItemQty: (itemId: number, quanity: number, price: number) => void;
    updateItemTotalQty: (qtyToAdd: number) => void;
    setItemNote: (
        itemId: number,
        value: string | undefined,
        price: number
    ) => void;
    setDelvieryDate: (delvieryDate: DeliveryDateDTO) => void;
    confirmOrder: () => void;
    possibleDeliveryDates: DeliveryDateDTO[];
    currentDeliveryDate: DeliveryDateDTO;
    totalOrderQty: number;
    showCostPrice?: boolean;
}

export const NewOrderForm = (props: INewOrderProps) => {
    const [isNormalOrderingDay, setIsNormalOrderingDay] = useState<boolean>(true);

    const params = useParams();

    useEffect(() => {
        const orderingDays = props.order.expectedStoreOrderDays;
        const currentOrderingDayIndex = dayjs(props.order.order.deliveryDate).day();
        const weekdays = [
            "sunday",
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
        ];
        const currentOrderingDay = weekdays[currentOrderingDayIndex];

        if (!orderingDays[currentOrderingDay as keyof typeof orderingDays]) {
            setIsNormalOrderingDay(false);
        }
    }, [props.order.order.deliveryDate, props.order.expectedStoreOrderDays]);

    return (
        <>
            <NoCards
                pageTitle={
                    props.order?.order.store?.name
                        ? `Order ${ItemType[props.order?.order.orderType!]} for ${props
                            .order?.order.store?.name}`
                        : undefined
                }
                className={"new-order"}
            >
                <>
                    <Col lg={12}>
                        {props.order?.defaultNextDeliveryDate && (
                            <DeliveryDatePicker
                                deliveryDates={props.possibleDeliveryDates}
                                currentValue={
                                    props.currentDeliveryDate?.actualValue ??
                                    props.order?.defaultNextDeliveryDate
                                }
                                setDeliveryDate={props.setDelvieryDate}
                                size="lg"
                            />
                        )}
                    </Col>
                    {props.order.error.length <= 0 ? (
                        <>
                            {props.order?.showOverBudgetWarning && (
                                <Col lg={12}>
                                    <Alert key={"danger"} variant={"danger"}>
                                        <strong>You are over budget for the week.</strong>
                                        <br />
                                        Budget: ${props.order.order.store?.budget}
                                        <br />
                                        Used: ${props.order.order.store?.budgetUsed}
                                    </Alert>
                                </Col>
                            )}
                            {!isNormalOrderingDay && (
                                <Col lg={12}>
                                    <Alert
                                        key={"danger"}
                                        variant={"danger"}
                                        className={"normal-day-alert"}
                                    >
                                        Warning: not a normal{" "}
                                        {OrderType[props.order.order.orderType].toLowerCase()}{" "}
                                        delivery day for this store.
                                    </Alert>
                                </Col>
                            )}
                            {props.order?.warning?.length > 0 && (
                                <Col lg={12}>
                                    <Alert
                                        key={"danger"}
                                        variant={"danger"}
                                        className={"normal-day-alert"}
                                    >
                                        {props.order.warning}
                                    </Alert>
                                </Col>
                            )}
                            {props.order?.order?.orderDetails &&
                            props.order.order.orderDetails.length > 0 &&
                            props.order.error.length <= 0 ? (
                                <>
                                        {props.order?.order?.orderDetails?.map(
                                            (orderDetail, index) => (
                                        <Col lg={12} key={`orderitem${index}`}>
                                            <Card className="shadow-sm" border="light">
                                                <OrderItem
                                                    itemId={orderDetail.itemId!}
                                                    name={orderDetail.item?.itemName!}
                                                    type={`${ItemType[props.order?.order.orderType!]}`}
                                                    description={orderDetail.item?.message!}
                                                    quantity={orderDetail.quantity}
                                                    increment={orderDetail.item?.incrementBy!}
                                                    image={orderDetail.item?.photo!}
                                                    price={orderDetail.item?.price!}
                                                    qtyAvailable={
                                                        (orderDetail.item?.stocktake ?? 0) +
                                                        (orderDetail.item?.incoming ?? 0)
                                                    }
                                                    updateItemQty={props.setItemQty}
                                                    updateItemTotalQty={props.updateItemTotalQty}
                                                    boxLot={orderDetail.item?.boxlot === 1}
                                                    special={orderDetail.item?.special === 1}
                                                    multibuy={orderDetail.item?.multiBuy === 1}
                                                    multibuyNote={orderDetail.item?.multiBuyNote!}
                                                    avilability={orderDetail.item?.availability!}
                                                    updateItemNote={props.setItemNote}
                                                    preorder={orderDetail.preOrder > 0}
                                                    requiredNote={false}
                                                    note={orderDetail.comment}
                                                    showCostPrice={props.showCostPrice}
                                                    costPrice={orderDetail.item?.costPrice!}
                                                />
                                            </Card>
                                        </Col>
                                        )
                                    )}
                                </>
                            ) : (
                                <Col lg={12}>
                                    <Card className="shadow-sm" border="light">
                                        <div className="no-items">No items available.</div>
                                    </Card>
                                </Col>
                            )}
                        </>
                    ) : (
                        <Col lg={12}>
                            <Card className="shadow-sm" border="light">
                                <div className="no-items">{props.order.error}</div>
                            </Card>
                        </Col>
                    )}
                    <Col lg={12} className={"d-flex flex-row-reverse"}>
                        <Stack gap={3} direction="horizontal">
                            <LinkContainer to={`/stores/${params.storeid}`}>
                                <Button type="button" variant="light">
                                    {"Cancel"}
                                </Button>
                            </LinkContainer>
                            {props.order.error.length <= 0 && (
                                <Button
                                    type="submit"
                                    disabled={props.totalOrderQty <= 0}
                                    onClick={() => props.confirmOrder()}
                                >
                                    {"Confirm"}
                                </Button>
                            )}
                        </Stack>
                    </Col>
                </>
            </NoCards>
        </>
    );
};
