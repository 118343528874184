import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { RoleContext } from "../../../App";
import { UserRole } from "../../../services/ApiClient";

import "./NavCardRow.scss";

interface INavCardRowProps {
  title: string;
  requiredRoles: UserRole[];
  children: React.ReactNode;
}

export const NavCardRow = (props: INavCardRowProps) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const roleContext = useContext(RoleContext);

  useEffect(() => {
    const getRoles = async () => {
      const hasRequiredRole = props.requiredRoles.some(
        (role: UserRole) => roleContext!.role === role
      );

      setIsAuthorized(hasRequiredRole);
    };
    getRoles();
  }, [props.requiredRoles, roleContext]);

  return (
    <>
      {isAuthorized && (
        <Col lg={12} className={"nav-card-row"}>
          <Row className="g-4">
            <Col xs={12}>
              <h5>{props.title}</h5>
            </Col>
            {props.children}
          </Row>
        </Col>
      )}
    </>
  );
};
