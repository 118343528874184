import { IHeaderComp } from "ag-grid-community";
import React, { useCallback } from "react";

interface ISelectAllHeaderProps {
  label: string;
  selectAll: (pickPackStatus: number, selected: boolean) => void;
  checked: boolean;
  pickPackStatus: number;
}

export const SelectAllHeader = (props: IHeaderComp & ISelectAllHeaderProps) => {
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      props.selectAll(props.pickPackStatus, event.currentTarget.checked);
    },
    [props]
  );

  return (
    <div className="form-check fd-checkbox-container" style={{ width: "100%" }}>
      <div>
        <input
          className="form-check-input fd-checkbox-lg"
          role="switch"
          type="checkbox"
          onClick={onClick}
          defaultChecked={false}
          style={{ marginRight: "10px" }}
        />
      </div>
      <span>{props.label}</span>
    </div>
  );
};
